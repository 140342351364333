import React, { Component } from "react";
import "../../public/scss/MethodComponent.scss";
import Header from './Header';
import Footer from "./Footer";

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

import TextWithImageMethod from './snippets/TextWithImageMethod';

import step1Image from './../../public/images/methodanimation1.gif';
import step2Image from './../../public/images/methodanimation2.gif';
import step3Image from './../../public/images/methodanimation3.gif';
import step4Image from './../../public/images/methodanimation1.gif';
import step5Image from './../../public/images/methodanimation5.gif';
import Layer32 from './../../public/images/Layer32.jpg';
import Layer41 from './../../public/images/Layer41.jpg';


class MethodComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step1 : {
                heading: "Take an online test & identify weak areas",
                para: "Step 1",
                btn_text: "",
                btn_text_url: "",
                image_url: step1Image,
                background : '',
                themeColor: "yellow",
                position: "ltr"
            },
            step2 : {
                heading: "Address weak areas by watching video tutorials",
                para: "Step 2",
                btn_text: "",
                btn_text_url: "",
                image_url: step2Image,
                background : Layer32,
                themeColor: "gray",
                position: "ltr"
            },
            step3 : {
                heading: "Solve practice problems to make sure you overcome your weaknesses",
                para: "Step 3",
                btn_text: "",
                btn_text_url: "",
                image_url: step3Image,
                background : '',
                themeColor: "yellow",
                position: "ltr"
            },
            step4 : {
                heading: "Take a re-test to see how much you have improved",
                para: "Step 4",
                btn_text: "",
                btn_text_url: "",
                image_url: step4Image,
                background : Layer41,
                themeColor: "gray",
                position: "ltr"
            },
            step5 : {
                heading: "Wow you have improved so much!",
                para: "Step 5",
                btn_text: "",
                btn_text_url: "",
                image_url: step5Image,
                background : '',
                themeColor: "yellow",
                position: "ltr"
            }
        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

    componentDidMount() {
        document.title = "Our Methodology - Lincoln Method";
    }
    
    render() {
        
        return (
        // <DocumentMeta {...meta}>
            <div className="MethodComponent">

                <Header />

                    <div className="homeEdtech" style={{background: '#333 center fixed / cover'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 12 }}>
                                    <h2>Here is how our Learning Engine works</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <TextWithImageMethod propData={this.state.step1} />
                    <TextWithImageMethod propData={this.state.step2} />
                    <TextWithImageMethod propData={this.state.step3} />
                    <TextWithImageMethod propData={this.state.step4} />
                    <TextWithImageMethod propData={this.state.step5} />
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default MethodComponent;