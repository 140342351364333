import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

//importing component for website
import HomeComponent from "./components/website/HomeComponent";
import CoursesComponent from "./components/website/courses/CoursesComponent";
import OnlineSchoolComponent from "./components/website/onlineSchool/OnlineSchoolComponent";
import OnlineSchoolDemoComponent from "./components/website/onlineSchool/OnlineSchoolDemoComponent";
import HolyFridaySaleComponent from "./components/website/onlineSchool/HolyFridaySaleComponent";
import AffordableEducationComponent from "./components/website/onlineSchool/AffordableEducationComponent";
import CourseDetailComponent from "./components/website/courses/CourseDetailComponent";
import WallOfFameComponent from "./components/website/WallOfFameComponent";
import MethodComponent from "./components/website/MethodComponent";

import CareersOnlineTeacherComponent from "./components/website/careers/CareersOnlineTeacherComponent";
import CareersThanksForApplyingComponent from "./components/website/careers/CareersThanksForApplyingComponent";

import ResearchComponent from "./components/website/ResearchComponent";
import ResellersComponent from "./components/website/ResellersComponent";
import FranchisePartnerComponent from "./components/website/FranchisePartnerComponent";

import UnitedNationsComponent from "./components/website/partnerships/UnitedNationsComponent";
import SlumBlockMillionaireComponent from "./components/website/partnerships/SlumBlockMillionaireComponent";
import FasionDesignShoutOutsComponent from "./components/website/partnerships/FasionDesignShoutOutsComponent";
import EducateComptonComponent from "./components/website/partnerships/EducateComptonComponent";
import InvestWithUsComponent from "./components/website/InvestWithUsComponent";
import ReportsComponent from "./components/website/ReportsComponent";
import RefundPolicyComponent from "./components/website/RefundPolicyComponent";
import LicenseAgreementComponent from "./components/website/LicenseAgreementComponent";
import FaqComponent from "./components/website/FaqComponent";
import PrivacyPolicyComponent from "./components/website/PrivacyPolicyComponent";
import LearningClinicComponent from "./components/website/LearningClinicComponent";
import CongratulationsComponent from "./components/login/CongratulationsComponent";
import ConfirmEmailComponent from "./components/login/ConfirmEmailComponent";
import LogoutComponent from "./components/logout/LogoutComponent";
import Checkout from './components/checkout/Checkout';
import successCheckoutPayment from './components/checkout/successCheckoutPayment';
import failedCheckoutPayment from './components/checkout/failedCheckoutPayment';
import TermsAndCondition from './components/website/TermsAndCondition';



function App() {

  return (
    <Provider store={store}>
      <HashRouter>
        <div className="App">
          <Switch>
            
            <Route exact path="/" component={HomeComponent} />
            <Route path="/courses" component={CoursesComponent} />
            <Route path="/course/:courseid" component={CourseDetailComponent} />
            {/* This is for online school courses */}
            <Route path="/online-school" component={OnlineSchoolComponent} />
            <Route path="/wall-of-fame" component={WallOfFameComponent} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/method" component={MethodComponent} />
            <Route path="/research" component={ResearchComponent} />
            <Route path="/resellers" component={ResellersComponent} />
            <Route path="/franchise-partner" component={FranchisePartnerComponent} />
            
            <Route path="/un-nations-sdg" component={UnitedNationsComponent} />
            <Route path="/slum-block-millionaire" component={SlumBlockMillionaireComponent} />
            <Route path="/fashion-design-shout-outs" component={FasionDesignShoutOutsComponent} />
            <Route path="/educate-compton" component={EducateComptonComponent} />
            <Route path="/how-to-invest-with-us" component={InvestWithUsComponent} />
            <Route path="/reports" component={ReportsComponent} />
            <Route path="/refund-policy" component={RefundPolicyComponent} />
            <Route path="/license-agreement" component={LicenseAgreementComponent} />
            <Route path="/TermsAndCondition" component={TermsAndCondition} />
            <Route path="/faqs" component={FaqComponent} />
            <Route path="/privacy-policy" component={PrivacyPolicyComponent} />
            <Route path="/learning-clinic" component={LearningClinicComponent} />
            <Route path="/congratulations/:detail" component={CongratulationsComponent} />
            
            <Route path="/confirm/:detail" component={ConfirmEmailComponent} />

            <Route path="/careers/online-school-teachers" component={CareersOnlineTeacherComponent} />
            <Route path="/careers/thanks-for-applying" component={CareersThanksForApplyingComponent} />
            
            <Route path="/online-school-demo" component={OnlineSchoolDemoComponent} />
            <Route path="/holy-friday-sale" component={HolyFridaySaleComponent} />
            <Route path="/affordable-education" component={AffordableEducationComponent} />

            <Route path="/success-checkout" component={successCheckoutPayment} />
            <Route path="/failed-checkout" component={failedCheckoutPayment} />

            <Route path="/logout/:isLogout" component={LogoutComponent} />
            <Route path="/modalSignin/:email" component={HomeComponent} />

          </Switch>
        </div>
      </HashRouter>
    </Provider>
  );

}

export default App;