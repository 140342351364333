import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typed from 'react-typed';

import "./../../../public/scss/snippets/BannerWithText.scss";

import {
  Button,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

class BannerWithText extends Component {
  
  constructor(props) {
    super(props);

    console.log(props)

    this.state = {
        heading: props.bannerData.heading,
        subheadings: props.bannerData.subheadings,
        para: props.bannerData.para,
        btn_icon: props.bannerData.btn_icon,
        btn_text: props.bannerData.btn_text,
        btn_text_url: props.bannerData.btn_text_url,
        image_url: props.bannerData.image_url,
        video_url: props.bannerData.video_url,
        theme: props.bannerData.themeColor,
        modal: false
    };

    this.toggleModal = this.toggleModal.bind(this);

  }

  toggleModal() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }
  

  render() {
    return (
      <div className={"BannerWithText " + this.state.theme} style={{ backgroundImage: !this.state.video_url || this.state.video_url === '' ? this.state.image_url ? "url("+ this.state.image_url +")" : null : null, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundColor: 'rgba(0,0,0,1)', backgroundSize: 'cover' }}>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal} centered={true} size="lg" className="videoModal">
          {/* <ModalHeader toggle={this.toggle}>Modal title</ModalHeader> */}
          <ModalBody style={{padding: '5px', background: '#000'}}>
          
            <iframe width="100%" height="440px" src="https://www.youtube.com/embed/W78-eN9Eqtc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

          </ModalBody>
          {/* <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter> */}
        </Modal>

        <Container style={{textAlign: 'left'}}>
            <Row>
                <Col md={{ size: 12 }}>
                    
                    <h1 className="BannerWithText_heading" dangerouslySetInnerHTML={{__html: this.state.heading}}></h1>
                    <h2 className="BannerWithText_subheading">
                      {this.state.subheadings.label}
                      <Typed
                        strings={this.state.subheadings.list}
                            typeSpeed={60}
                            backSpeed={15}
                            loop >
                              <span></span>
                      </Typed>
                    </h2>
                    
                    

                    {
                      this.state.para && this.state.para != '' ?
                        <p className="BannerWithText_paragraph" dangerouslySetInnerHTML={{__html: this.state.para}}></p>
                      : null
                    }
                      {
                        this.state.btn_text || this.state.btn_text !== '' ?
                        
                          <Button className="BannerWithText_btn" size="lg" color="primary" onClick={this.toggleModal}>
                            {
                              this.props.bannerData.btn_icon ?
                                <FontAwesomeIcon style={{marginRight: '15px'}} icon={this.props.bannerData.btn_icon} size="1x" color="#f8ab24" />
                              : null
                            }
                            <span dangerouslySetInnerHTML={{__html: this.state.btn_text}}></span>
                          </Button>
                          :
                          null
                      }
                </Col>
            </Row>
        </Container>

        {
            this.state.video_url && this.state.video_url !== '' ?
        
            <video className="slider-video" width="100%" loop autoPlay muted>
                {/* <source src={this.state.video_url} type="video/webm" /> */}
                <source type="video/mp4" src={this.state.video_url} />
            </video>
        
            : null

        }

      </div>
    );
  }
}

export default BannerWithText;
