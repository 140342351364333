import React, { Component } from "react";
import "../../public/scss/snippets/LoginComponent.scss";

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import store from './../../store';
import PropTypes from 'prop-types';
import { getSignUpFormType } from './../../actions/webRedActions';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup
} from "reactstrap";

import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';

import giraffeImage from '././../../public/images/login/giraffe.png';
import giraffeImageAngry from '././../../public/images/login/giraffe_angry.png';
import { isMobileBrowser, reserveBackOnMobile } from "../../actions/utility";

class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup : false,
            isLoginForm : true,
            signupFormType : 'normal',
            isCredentialActive : 0,
            extraFields : [],
            // signupFormData : {}
        };
    }

    componentDidMount() {
        if(this.props.signUpPath != null){
            var str = this.props.signUpPath;
            if(str.includes("modalSignin")){
                
                this.setState({
                    showPopup: true
                })
                
            }else{
                this.setState({
                    showPopup: false
                })
            }
     }
    }

    componentWillReceiveProps(nextProps, thisProps) {
        
        if (nextProps.showPopup !== false && this.props.showPopup != nextProps.showPopup) {
            this.setState({
                showPopup: true
            }, () => {
                // this.setState({
                //     isLoginForm : true
                // })
                if(localStorage.getItem('loginType') == 'normal'){
                    this.setState({
                        isLoginForm : true,
                        signupFormType : "normal",
                        isCredentialActive : 0, // only for child's signup form,
                        extraFields : store.getState().webRed.extraFieldsArray
                    })
                }
                else{
                    
                    this.setState({
                        isLoginForm : false
                    })

                    var typehere = store.getState().webRed.SignUpFormType;
                    if(typehere){
                        this.setState({
                            signupFormType : typehere.formFor,
                            isCredentialActive : typehere.isCredentialActive, // only for child's signup form
                            extraFields : store.getState().webRed.extraFieldsArray
                        })
                    }
                }


                if(isMobileBrowser()) {
                    reserveBackOnMobile(true);
                }

            });
        }
        else{
            this.setState({
                showPopup: false
            })
        }

        // if(nextProps.SignUpFormType && this.props.SignUpFormType != nextProps.SignUpFormType){
        //     alert('asd')
        //     if(localStorage.getItem('loginType') == 'normal'){
        //         this.setState({
        //             signupFormType : "normal"
        //         })
        //     }else{  
        //         this.setState({
        //             signupFormType : nextProps.SignUpFormType.formFor
        //         })
        //     }
        // }

    }

    toggleLoginModal = () => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));

        if(isMobileBrowser()) {
            reserveBackOnMobile(false);
        }
    }

    changeFormState = () => {
        this.setState(prevState => ({
            isLoginForm: !prevState.isLoginForm
        }));
    }

    render() {
        return (
            <div className="LoginComponent">

                <Modal isOpen={this.state.showPopup} centered={true} size={this.state.signupFormType !== 'normal' ? "md" : "md"} className={this.state.isLoginForm ? "loginPopupMain" : "loginPopupMain signup" }>
                    <ModalBody>
                        <div>
                            <Container>
                                <p className="closeModalLogin" onClick={this.toggleLoginModal}>X</p>
                                <Row>
                                    {/* <Col md={{size: this.state.isLoginForm ? 5 : 3}}>
                                        <Media className={this.state.isLoginForm ? 'imageLogin' : 'imageSignup'} style={{transform : 'scaleX(-1)', marginBottom: '-40px', width: this.state.isLoginForm ? 'auto' : '100%'}} object src={giraffeImage} alt="Giraffe" />
                                    </Col> */}
                                    <Col md={{size: this.state.isLoginForm ? 12 : 12, offset: this.state.isLoginForm ? 0 : 0}}>
                                        
                                        {/* {
                                            this.state.signupFormType == 'normal' ?
                                                <h3 className="learnHeading" style={{margin: '1vh 0 3vh 0'}}>
                                                    Login
                                                </h3>
                                            : null
                                        } */}
                                        
                                        {
                                            this.state.isLoginForm ?
                                            <div>
                                                {/* {
                                                    this.state.signupFormType !== 'normal' ? */}
                                                        <p className="anchorLink" onClick={this.changeFormState}>Sign up for new account</p>
                                                    {/* : null
                                                } */}
                                                
                                                <LoginForm email={this.props.email} signUpPath={this.props.signUpPath}/>
                                            </div>
                                            :
                                            <div>
                                                <p className="anchorLink" onClick={this.changeFormState}>(Login if you already have an account)</p>
                                                <br/>
                                                <SignUpForm formType={this.state.signupFormType} isCredentialActive={this.state.isCredentialActive} extraFields={this.state.extraFields} />
                                            </div>
                                        }
                                        
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }

}

LoginComponent.propTypes = {
    // getSignUpFormType: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // loginSuccess: state.webRed.loginSuccess
    // extraSignUpFieldsNormal : state.webRed.extraSignUpFieldsNormal,
    // extraSignUpFieldsCourse : state.webRed.extraSignUpFieldsCourse
    // SignUpFormType : state.webRed.SignUpFormType
})

export default connect(mapStatesToProps, { })(LoginComponent);