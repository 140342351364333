import React, { Component } from "react";
import Header from './Header';
import Footer from "./Footer";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

import homeBanner from './../../public/images/homeBanner.png';

import report1 from './../../public/images/report1_1.png';
import report2 from './../../public/images/report2_1.png';
import report3 from './../../public/images/report3_1.png';

class ReportsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false
        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

    componentDidMount() {
        document.title = "Reports - Lincoln Method"
    }
    
    render() {
        
        const { photoIndex, isOpen } = this.state;
        const images = [
            report1,
            report2,
            report3
        ];

        return (
        // <DocumentMeta {...meta}>
            <div className="ReportsComponent">

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                )}

                <Header />

                <div className="homeEdtech" style={{backgroundColor: '#333'}}>
                    <Container>
                        <Row>
                            <Col md={{ size: 12 }}>
                                <h2>Reports</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div>

                    <Container style={{padding: '7vh 0'}}>
                        <Row>

                            {
                                images.map((imageUrl, key) =>
                                    <Col key={key} md={{ size: 4 }}>
                                        page {key + 1}
                                        <Media style={{maxWidth: '100%', cursor: 'pointer', margin: '15px 0'}} onClick={() => this.openLighBox(key)} object src={imageUrl} />
                                        
                                    </Col>
                                )
                            }

                        </Row>
                    </Container>
                    
                </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default ReportsComponent;