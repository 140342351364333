import React from 'react';
import {
    Row,
    Col,
    Table,
    Card,
    Tab,
    Nav,
    Button,
    Form ,
    Collapse
} from 'react-bootstrap';

import Header from '../website/Header';
import Footer from '../website/Footer';
import { doneMangoFinalPayment } from './../../actions/webRedActions';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { data } from 'jquery';

class successCheckoutPayment extends React.Component {
    state = {
        isPaymentSuccess : false,
        checkoutCourse : ''
    };

    componentDidMount() {
        
        let checkoutCourse = localStorage.getItem('checkoutCourse');
        checkoutCourse = JSON.parse(checkoutCourse);
        let couponId = localStorage.getItem('couponId');
        let couponCode = localStorage.getItem('couponCode');

        this.setState({
            checkoutCourse : checkoutCourse
        }, () => {

            const { checkoutCourse } = this.state;

            var payMongoResponseId = localStorage.getItem('payMongoResponseId');
            if(payMongoResponseId && payMongoResponseId != null && payMongoResponseId != '') {

                // validate the id for confirmation that we can pay against this id to pay mongo
                let login = localStorage.getItem('loginData');
                
                if(login)
                {
                    login = JSON.parse(login);

                    var data = {
                        "candidateid":login.user.id,
                        "amount": parseInt(checkoutCourse.course_price) * 100,
                        "id": payMongoResponseId,
                        "course_id": checkoutCourse.id,
                        "course_name": checkoutCourse.course_name,
                        "currency": "PHP",
                        "couponId": couponId,
                        "couponCode": couponCode,
                    };
    
                    this.props.doneMangoFinalPayment(data, payMongoResponseId);
                    
                }
    
            }

        })

    }
    
    componentWillReceiveProps = (nextProps, thisProps) => {
        // console.log()
        if(this.props.finalPaymentDonePayMongo != nextProps.finalPaymentDonePayMongo) {
            console.log(nextProps.finalPaymentDonePayMongo);
            if(nextProps.finalPaymentDonePayMongo.data) {
                this.setState({
                    isPaymentSuccess : true
                })
            }
        }
    };

    render() {

        const {isPaymentSuccess, checkoutCourse} = this.state;

        return (
            <div className="checkoutpaymentSuccess">
                <Header />
                    <div className="container" style={{padding: '50px 0'}}>
                        <Row className='btn-page'>
                            <Col md={{size: 12}} style={{
                                padding: '30vh 5vw'
                            }}>
                                {
                                    isPaymentSuccess ?
                                        <h2>
                                            Your payment has been successfull and you're registered into<br /><strong>{checkoutCourse.course_name}</strong>
                                        </h2>
                                    : null
                                }
                            </Col>
                        
                        </Row>
                    </div>
                <Footer/>
            </div>
        );
    }
}
const mapStatesToProps = state => ({
    finalPaymentDonePayMongo: state.webRed.finalPaymentDonePayMongo
})
export default connect(mapStatesToProps,{ doneMangoFinalPayment })(successCheckoutPayment);