import React, { Component } from "react";
import { Container, Row, Col, Media } from "reactstrap";
import "../../public/scss/Footer.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import socialFacebook from './../../public/images/socialFacebook.png';
import socialTwitter from './../../public/images/socialTwitter.png';
import socialLinkedin from './../../public/images/socialLinkedin.png';
import socialInstagram from './../../public/images/socialInstagram.png';
import socialYoutube from './../../public/images/socialYoutube.png';

class Footer extends Component {
  render() {
    return (
      <div className="Footer">

        {/* <div className="homeEdtech" style={{background: '#333', padding: '10vh 0'}}>
            <Container style={{textAlign: 'center'}}>
                <Row>
                  <Col md={{ size: 12 }}>
                    <h2 style={{color: '#f8ab24'}}>Call Us</h2>
                  </Col>
                </Row>
                <Row style={{paddingTop: '5vh'}}>
                  <Col md={{size: 12}}>
                    <h2 style={{color: '#fff'}}>
                      <a style={{color: 'inherit'}} href="tel:+923008296228"><FontAwesomeIcon style={{marginRight: '15px'}} icon={faPhoneAlt} size="1x" color="#fff" />+92 300 829 6228</a>
                    </h2>
                  </Col>
                  {/* <Col md={{size: 6}}>
                    <h2 style={{color: '#fff'}}>
                      <a style={{color: 'inherit'}} href="tel:+923008296208"><FontAwesomeIcon style={{marginRight: '15px'}} icon={faPhoneAlt} size="1x" color="#fff" />+92 300 829 6208</a>
                    </h2>
                  </Col> 
                </Row>
            </Container>
        </div> */}

        <Container
          className="text-center text-md-left"
          style={{ padding: "100px 0" }}
        >
          <Row>
            <Col md={{ size: 4 }}>
              <h5 className="title">Quick Links</h5>
              <ul>
                <li className="list-unstyled">
                  <a href="/wall-of-fame">Wall of fame.</a>
                  <br />
                  {/* <a href="/how-to-invest-with-us">How to invest with us ?</a>
                  <br/>
                  <a href="/reports">Reports</a>
                  <br/>
                  <a href="/faqs">FAQs</a>
                  <br/>
                  <a href="http://form.assessmentfund.com/ODY2MQ==" target="_new">ADHD consultation appointment</a>
                  <br/>
                  <a href="/refund-policy">Refund Policy</a>
                  <br/>
                  <a href="/license-agreement">License Agreement</a>
                  <br/>
                  <a href="/privacy-policy">Privacy Policy</a> */}
                </li>
              </ul>
            </Col>
            {/* <Col md={{ size: 3, offset: 0 }}>
              <h5 className="title">Careers</h5>
              <ul>
                <li className="list-unstyled">
                  <a href="/careers/online-school-teachers">Online school teachers</a>
                  <br/>
                </li>
              </ul>
            </Col> */}
            <Col md={{ size: 4, offset: 0 }}>
              {/* <h5 className="title">Social Media & Press</h5>
              <ul className="footerSocial">
                <li className="list-unstyled">
                  <a href="https://www.facebook.com/LincolnMethod" target="new">
                    <Media object src={socialFacebook} alt="Facebook" />
                  </a>
                  <a href="https://twitter.com/LincolnMethod" target="new">
                    <Media object src={socialTwitter} alt="socialTwitter" />

                  </a>
                  <a href="https://www.linkedin.com/company/lincoln-method/" target="new">
                    <Media object src={socialLinkedin} alt="socialLinkedin" />

                  </a>
                  <a href="https://www.instagram.com/lincolnmethod/" target="new">
                    <Media object src={socialInstagram} alt="socialInstagram" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCPFRa4VViOC_6TWMaIqD_Dg" target="new">
                    <Media object src={socialYoutube} alt="socialYoutube" />
                  </a>
                </li> */}

              {/* <li className="list-unstyled" style={{paddingTop: '20px', marginTop: '20px', borderTop: '1px #333 solid'}}>
                  <a target="_blank" href="http://info.lincolnmethod.com/wp-content/uploads/2020/06/Introducing-Lincoln-Method-Media-Kit.pdf">Download Media Kit</a>
                  <br/>
                </li> */}

              {/* </ul> */}
            </Col>
            <Col md={{ size: 4, offset: 0 }}>
              {/* <h5 className="title">Contact Us</h5> */}
              <p>© 2020 skillupz.com All rights reserved.</p>
              <ul>
                <li className="list-unstyled">
                  Powered by <a href="https://lincolnmethod.com" target="new">Lincoln Method.</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
