import React, { Component } from "react";
import store from './../../store';
import PropTypes from 'prop-types';
import { doLogin, triggerForgotPassPopup } from './../../actions/webRedActions';
import { connect } from 'react-redux';
import { validateEmailAddress, getCurrentClient, getBaseUrl } from './../../actions/utility';
import axios from 'axios';
import $ from 'jquery'

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup
  } from "reactstrap";

import SocialLoginComponent from './SocialLoginComponent';


class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email,
            password: '',
            credError : '',
            registeredUsers : [],
            isUserName : false,
            selectedUsername : '',
            loginStep : 1
        };
    }
    
    componentDidMount() {
        if(this.props.signUpPath != null){
            var str = this.props.signUpPath;
            if(str.includes("modalSignin")){
                $('#login_btn').click();
            }
        }
    }
    
    componentWillReceiveProps(nextProps, thisProps) {
        console.log('nextProps:', nextProps);
        
        // for course login response
        if (typeof nextProps.loginSuccessWithCourse === 'object' && (nextProps.loginSuccessWithCourse.message && nextProps.loginSuccessWithCourse.message.indexOf('password is incorrect') > 0)) {
            // showing here the incorrect credentials msg
            this.setState({
                credError : nextProps.loginSuccessWithCourse.message
            })
        }
        else if (typeof nextProps.loginSuccessWithCourse === 'object' && nextProps.loginSuccessWithCourse.status == 'success') {
            console.log('login has been succcessfull');
            try {
                localStorage.setItem('loginData', JSON.stringify(nextProps.loginSuccessWithCourse.result));
            }
            catch (err) {
                console.log('loginData could not store:', err);
            }
        }

        // for normal login response
        if (typeof nextProps.loginSuccessNormal === 'object' && (nextProps.loginSuccessNormal.message && nextProps.loginSuccessNormal.message.indexOf('password is incorrect') > 0)) {
            // showing here the incorrect credentials msg
            this.setState({
                credError : nextProps.loginSuccessNormal.message
            })
        }
        else if (typeof nextProps.loginSuccessNormal === 'object' && nextProps.loginSuccessNormal.status == 'success') {
            console.log('login has been succcessfull');
            try {
                localStorage.setItem('loginData', JSON.stringify(nextProps.loginSuccessNormal.result));
            }
            catch (err) {
                console.log('loginData could not store:', err);
            }
        }

    }

    validateLoginCredentials = (column, value) => {

        if(value != "") {
            
            var bodyFormData = new FormData();
            var currentClient = getCurrentClient();

            bodyFormData.set('client_id', currentClient.id);
            bodyFormData.set('column', column);
            bodyFormData.set('val', value);

            axios.post(getBaseUrl() + '/api/v1/validate/user/column', bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                console.log(res);
                if(res.data.status == "success"){
                    if(res.data.result == "1"){
                        this.setState({
                            registeredUsers : res.data.data,
                            isUserName : false
                        }, () => {
                            
                            this.setState({
                                loginStep : 2,
                                credError : ''
                            })

                        })
                    } else {
                        
                        // now validate if this exits as username.

                        bodyFormData.set('column', 'user_loginname');

                        axios.post(getBaseUrl() + '/api/v1/validate/user/column', bodyFormData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then((res) => {
                            console.log(res);
                            if(res.data.status == "success"){
                                if(res.data.result == "1"){
                                    
                                    this.setState({
                                        selectedUsername : value,
                                        isUserName : true
                                    }, () => {
                                        
                                        this.setState({
                                            loginStep : 2,
                                            credError : ''
                                        })
            
                                    })

                                } else {
                                    this.setState({
                                        credError : "Email or username does not exist in the system."
                                    })
                                }
                            }
                        }).catch((error) => {
                            console.log(error);
                        });

                        // this.setState({
                        //     credError : "Email does not exist in the system."
                        // })
                    }
                }
            }).catch((error) => {
                console.log(error);
            });

        } else {
            this.setState({
                credError : "Please enter valid email or username."
            })
        }

    }
    
    /**
     * function for submission of form for login
     * @param event{object}
    */
    submitLoginForm = (event) => {

        const { email, selectedUsername, password, isUserName } = this.state;

        if (email != "" && password != "") {
            
            // if(validateEmailAddress(email)){
                this.setState({
                    credError : "" // removing the error message
                }, () => {
                    var loginType = 'course';
                    var courseId = 0,
                    isPurchase = 0,
                    isPassRequired = 1;
                    
                    if(localStorage.getItem('loginType') && localStorage.getItem('loginType') != null){
                        loginType = localStorage.getItem('loginType');
                        if(loginType == 'course'){
                            courseId = store.getState().webRed.courseDetail.course.id;
                            isPurchase = 1;
                        }
                    }

                    var loginViaThis = email;
                    if(isUserName) {
                        loginViaThis = selectedUsername;
                    }

                    this.props.doLogin(loginViaThis, password, courseId, isPurchase, loginType, isPassRequired); // calling the action to login in now

                })
            // }
            // else{
            //     this.setState({
            //         credError : "Email is not valid."
            //     })
            // }
            
        }
        else {
            this.setState({
                credError : "Username and Password is required."
            })
        }

    }

    forgotPassword = () => {
        this.props.triggerForgotPassPopup(this.state.email);
    }

    render() {
        const { loginStep, registeredUsers, isUserName } = this.state;
        return (
            <div style={{padding: '1vh 0'}} className="LoginForm">
                
                {/* <Form className="loginform"> */}
                    
                    <h4>Login</h4><br/>
                    {
                        this.state.credError && this.state.credError.length ?
                        <FormGroup style={{margin:0}}>
                            <p style={{left: '0', position: 'relative'}} className="error">{this.state.credError}</p>
                        </FormGroup>
                        : null
                    }
                    {
                        loginStep == 1 ?
                        
                            <FormGroup style={{position: 'relative'}}>
                                
                                <Input type="email" name="login_email" id="login_email" placeholder="Enter email or username *" onChange={(evt) => {
                                    this.setState({ email: evt.target.value })
                                }} value={this.state.email}/>
                                <br/>
                                <Button id="login_btn" onClick={(e) => this.validateLoginCredentials("user_email", this.state.email)} size="md" color="warning">Next</Button>

                            </FormGroup>
                        : null
                    }
                    
                    {
                        loginStep == 2 ?

                        (registeredUsers.length && registeredUsers.length > 1) && !isUserName ?
                            <div className="UsernamesListings" style={{minHeight: 'auto'}}>
                                
                                <label style={{fontSize: '12px'}}>Select a user account to login</label>
                                {/* <hr /> */}
                                <ul className="registeredUsersList">
                                    {
                                        registeredUsers.map((user, key) =>
                                            <li key={key} onClick={(e) => {
                                                this.setState({
                                                    selectedUsername : user.user_loginname,
                                                    isUserName : true
                                                })
                                            }}>
                                                <Button color="primary" style={{background: '#fafafa'}}>
                                                    use<br/>this
                                                </Button>
                                                <p style={{color:'#f8ab24'}}>
                                                    {user.user_loginname} <br/>
                                                    <span>{user.user_name}</span>
                                                </p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        : 

                            <div>
                                <FormGroup>
                                    <label style={{fontSize: '12px'}}>Your {isUserName ? 'username' : 'email'} is { isUserName ? this.state.selectedUsername : this.state.email } <span style={{color: 'blue', marginLeft: '5px', cursor: 'pointer'}}
                                        onClick={(e) => {
                                            this.setState({
                                                loginStep : 1,
                                                credError : ''
                                            })
                                        }}
                                    >change</span> </label>
                                    <hr />
                                    <Input type="password" name="password" id="login_password" placeholder="Enter password *" onChange={(evt) => {
                                        this.setState({ password: evt.target.value });
                                    }} />
                                </FormGroup>
                                <FormGroup>
                                    <Button onClick={this.submitLoginForm} size="md" color="warning">Login</Button>
                                    <p style={{cursor: 'pointer', display: 'inline-block', fontSize: '14px', float: 'right', fontWeight: '500'}} onClick={this.forgotPassword}>Forgot password ?</p>
                                </FormGroup>
                            </div>
                            
                        : null

                    }
                    
                {/* </Form> */}
                
                {/* <p style={{marginBottom: '0', marginTop: '10px', fontSize: '14px'}}>Connect with us.</p>
                <SocialLoginComponent /> */}

            </div>
        );
    }
}

/**
 * managing the class proptypes bellow in order to use redux things in this class
 */
LoginForm.propTypes = {
    doLogin: PropTypes.func.isRequired,
    triggerForgotPassPopup: PropTypes.func.isRequired
}
/**
 * map redux things in current class state
 */
const mapStatesToProps = state => ({
    loginSuccessWithCourse: state.webRed.loginSuccessWithCourse,
    loginSuccessNormal: state.webRed.loginSuccessNormal
})
export default connect(mapStatesToProps, { doLogin, triggerForgotPassPopup })(LoginForm);