import React, { Component } from "react";
import { connect } from 'react-redux';

import { logOut } from './../../actions/utility';

class LogoutComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const islogout = this.props.match.params.isLogout;
        console.log(islogout)
        if(islogout == 'true'){
            if(logOut()) // calls logout from utility
            {
                window.location = "/courses";
            }
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {

    }

    render() {

        return (
            <div className="LogoutComponent">

            </div>
        );
    }

}

LogoutComponent.propTypes = {
}

const mapStatesToProps = state => ({
})

export default connect(mapStatesToProps, { })(LogoutComponent);