import React, { Component } from "react";
import './../../../public/scss/OnlineSchoolCourses.scss';

import store from './../../../store';
import PropTypes from 'prop-types';
// import { getAllCourses } from './../../../actions/webRedActions';
import { connect } from 'react-redux';

import Header from './../Header';
import Footer from "./../Footer";

import { formatPrice, isMobileBrowser } from './../../../actions/utility';

import jstz from 'jstz';

import {
      Container,
      Row,
      Col,
      Button,
      ButtonGroup,
      Card, CardImg, CardText, CardBody,
      CardTitle, CardSubtitle,
      Media,
      Badge,
      Spinner,
      Table,
      Modal,
      ModalHeader,
      ModalBody
    } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes, faClock, faCalendar, faBook, faVideo, faWifi } from '@fortawesome/free-solid-svg-icons';

import inclass2 from './../../../public/images/courses/inclass2.gif';

import icon_Chapters from './../../../public/images/courses/Chapters.png';
import icon_Location from './../../../public/images/courses/Location.png';
import icon_OnlineLearning from './../../../public/images/courses/Online-Learning.png';
import icon_OnlineVideo from './../../../public/images/courses/Online-Video.png';

import storelistingbg from './../../../public/images/courses/storelistingbg.png';
// import holyFridaySaleBannerPerMonth from './../../../public/images/sales/holyFridaySaleBannerPerMonth.jpeg';


class AffordableEducationComponent extends Component {

    constructor(props) {
        super(props);

        const timezone = jstz.determine();
        
        this.state = {
            loading : true,
            grades : {},
            selectedGrade : '',
            filteredCourses : {},
            sortedCourses : [],
            visibleGradeList : [],
            applyGrid : false,
            showFiltersOnMobile : false,
            popoverOpen : [],
            popOverGradeId : 0,
            popOverCourseId : 0,
            showPopupTime : false,
            currentTimeZone : timezone.name(),
            formStep : 1,

            disablePage : false
        }

        this.courseStateManagement = this.courseStateManagement.bind(this);
    }

    // openLighBox(index) {
    //     this.setState({
    //         photoIndex: index,
    //         isOpen: true
    //     })
    // }

    componentDidMount() {
        document.title = "Make Education Affordable - Lincoln Method";        
        window.addEventListener('scroll', this.handleScroll); // gets the scrolling event

        var dataInStore = store.getState().webRed.coursesDataOnlineSchool;
        if(dataInStore && dataInStore != "" && dataInStore != "error"){ // gets data from store if it already there
            this.courseStateManagement(dataInStore);
        }

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentWillReceiveProps(nextProps, thisProps){
        console.log(nextProps)
        
        if(nextProps.coursesDataOnlineSchool != 'error' && this.props.coursesDataOnlineSchool != nextProps.coursesDataOnlineSchool){ // check if there's change in courses data

            this.courseStateManagement(nextProps.coursesDataOnlineSchool);
           
        }

    }

    courseStateManagement(coursesData) {
         // console.log(nextProps.coursesData)
         this.setState({
            grades: coursesData.grades,
            filteredCourses: coursesData.coursesData.filteredCourses,
            loading: false
        },
        () => {
            console.log(this.state.filteredCourses);
            var sortedCoursesArray = [];
            // var sortedPopoverArray = [];
            var coursesKeys = Object.keys(this.state.filteredCourses);
            coursesKeys.map(course=>{
                
                // console.log(this.state.filteredCourses[course]);
                var gradeArray = [];
                // var popOverArray = [];
                var courseKey = Object.keys(this.state.filteredCourses[course]);
                var gradeName = this.state.filteredCourses[course][courseKey[0]].grade;
                courseKey.map(_course=>{
                    var thisCourse = this.state.filteredCourses[course][_course];
                    gradeArray.push(thisCourse);
                    // popOverArray.push(false);
                })
                sortedCoursesArray.push({
                    grade : gradeName,
                    courses : gradeArray,
                    visible : true
                })
                // sortedPopoverArray.push({
                //     courses : popOverArray
                // })

            })

            this.setState({
                sortedCourses : sortedCoursesArray,
                // popoverOpen: sortedPopoverArray
            }, () => {
                console.log(this.state.sortedCourses);
                // console.log(this.state.popoverOpen);
            })
            
        })
    }


    // filterCourses = (e, grade) => {
    //     var visibleList = this.state.visibleGradeList;
    //     if(!visibleList.includes(grade)){
    //         visibleList.push(grade);
    //         this.setState({
    //             visibleGradeList : visibleList
    //         },
    //         () => {
    //             // console.log(this.state.visibleGradeList)
    //         })
    //     }
    //     else{
    //         var index = visibleList.indexOf(grade);
    //         if (index > -1) {
    //             visibleList.splice(index, 1);
    //             this.setState({
    //                 visibleGradeList : visibleList
    //             },
    //             () => {
    //                 // console.log(this.state.visibleGradeList)
    //             })
    //         }
    //     }
    // }

    courseCLicked = (grade, value) => {

        this.state.sortedCourses.map(_grade => {
            // console.log(grade);
            if(_grade.grade == grade) {

                console.log(_grade)

                _grade.courses.map(course => {
                    if(course.course_name.indexOf(value) > -1) {
                        this.props.history.push('course/' + course.id);
                    }
                })
            }
        })

    }

    gradeClicked = (key, grade) => {

        this.setState({
            formStep : 2,
            selectedGrade : grade
        }, () => {
            console.log(key, grade)
            window.scrollTo(0, 0); //scroll to top
        })

    }
    
    handleScroll = (event) => {
        var scrollTop = window.scrollY;

        if(scrollTop > 300){
            if(!this.state.applyGrid){
                this.setState({
                    applyGrid : true
                })
            }
        }
        else{
            if(this.state.applyGrid){
                this.setState({
                    applyGrid : false
                })
            }
        }
    }

    
    render() {

        const {sortedCourses, popOverGradeId, popOverCourseId} = this.state;

        return (
        // <DocumentMeta {...meta}>
            <div className="AffordableEducationComponent" style={{background: 'url('+ storelistingbg +') fixed rgb(255, 255, 255)'}}>

                <Header />
                
                {/* <div className="homeEdtech" style={{backgroundImage: 'url('+ holyFridaySaleBannerPerMonth +')', backgroundColor: '#5c0a03', backgroundPosition: 'center right 50px', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
                    <Container>
                        <Row>
                            <Col md={{ size: 12 }}>
                                <h2 style={{fontSize: '3.5rem'}}><span style={{color: '#fff'}}>Holy</span> Friday <span style={{color: '#fff'}}>Sale</span></h2>
                                <h4 style={{fontSize: '2rem', color: '#f8ab24'}}><span style={{color: '#fff'}}>Online</span> School <span style={{color: '#fff'}}>Classes</span></h4>
                            </Col>
                        </Row>
                    </Container>
                </div> */}

                <div>
                    {/* <Media src={holyFridaySaleBannerPerMonth} width="100%" /> */}
                    <Container>
                        <Row>
                            {/* <Col md={{size : 12}} >
                            </Col> */}
                            {
                                this.state.disablePage ?
                                <Col style={{padding: '50px 20px'}}>
                                    <h3>
                                        Oops.<br/><br/>
                                        <span style={{fontWeight: 'bold'}}>Our Holy Friday sale has ended.</span>
                                        <br/>
                                        Follow us on Facebook to know about our next sale.
                                        <br/><br/>
                                        <a href="https://www.facebook.com/LincolnMethod" target="new">
                                            <Button size={'lg'} color="primary">
                                                Go to facebook page
                                            </Button>
                                        </a>
                                    </h3>
                                </Col>
                                :
                                
                                <Col md={{size : this.state.applyGrid ? 12 : 12 }}>
                                    <div className="homeEdtech" style={{background: 'transparent', padding: '7vh 0'}}>
                                        <Container>
                                            <Row>
                                            {
                                                !this.state.loading ?
                                                
                                                    this.state.formStep == 1 ?
                                                        <Col className={this.state.applyGrid ? "filterButtons stick" : "filterButtons"} md={{ size: 6, offset: 3 }} sm={{ size: 12 }}>
                                                            
                                                            <h2 style={{marginBottom: '20px'}}>Select a class to register</h2>

                                                            {
                                                                this.state.grades.length && this.state.grades.map((grade, key) => {
                                                                    console.log(grade)
                                                                    if(grade.indexOf('eneral') > -1){
                                                                        return false;
                                                                    } else {
                                                                        return (
                                                                            <Button size={'lg'}
                                                                                onClick={() => this.gradeClicked(key, grade)}
                                                                                key={key}
                                                                                id={'grade_' + key}
                                                                                // outline
                                                                                color="primary"
                                                                                block
                                                                                dangerouslySetInnerHTML={{__html: grade}}>
                                                                            </Button>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </Col>
                                                    :
                                                        <Col className={this.state.applyGrid ? "filterButtons stick" : "filterButtons"} md={{ size: 6, offset: 3 }} sm={{ size: 12 }}>
                                                            
                                                            <Button size={'sm'}
                                                                onClick={e => this.setState({
                                                                    formStep : 1
                                                                }) }
                                                                color="warning">Back</Button>

                                                            <h2 style={{margin: '30px 0', color: '#0062cc'}}>Select a subject</h2>

                                                            <Button size={'lg'}
                                                                onClick={() => this.courseCLicked(this.state.selectedGrade, 'English') }
                                                                id={'English'}
                                                                color="warning"
                                                                block>English</Button>

                                                            <Button size={'lg'}
                                                                onClick={() => this.courseCLicked(this.state.selectedGrade, 'Math') }
                                                                id={'Math'}
                                                                color="warning"
                                                                block>Math</Button>
                                                            
                                                        </Col>
                                                : null
                                            }
                                            </Row>
                                        </Container>
                                    </div>
                                </Col>   
                            }
                            
                        </Row>
                    </Container>
                </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

AffordableEducationComponent.propTypes = {
    // getAllCourses: PropTypes.func.isRequired
}

const mapStateToProps = state =>({
    coursesDataOnlineSchool : state.webRed.coursesDataOnlineSchool
})

export default connect(mapStateToProps, {  }) (AffordableEducationComponent);
  