import React, { Component } from "react";
import Header from './Header';
import Footer from "./Footer";

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";


class FaqComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

    componentDidMount() {
        document.title = "FAQ's - Lincoln Method";
    }
    
    render() {
        
        return (
        // <DocumentMeta {...meta}>
            <div className="FaqComponent">

                <Header />

                    <div className="homeEdtech" style={{backgroundColor: '#121625'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <h2>FAQ's</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="researchContent" style={{padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row style={{paddingBottom: '10px'}}>
                                <Col md={{ size: 12 }}>
                                    
<h4 style={{ marginTop: '4vh' }}>1. What subjects are presently ready with LM?</h4>


Skillupz has all kind of interesting courses for several types of learners. This includes students who are in Kindergarten all the way up to Grade 12. It also includes learners in a corporate environment for a full list of courses and descriptions please click on the following link.

http://skillupz.com/courses

<h4 style={{ marginTop: '4vh' }}>2. What levels/grades does it cater to (meaning which class)?</h4>


Skillupz has courses available for students from Kindergarten all the way up to Grade 12. Skillupz also has courses for the learners in the corporate sector.

Skillupz is open to partnership with educators and corporate trainers and can create blended learning opportunities for any classroom setting.


<h4 style={{ marginTop: '4vh' }}>3. What school curriculum does Skillupz follow?</h4>


Skillupz has meticulously addressed the curriculum and learning needs of multiple education boards including, IGCSE, GCE, CIE, CBSE, Edexcel , AKUEB, Metric and several other boards.


<h4 style={{ marginTop: '4vh' }}>4. What is minimum time period of a module validity (meaning how long does the program remain accessible)?</h4>


Skillupz provides on-demand learning courses. The module validity depends on the type of module created. On average, each module last for about 6-9 months.


<h4 style={{ marginTop: '4vh' }}>5. Can more than one person use same login?</h4>


 Each login is created for 1 person only.  The individual’s dashboard displays curated learning journey designed based on the individual’s weaknesses and strengths.


 <h4 style={{ marginTop: '4vh' }}>6. Can more then one person get trained using the same module?</h4>


Since every candidate has their own dashboard, unlimited candidates can use 1 module. However, each candidate will have their own grades and reports.


<h4 style={{ marginTop: '4vh' }}>7. Can programs be customized based on student requirements?</h4>


Skillupz can customize all of its programs to facilitate client’s need.


<h4 style={{ marginTop: '4vh' }}>8. What programs are readily available for corporate clients?</h4>


For a full list of courses and descriptions please click on the following link.

http://skillupz.com/courses


<h4 style={{ marginTop: '4vh' }}>9. Trust Corporate programs will need multiple accesses since it might typically be a dept with 5-10 members who wish to take on a particular program in a group.</h4>


Each member of the team will have their own dashboard which will keep track of their individual progress. However, multiple members can be part of a single program.


<h4 style={{ marginTop: '4vh' }}>10. How is each package priced.. ?</h4>


The pricing varies based upon our agreements. Usually all prices are mentioned in the course detail. For further information email us at
hello@skillupz.com
                                    
                                </Col>
                            </Row>
                        </Container>
                    </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default FaqComponent;