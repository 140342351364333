import React, { Component } from "react";
import "../../../public/scss/Partnerships.scss";
import Header from './..//Header';
import Footer from "./../Footer";
import IconGridSection from './../snippets/iconGridSection';

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

    
import icon_Math from './../../../public/images/partnerships/compton/Math.png';
import icon_Positivity from './../../../public/images/partnerships/compton/Positivity.png';
import icon_Teamwork from './../../../public/images/partnerships/compton/Teamwork.png';
import icon_Blockchain from './../../../public/images/partnerships/compton/Blockchain.png';
import icon_Passion from './../../../public/images/partnerships/compton/Passion.png';
import icon_Creativity from './../../../public/images/partnerships/compton/Creativity.png';

import xFactor from './../../../public/images/partnerships/compton/xFactor.jpeg';
import yesYouCan from './../../../public/images/partnerships/compton/yesYouCan.jpeg';

import billBrown from './../../../public/images/partnerships/compton/billBrown.jpg';
import saleemQureshi from './../../../public/images/partnerships/compton/saleemQureshi.jpg';

import squarelinkedin from './../../../public/images/partnerships/compton/squarelinkedin.png';

class EducateComptonComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            iconsData : {
                background: '#0c2e5d',
                heading: "<span style='color: #fff'>We will help kids RISE in 6 program</span>",
                data: [{ 
                        iconColor : '#fff',
                        iconImage : icon_Math,
                        iconLabel : 'Rise in Math'
                    },{ 
                        iconColor : '#fff',
                        iconImage : icon_Positivity,
                        iconLabel : 'Rise in Positivity'
                    },{ 
                        iconColor : '#fff',
                        iconImage : icon_Teamwork,
                        iconLabel : 'Rise in Teamwork'
                    },{ 
                        iconColor : '#fff',
                        iconImage : icon_Blockchain,
                        iconLabel : 'Rise in Blockchain'
                    },{ 
                        iconColor : '#fff',
                        iconImage : icon_Passion,
                        iconLabel : 'Rise in Passion'
                    },{ 
                        iconColor : '#fff',
                        iconImage : icon_Creativity,
                        iconLabel : 'Rise in Creativity'
                    }]
            }
        }
    }

    componentDidMount() {
        document.title = "Educate Compton - Lincoln Method";
    }
    
    render() {

        return (
            <div className="EducateComptonComponent">

                <Header />

                <div className="partnershipContent" style={{padding: '10vh 0'}}>
                    <Container style={{textAlign: 'left'}}>
                        <Row style={{paddingBottom: '10px'}}>
                            <Col md={{ size: 12 }}>
                                <h2>The "Compton Rising" project</h2>
                            </Col>
                            <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                <p>The year 2019 marks 400 years of Black history, and we would like to draw the community's attention to the state of education in Compton (Los Angeles).</p>
                                <p>This is why we are starting the <strong>Compton Rising project</strong> and we are inviting our close friends and their organizations to express their support as we grow this project.</p>
                                <p>We want to reach out to you and invite you to show your <strong>support</strong> for the Compton Rising Project.</p>
                                <p>By signing your support, your only obligation would be to speak positively of Compton, and endorse the education and blockchain for kids, within your social networks.</p>
                                <p>If you are an organization, we would like to post your logo, as a supporter, on this page as well.</p>
                                <p>We hope you will join us on this exciting journey.</p>
                            </Col>
                        </Row>
                    </Container>

                </div>

                <IconGridSection iconsData={this.state.iconsData}></IconGridSection>

                <div className="partnershipContent" style={{padding: '10vh 0', background: '#e8f700cf'}}>

                    <Container>
                        <Row>
                            <Col md={{size: 12}}>
                                <h1>Help someone RISE</h1>
                                <a href="https://www.gofundme.com/f/compton-rising">
                                    <Button className="BannerWithText_btn" size="lg" color="primary">
                                        Donate
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                    </Container>

                </div>

                <div className="partnershipContent" style={{padding: '10vh 0'}}>

                    <Container style={{textAlign: 'left'}}>
                        <Row>
                            <Col md={{size: 12}}>
                                <h2>Value Proposition</h2>
                                <ol>
                                    <li>Assess and then teach the kids on their weakness in:
                                    <ul>
                                        <li>Math Concepts</li>
                                        <li>Blockchain Basics</li>
                                        <li>Positive Outlook</li>
                                        <li>Teamwork</li>
                                        <li>Commitment to a Cause</li>
                                        <li>Creativity</li>
                                    </ul>
                                    </li>
                                    <li>Kids will be able to increase their grades by at least 20 percentage points (2 letter grades)</li>
                                    <li>Each child will be assigned to a career coach for the duration of the program</li>
                                    <li>Promote program participants to business leaders within California</li>
                                </ol>
                                <h2>Incentives for learning &amp; completing the program</h2>
                                <ol>
                                    <li>Kids will be given the tablets from the start of the program</li>
                                    <li>Kids get to enjoy recognition and acknowledgement at sporting event or a music event</li>
                                    <li>Kids will learn lessons to instill the right skills to become future leaders</li>
                                    <li>Kids will learn to practice confident communication as a result of the activity based practical learning sessions</li>
                                    <li>Documentaries for the top few kids will be created</li>
                                </ol>
                                <h2>Key Activities</h2>
                                <ul>
                                    <li>Kids can learn at home throughout the month using the tablets that have been given to them since the beginning of the program</li>
                                    <li>Over the weekends, the kids will be invited for group discussion and activity session to reinforce their concepts</li>
                                    <li>Short documentaries will be created on selected number of participating students</li>
                                </ul>
                                <h2>Intensity of learning</h2>
                                <ol>
                                    <li>Kids will be learning through:
                                    <ul>
                                        <li>16 learning sessions</li>
                                        <li>8 activity &amp; discussion</li>
                                    </ul>
                                    </li>
                                    <li>Learning sessions will be conducted individually at home</li>
                                    <li>Activity sessions &amp; discussion will take place in-class, and will be held on Friday afternoons, and Saturday mornings</li>
                                </ol>

                            </Col>
                            <Col md={{size: 12}}>
                                <Media style={{maxWidth: '100%'}} object src={xFactor} />
                                <Media style={{maxWidth: '100%'}} object src={yesYouCan} />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: '10vh'}}>
                            <Col md={{size: 12}}>
                                <h1>Our Trustees</h1>
                                <Row>
                                    <Col md={{size: 3}}>
                                        <Media style={{maxWidth: '100%', marginBottom: '15px'}} object src={billBrown} />
                                        <h3>Bill Brown <a target="new" href="#"><Media style={{width: '30px', display: 'inline-block'}} object src={squarelinkedin} /></a></h3>
                                    </Col>
                                    <Col md={{size: 3}}>
                                        <Media style={{maxWidth: '100%', marginBottom: '15px'}} object src={saleemQureshi} />
                                        <h3>Saleem Qureshi <a target="new" href="https://www.linkedin.com/in/saleemqureshi?originalSubdomain=pk"><Media style={{width: '30px', display: 'inline-block'}} object src={squarelinkedin} /></a></h3>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>

                </div>
                
                <Footer />
            </div>
        );
    }
}

export default EducateComptonComponent;