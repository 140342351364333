import React, { Component } from "react";
import "../../public/scss/ResearchComponent.scss";
import Header from './Header';
import Footer from "./Footer";

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

import TextWithImage from './snippets/TextWithImage';

import image1 from './../../public/images/research/1.jpeg';
import image2 from './../../public/images/research/2.jpeg';
import image3 from './../../public/images/research/3.jpeg';
import image4 from './../../public/images/research/4.jpeg';
import image5 from './../../public/images/research/5.jpeg';
import image6 from './../../public/images/research/6.jpeg';
import image7 from './../../public/images/research/7.jpeg';
import image8 from './../../public/images/research/8.jpeg';


class ResearchComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

    componentDidMount() {
        document.title = "Research - Skillupz";
    }
    
    render() {
        
        return (
        // <DocumentMeta {...meta}>
            <div className="ResearchComponent">

                <Header />

                    <div className="homeEdtech" style={{background: '#121625 center fixed / cover'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <h2>Let's educate kids for jobs that will be in high demand in the future</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="researchContent" style={{padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row style={{paddingBottom: '10px'}}>
                                <Col md={{ size: 12 }}>
                                    <h2 style={{color: '#f8ab24', fontWeight: 'bold'}}>List of 50 jobs that will be high demand</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ size: 12 }}>
                                <h4>1. Solar Energy Technician</h4>
                                Like wind energy, solar power will continue to be a major part of humanity's transition toward a clean-energy future. Median yearly pay for this occupation was $39,240 in 2016.
                                <Media object src={image1} alt="" />

                                <h4>2. Wind Energy Technician</h4>
                                With climate change threatening to severely damage the world as we know it, it will become more important to move to clean energy sources. The median salary in this field was $52,260 in 2016.

                                <h4>3. Nurse Practitioner</h4>
                                Because of an aging and longer-living population, the health care system may have trouble keeping up with the growing influx of patients. In 2016, median yearly pay for nurse practitioners was $100,910. And between 2016 and 2026, they could benefit from about 144,000 job openings.

                                <h4>4. Software Developer</h4>
                                Computers, robots, and mobile devices are useless without the well-engineered software that gives life to the sophisticated hardware it runs on. The median yearly pay for application software developers in 2016 was $100,080. 
                                <Media object src={image2} alt="" />

                                <h4>5. Physical Therapist</h4>
                                With more seniors in our communities, the need for physical therapy professionals will increase. In 2016, the median salary for physical therapists was $85,400.

                                <h4>6. Registered Nurse (RN)</h4>
                                In total, more than two million jobs are expected to become available for RNs between 2016 and 2026. The median salary for RNs in 2016 was $68,450

                                <h4>7. Health Services Manager</h4>
                                The health care sector may undergo more changes over the coming decades than most other industries. Health and medical services managers earned median yearly pay of $96,540 in 2016. And about 367,000 job openings may become available in this field over the decade that ends in 2026.

                                <h4>8. Data Analyst</h4>
                                Thanks to computing advances and a cultural shift toward more tracking and measuring, the amount of data that gets collected every year grows by an astonishing amount. he median salary for data analysts in May 2017 was $57,261.

                                <h4>9. Digital Content Specialist</h4>
                                One of the major cultural revolutions that keeps getting more entrenched is the move toward more dynamic, digital, interactive, and on-demand media. Because of digital devices that keep us constantly connected to almost any kind of information or entertainment we want to consume, the need for fresh content that breaks through the noise is never-ending.

                                <h4>10. Information Security Analyst</h4>
                                As our modern way of life gets more intertwined with computers and dependent on information technology (IT), we all become more vulnerable to cyberattacks. In 2016, they earned median yearly pay of $92,600.

                                <h4>11. Computer Systems Analyst</h4>
                                The reasons for getting an education in computer science will probably continue to multiply as information technology grows more complex and intertwined with everything in our lives. In 2016, the median salary in this field was $87,220.

                                <h4>12. Biomedical Engineer</h4>
                                Professionals in this field are already starting to revolutionize the health care industry. In fact, biomedical engineering is probably one of the best careers to get into if you want your work to have a positive impact in the years ahead. Biomedical engineers enjoyed a median salary of $85,620 in 2016.

                                <h4>13. Mechanical Engineering Specialist</h4>
                                Do you want to help develop some of the most exciting emerging technologies? Increasingly, mechanical engineers and mechanical engineering technicians are involved in the design and testing of things like advanced robots, automation equipment, 3D-printing machines, and clean energy devices. The technicians who helped them made $54,480.

                                <h4>14. Electronics Engineering Specialist</h4>
                                Like mechanical engineering pros, a lot of people in this field get to help design, test, and evaluate leading-edge technologies. As electronic circuitry and other components get smaller, more complex, and more powerful, it's up to these professionals to figure out how to take advantage of the latest technological advances. From 2016 to 2026, job openings are expected to total 51,000 for computer hardware engineers, 92,000 for other electronics engineers, and 120,000 for electronics engineering technicians. In 2016, median salaries were $115,080 for computer engineers, $99,210 for other electronics engineers, and $62,190 for technicians.

                                <h4>15. Digital Rehab Counselor</h4>
                                Do you ever feel overwhelmed by the incredible amount of digital information you consume in a day? Many people do. As more and more of our lives revolve around social media and other online activities, there's a growing awareness that a lot of us are actually addicted to the technology we use. People with counseling training will likely be the best-equipped to pursue this type of job.

                                <h4>16. Blockchain Developer</h4>
                                You've probably heard of the digital cryptocurrency called Bitcoin. But how much do you know about the underlying technology that makes it possible? Blockchain technology works as a distributed cryptographic ledger that can make economic and other types of transactions decentralized, faster, and more private. That's why professionals who understand how to develop practical services and products with blockchain technology will probably be in high demand well into the future. 
                                <Media object src={image3} alt="" />

                                <h4>17. Civil or Commercial Drone Pilot or Dispatcher</h4>
                                The government is figuring out the best way to regulate the use of drones. After all, people continue to dream up new ways to take advantage of their remote flight capabilities. So some companies and public agencies are beginning to put together teams of drone pilots and dispatchers to manage their fleets of small remote-controlled aircraft. 
                                <Media object src={image4} alt="" />

                                <h4>18. Smart-Building Technician</h4>
                                With each passing year, more homes, offices, and factories are taking advantage of automation technology to control various building systems such as lighting, heating, air conditioning, and security. So tradespeople such as HVAC technicians are starting to become smart-building technicians.

                                <h4>19. 3D-Printing Technician</h4>
                                Many futurists believe that we've only just begun to scratch the surface of what 3D-printing technology (aka additive manufacturing) will ultimately be capable of achieving. So technicians who specialize in maintaining 3D-printing equipment will likely be in growing demand. 
                                <Media object src={image5} alt="" />

                                <h4>20. Augmented Reality Developer</h4>
                                Is the real world too boring? Does it need some visual enhancements that provide extra information or entertainment value? Some people think so. That's why they're developing ways to superimpose digital images and information onto a person's normal perception of the world using special glasses, bionic contact lenses, or other technologies. So augmented reality developers are needed to intelligently design and plan the safe and effective use of this technology.

                                <h4>21. Personal Privacy Advisor</h4>
                                As technology evolves and touches more and more aspects of our lives, it's getting harder and harder to maintain our privacy. It's a challenge that will only get more complex as facial recognition and augmented reality technologies become widely adopted. So professionals who specialize in helping people protect their privacy—without losing out on some of the main benefits of social media or other technologies—will likely find themselves in demand.

                                <h4>22. Personal Brand Advisor</h4>
                                Even among large, established organizations, hiring freelance professionals for short-term projects is becoming a pretty big trend that may expand well into the future. In fact, many futurists foresee a time when most jobs will be done on a temporary contract basis. Personal brand advisors will help their clients establish and maintain public personas and professional reputations that make them stand apart from the competition.

                                <h4>23. Robotics Service Technician</h4>
                                In the decades to come, almost every home could have sophisticated robots that assist with routine housework such as laundry, cooking, cleaning, and lawn maintenance. But those robots probably won't always work as they should. In fact, robot technicians may eventually become just as commonplace as plumbers, electricians, auto mechanics, and other types of skilled tradespeople. 
                                <Media object src={image6} alt="" />

                                <h4>24. Remote Health Care Engineer</h4>
                                As robotics and communications technologies get more advanced, it's becoming possible for doctors and other health care specialists to consult with patients without being physically present. Technology is even being developed and perfected to allow surgeons to perform remote operations with the assistance of robots. In the future, many more remote health care engineers will develop and coordinate the use of advanced technology that allows patients to get diagnosed and treated by almost any medical specialist in the world, regardless of where he or she is physically located.

                                <h4>25. Urban Agriculture Specialist</h4>
                                Here's a startling fact: Each year, the urban population on Earth grows by the equivalent of about seven Chicago-sized cities. That means, from 2010 to 2050, the global population of city dwellers will double, reaching about 5.2 billion people. So in order for cities to be sustainable, they'll need to grow their own food using sophisticated hydroponics and vertical farming methods. Demand for professional urban farmers will likely grow as more cities and urban dwellers realize that their survival depends on growing food locally. 
                                <Media object src={image7} alt="" />

                                <h4>26. Nanotech Engineer</h4>
                                By manipulating matter at the level of atoms or molecules, it has become possible to imagine and develop a wide range of new innovations. For example, many people in this field envision a future in which self-replicating nanostructures are used in construction and nano-robots are put into the human body to assist the immune system and cure diseases. Nanoscale technologies are already being used to create advanced materials, and they're being incorporated into some electronics and consumer products (like clothing). As this technology matures, nanotech engineers will need to ensure that their creations don't pose a risk to the environment or human health.

                                <h4>27. Biomech Technician</h4>
                                Bionic limbs continue to get more sophisticated. It's possible that, one day, even people without any disabilities will want biomechanical add-ons in order to enjoy super-human capabilities. But the technology will probably be so complex that only people with specialized skill sets will be able to repair it, upgrade it, or tune it up.

                                <h4>28. Genetic Engineer</h4>
                                The idea of custom-designed and made-to-order babies may make a lot of us feel squeamish right now, but people's attitudes could change as genetic engineering matures and more companies begin to commercialize the resulting technology. It will be up to governments to decide how to regulate an industry that will benefit from a lot of pent-up demand.

                                <h4>29. Tech Ethicist</h4>
                                Humanity still hasn't fully learned that we shouldn't do something just because we can. Ethicists have the job of trying to help us avoid learning that lesson the hard way. How much information are we entitled to know about other people? The questions are almost limitless given how quickly technology is changing our world.

                                <h4>30. Virtual Reality Designer</h4>
                                Most people like to travel and explore places they've never been. However, you don't necessarily have to physically travel anywhere in order to see and experience new places. Talented VR designers may become highly sought-after as VR developers race to fill a growing demand for more sophisticated, realistic, and imaginative virtual experiences. 
                                <Media object src={image8} alt="" />

                                <h4>31. Problem-Solving Competition Director</h4>
                                Some people believe that solutions to humanity's biggest problems can be discovered by creating competitions with large monetary prizes for the winners. Competitions of this nature may multiply at a faster rate, which will require professionals who can produce and manage them.

                                <h4>32. Synthetic Biology Engineer</h4>
                                Did you know that scientists in this field are already creating lab-grown meat that doesn't require any farm animals to be raised or killed? That is just the tip of the iceberg when it comes to the staggering possibilities offered by synthetic biology. With their knowledge of molecular biology, physics, chemistry, and engineering, people in this occupation may eventually create all kinds of artificial organisms or redesign existing natural ones to deliver medical and industrial breakthroughs that nobody has imagined yet.

                                <h4>33. Project-Based Human Resources Director</h4>
                                With more companies moving toward project-based employment models, it's likely that specialized human resources professionals will increasingly be needed for choosing and coordinating the best freelance workers for individual projects. In fact, organizations may hire project directors the same way movie studios hire film directors Along with skills in human resources, this type of professional may also need a deep understanding of project management.

                                <h4>34. Weather Control Engineer</h4>
                                Climate change is already being linked to many droughts, floods, and various severe and unexpected weather events. But as climate science advances, it may become possible to safely and effectively manipulate the weather in predictable ways. Geoengineering is already happening at smaller scales. It might not be able to reverse all the effects of global warming, but it could give us the tools to ensure that certain regions aren't completely devastated by it. If the science advances that far, then some of the top jobs for the future will probably be related to controlling the weather.

                                <h4>35. Organizational Disrupter</h4>
                                As established companies and other organizations expand, they often lose some of their ability to innovate. The role of a disrupter would be to introduce a few seemingly chaotic changes in an organization that promote more creativity, risk-taking, collaboration, and innovation.

                                <h4>36. Personal Education Guide</h4>
                                Going to school is still an effective way to learn and get recognized credentials. Personal education guides may act as coaches and counselors in helping people choose on-demand courses or designing customized training plans that utilize freelance instructors. They may provide confirmation that their clients have successfully completed those courses and earned alternative credentials that more and more employers will recognize as being valid.

                                <h4>37. Custom Body Part Manufacturer</h4>
                                At some point, waiting lists for organ transplants will probably become a thing of the past. Soon, doctors may be able to order up custom organs that are grown or 3D-printed using their patients' own cells. They've even grown skin, ears, and other external body parts.

                                <h4>38. Brain Implant Specialist</h4>
                                The human brain is incredibly complex, but mankind's understanding of how it works is growing faster than most of us probably realize. As we combine the rapid advances in neuroscience with the amazing advances in computer technology, we'll end up with some truly amazing possibilities. Special computer chips may one day be implanted into people's brains for benefits such as virtual telepathy, memory enhancements, disease management, mood regulation, paralysis treatments, and much more.

                                <h4>39. Personal Microbiome Manager</h4>
                                When it comes to what you can do with a biology degree, this job probably isn't an option quite yet, but it could become a good one in the decades ahead. So specialists who know how to manage our personal microbiota may become just as important as today's physicians.

                                <h4>40. Pharmaceutical Artisan</h4>
                                The pharmacy careers of today will probably be around for a long time. But as 3D printing grows more widespread, it may become possible to quickly produce customized medications for people on an on-demand basis (rather than giving out mass-produced meds). Some pharmaceutical artisans may even create targeted treatments based on a person's own stem cells.

                                <h4>41. Urban Security Coordinator</h4>
                                In a fast-changing world with more densely populated cities, keeping people safe from one another may become increasingly challenging. That could lead to new positions for security pros with specialized skills in coordinating human workers and robots, artificial intelligence, and other advanced technologies within urban environments.

                                <h4>42. End-of-Life Manager or Memorializer</h4>
                                With our culture and economy increasingly focused on the personal brands of individuals, it's possible that many people, well in advance of their deaths, will want to plan elaborate memorial services and celebrations that are more extravagant than the kind offered by most funeral directors today. If so, professionals will be needed who can help coordinate major end-of-life celebrations and craft engaging tributes about dying individuals' lives and legacies.

                                <h4>43. Hyper-Intelligent Transportation Engineer</h4>
                                We're quickly moving toward a future in which humans will no longer be the operators of transport vehicles. Self-driving cars are probably only the beginning. A new paradigm of smart roads, vacuum-tube tunnels, maglev trains, and many other advanced transportation systems could replace our current one.

                                <h4>44. Cyborg Designer</h4>
                                By combining the best aspects of a natural organism with those of an advanced robot, it may be possible to create a new entity that is superior to the sum of its parts. So, in the future, highly creative cyborg designers might be in demand as humans try to merge themselves with machines in order to transcend their natural limitations.

                                <h4>45. Robotic or Holographic Avatar Designer</h4>
                                With people spending more of their time in virtual worlds, they might come to be a little disappointed with the real world. They might want to interact with their virtual friends or significant others without having to wear their VR gear. Special designers will be needed to help create custom avatars that are just as appealing or imaginative in the real world as they are in the virtual ones they inhabit.

                                <h4>46. Space Tourism Guide</h4>
                                Companies like SpaceX and Virgin Galactic already have plans well underway to commercialize space travel for the public. In the relatively near future, space tourists may be going into orbit for family holidays, a little adventure, or even to conduct business. That's why special guides will be needed to help people prepare for space travel and get adjusted to the new environment once they're in orbit.

                                <h4>47. Space Nurse or Physician</h4>
                                Going into outer space presents all kinds of distinct medical challenges. The human body simply isn't designed for a zero-gravity environment. Specialized doctors and nurses will likely be needed for extended stays in space in order to look after the many ordinary people who will take trips beyond Earth's atmosphere.

                                <h4>48. Landfill Worm Operator</h4>
                                Earth doesn't have an endless supply of natural resources. The rapid industrialization of the world is beginning to cause shortages of some mined resources. Some futurists envision a future in which robotic earthworms, partially operated by humans, mine landfills and extract the valuable resources while filling in the resulting voids with good-quality soil.

                                <h4>49. Extinct Species Revivalist</h4>
                                Human actions continue to cause the extinction of various plants, animals, and microorganisms. In many cases, we don't realize how important those species are to the health of the world's ecosystems until they're gone. So conserving threatened species is often essential. But science may also advance to the point that we can actually bring back some of the species that have already gone extinct and reintegrate them into their natural environments.

                                <h4>50. Android Relationship Counselor</h4>
                                We're still a long way off from being able to date robots that have human intelligence. But some scientists believe that artificial intelligence will eventually advance enough to make that possible. That will be especially true if we ever reach the Singularity—the theoretical point in the future when autonomous machines become more intelligent than humans.
                                </Col>
                            </Row>
                        </Container>
                    </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default ResearchComponent;