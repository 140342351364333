import {

	getCoursesSuccess,
	getCoursesSuccessOnlineSchool,
	GET_ALL_CLIENTS,
	getCourseDetailSuccess,
	getQuestionHtmlSuccess,
	_handleSocialLoginSuccess,
	_loginSuccess,
	_loginSuccess2,
	_signupSuccess,
	GET_SIGNUP_FORM_TYPE,
	SHOW_LOGIN_POPUP,
	SHOW_ENROLLMENT_POPUP,
	SHOW_FORGOT_PASS,
	SHOW_CHECKOUT_POPUP,
	GET_EXTRA_STORE_FIELDS,
	SHOW_CONGRATULATIONS,
	_loginSessionEncrypted,
	_checkoutCoupon,
	_checkoutCashOnDevliverySuccess,
	_checkoutJazzCashSuccess,
	_forgotPasswordMessage,
	_validateEnroll,
	_validateEmailExists,
	_addiotionalJazzCheckoutDetails,
	_checkPhoneNumber,
	_validatePhoneVerification,
	_checkIfUsersExists,
	_triggerNewUserType,
	_checkIfUserNameExists,
	_triggerNewUserPassword,
	_triggerNewUserDetails,
	_triggerNewUserEmail,
	_signupSuccessPhone,
	_checkIfThisColumnExists,
	_saveCard,
	_saveCardDirect,
	_creatpaymentmethod,
	_checkoutDoneFinal
	// _cartUpdateDone,
	// _cartRemoveDone

} from './types';

import { toast } from 'react-toastify';

import { getCurrentClient, getBaseUrl , LiveKeysPayMango  , TestingKeysPayMango} from './utility';

import axios from 'axios';
import ndjsonStream from 'can-ndjson-stream';
import { platform } from 'os';
import tokenProvider from 'axios-token-interceptor';
import { async } from 'q';

const instance = axios.create({
	baseURL: getBaseUrl() + '/'
	// baseURL: 'https://neuro-lincoln.local/'
});

// Configure the provider with the necessary options.
// const options = {
// 	token: 'abc',
// 	headers: {
// 		'Content-Type': 'application/json'
// 	}
// };
// const options = {};
// instance.interceptors.request.use(tokenProvider(options));

// export const login = (userInfo) => async (dispatch) => {
// 	const res = await axios
// 		.post('https://dev.iponym.com/getdata/signin', userInfo, {
// 			headers: {
// 				'Content-Type': 'application/json'
// 			}
// 		})
// 		.then((res) => {
// 			dispatch({
// 				type: LOG_IN_SUCCESS,
// 				payload: res.status
// 			});
// 			console.log(res);
// 		})
// 		.catch((error) => {
// 			dispatch({
// 				type: LOG_IN_FAILURE,
// 				payload: 'error'
// 			});
// 			console.log(error);
// 		});
// };

export const getAllCourses = () => async (dispatch) => {
	instance.get('api/v1/store?storename=skillupz')
		.then((res) => {

			if (res.data.status == "success") { // if success only
				dispatch({
					type: getCoursesSuccess,
					payload: res.data.result
				});
			}
			else {
				dispatch({
					type: getCoursesSuccess,
					payload: 'error'
				})
			}

		})
		.catch((error) => {
			dispatch({
				type: getCoursesSuccess,
				payload: 'error'
			});
		});
};

export const getAllOnlineSchoolCourses = () => async (dispatch) => {
	instance.get('api/v1/store?is_onlineschool=1&storename=skillupz')
		.then((res) => {

			if (res.data.status == "success") { // if success only
				dispatch({
					type: getCoursesSuccessOnlineSchool,
					payload: res.data.result
				});
			}
			else {
				dispatch({
					type: getCoursesSuccessOnlineSchool,
					payload: 'error'
				})
			}

		})
		.catch((error) => {
			dispatch({
				type: getCoursesSuccessOnlineSchool,
				payload: 'error'
			});
		});
};

export const getCourseDetails = (id) => async (dispatch) => {
	var bodyFormData = new FormData();
	bodyFormData.set('courseid', id);

	instance.post('api/v1/store/storedetail', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
		.then((res) => {
			console.log(res)
			if (res.data.status == "success") { // if success only
				dispatch({
					type: getCourseDetailSuccess,
					payload: res.data.result
				});
			}
			else {
				dispatch({
					type: getCourseDetailSuccess,
					payload: 'error'
				})
			}
		})
		.catch((error) => {
			console.log(error)
			dispatch({
				type: getCourseDetailSuccess,
				payload: 'error'
			});
		});

};

export const getTestPreview = (data) => async (dispatch) => {

	var postData = new FormData();
	postData.set('_token', data.token);
	postData.set('questionid', data.questionid);

	instance.post('chkquestion', postData)
		.then((res) => {

			if (res.data == 1) {

				var postData2 = new FormData();

				postData2.set('_token', data.token);
				postData2.set('questionid', data.questionid);
				postData2.set('iscorrect', 0);
				postData2.set('testid', data.testid);
				postData2.set('program', data.program_id);
				postData2.set('assess_id', data.assessment_id);
				postData2.set('practice', '0');
				postData2.set('next', '0');
				postData2.set('is_skipped', '0');
				postData2.set('frmnextqs', '0');
				postData2.set('isback', '1');
				postData2.set('ishistory', '1');
				postData2.set('zero', 0);
				postData2.set('is_react', 1);

				instance.post('loadoboquestionclient', postData2)
					.then((res) => {
						dispatch({
							type: getQuestionHtmlSuccess,
							payload: res.data
						});
					})
					.catch((error) => {
						dispatch({
							type: getQuestionHtmlSuccess,
							payload: 'error'
						});
					});

			}

		})
		.catch((error) => {
			dispatch({
				type: getQuestionHtmlSuccess,
			})
		});

};

export const _handleSocialLogin = (socialType, socialData, storeId, courseId, isPurchase) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('social_data', JSON.stringify(socialData));
	bodyFormData.set('social_type', socialType);
	bodyFormData.set('storeid', storeId);
	bodyFormData.set('courseid', courseId);
	bodyFormData.set('purchase', isPurchase);

	instance.post('api/v1/social/login', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
		.then((res) => {
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _handleSocialLoginSuccess,
					payload: res.data
				});
			}
			else {
				if (res.data.status == 'error') {
					dispatch({
						type: _handleSocialLoginSuccess,
						payload: res.data.message
					})
				}
				else {
					dispatch({
						type: _handleSocialLoginSuccess,
						payload: 'error'
					})
				}
			}
		})
		.catch((error) => {
			dispatch({
				type: _handleSocialLoginSuccess,
				payload: 'error'
			});
		});

};

export const doLogin = (userEmail, userPass, courseId, isPurchase, loginType, isPassRequired) => async (dispatch) => {

	console.log(userEmail, ':recieve the client params:', userPass);

	var bodyFormData = new FormData();
	var currentClient = getCurrentClient();

	bodyFormData.set('email', userEmail);

	bodyFormData.set('password', userPass);

	bodyFormData.set('courseid', courseId);

	bodyFormData.set('purchase', isPurchase);

	bodyFormData.set('isPassRequired', isPassRequired);

	bodyFormData.set('storeid', currentClient.id);

	instance.post('api/v1/signin', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
		.then((res) => {
			if (res.data.status == "success") { // if success only
				if (loginType == 'normal') {
					dispatch({
						type: _loginSuccess2,
						payload: res.data
					});
				}
				else {
					dispatch({
						type: _loginSuccess,
						payload: res.data
					});
				}
			}
			else {
				if (res.data.status == 'error') {
					if (loginType == 'normal') {
						dispatch({
							type: _loginSuccess2,
							payload: res.data
						})
					}
					else {
						dispatch({
							type: _loginSuccess,
							payload: res.data
						})
					}
				}
				else {
					if (loginType == 'normal') {
						dispatch({
							type: _loginSuccess2,
							payload: 'error'
						})
					}
					else {
						dispatch({
							type: _loginSuccess,
							payload: 'error'
						})
					}
				}
			}
		})
		.catch((error) => {
			if (loginType == 'normal') {
				dispatch({
					type: _loginSuccess2,
					payload: 'error'
				});
			}
			else {
				dispatch({
					type: _loginSuccess,
					payload: 'error'
				});
			}
		});
}

export const doLoginSession = (userEmail, userPass) => async (dispatch) => {

	var bodyFormData = new FormData();
	var currentClient = getCurrentClient();

	bodyFormData.set('email', userEmail);
	bodyFormData.set('password', userPass);
	bodyFormData.set('storeid', currentClient.id);

	instance.post('api/v1/login/session', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
		.then((res) => {
			console.log('loginSession', res);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _loginSessionEncrypted,
					payload: res.data
				});
			}
			else {
				if (res.data.status == 'error') {
					dispatch({
						type: _loginSessionEncrypted,
						payload: res.data
					});
				}
				else {
					dispatch({
						type: _loginSessionEncrypted,
						payload: 'error'
					});
				}
			}
		})
		.catch((error) => {
			dispatch({
				type: _loginSessionEncrypted,
				payload: 'error'
			});
		});
}

export const triggerLoginPopup = () => async (dispatch) => {

	dispatch({
		type: SHOW_LOGIN_POPUP,
		payload: Math.random()
	});

};

export const triggerEnrollPopup = () => async (dispatch) => {

	dispatch({
		type: SHOW_ENROLLMENT_POPUP,
		payload: Math.random()
	});

};

export const triggerForgotPassPopup = (login_email) => async (dispatch) => {

	dispatch({
		type: SHOW_FORGOT_PASS,
		payload: {
			emailPersist: login_email,
			random: Math.random()
		}
	});

};

export const triggerCheckoutPopup = () => async (dispatch) => {

	dispatch({
		type: SHOW_CHECKOUT_POPUP,
		payload: Math.random()
	});

};

export const getSignUpFormType = (id) => async (dispatch) => {


	var bodyFormData = new FormData();
	bodyFormData.set('courseid', id);

	instance.post('api/v1/checkcoursedetail', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
		.then((res) => {

			console.log(res)
			if (res.data.status == "success") { // if success only
				dispatch({
					type: GET_SIGNUP_FORM_TYPE,
					payload: res.data.result
				});
			}
			else {
				dispatch({
					type: GET_SIGNUP_FORM_TYPE,
					payload: 'error'
				})
			}
		})
		.catch((error) => {
			dispatch({
				type: GET_SIGNUP_FORM_TYPE,
				payload: 'error'
			});
		});

};

export const getAllClientsData = () => async (dispatch) => {

	instance.get('api/v1/lmclients')
		.then((res) => {
			if (res.data.status == "success") { // if success only
				dispatch({
					type: GET_ALL_CLIENTS,
					payload: res.data.result
				});

				var currentClient = getCurrentClient();
				var formData = new FormData();
				formData.set('storeid', currentClient.id); // store id static for skillupz

				instance.post('api/v1/lmclientsextrafields', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
					.then((res) => {
						console.log(res)
						if (res.data.status == "success") { // if success only
							dispatch({
								type: GET_EXTRA_STORE_FIELDS,
								payload: res.data.result
							});
						}
						else {
							dispatch({
								type: GET_EXTRA_STORE_FIELDS,
								payload: []
							})
						}
					})
					.catch((error) => {
						dispatch({
							type: GET_EXTRA_STORE_FIELDS,
							payload: []
						});
					});

			}
			else {
				dispatch({
					type: GET_ALL_CLIENTS,
					payload: 'error'
				})
			}
		}).catch((error) => {
			dispatch({
				type: GET_ALL_CLIENTS,
				payload: 'error'
			});
		});

};

export const doRegister = (
	formtype,
	txt_email,
	txt_password,
	txt_parent_email,
	txt_fname,
	txt_lname,
	txt_parent_name,
	txt_parent_contact,
	sel_parent,
	sel_gender,
	txt_dob,
	txt_grade,
	txt_phone,
	storeid,
	txt_additional_,
	courseid,
	purchase,
	clientUrl,
	clientUrlProtocol
) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('formtype', formtype);
	bodyFormData.set('txt_email', txt_email);
	bodyFormData.set('txt_password', txt_password);
	bodyFormData.set('txt_parent_email', txt_parent_email);
	bodyFormData.set('txt_fname', txt_fname);
	bodyFormData.set('txt_lname', txt_lname);
	bodyFormData.set('txt_parent_name', txt_parent_name);
	bodyFormData.set('txt_parent_contact', txt_parent_contact);
	bodyFormData.set('sel_parent', sel_parent);
	bodyFormData.set('sel_gender', sel_gender);
	bodyFormData.set('txt_dob', txt_dob);
	bodyFormData.set('txt_grade', txt_grade);
	bodyFormData.set('txt_phone', txt_phone);
	bodyFormData.set('storeid', storeid);
	bodyFormData.set('txt_additional_', txt_additional_);
	bodyFormData.set('courseid', courseid);
	bodyFormData.set('purchase', purchase);
	bodyFormData.set('clientUrl', clientUrl);
	bodyFormData.set('clientUrlProtocol', clientUrlProtocol);

	instance.post('api/v1/signup', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		console.log(res)
		if (res.data.status == "success") { // if success only
			dispatch({
				type: _signupSuccess,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: _signupSuccess,
				payload: res.data.message + '_r_' + Math.random()
			})
		}
	}).catch((error) => {
		dispatch({
			type: _signupSuccess,
			payload: "error"
		});
	});
}

export const triggerCongratulationsAnimation = () => async (dispatch) => {

	dispatch({
		type: SHOW_CONGRATULATIONS,
		payload: Math.random()
	});

};


export const applyCheckoutCoupon = (courseid, couponcode, dayid) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('courseid', courseid);
	bodyFormData.set('couponcode', couponcode);
	bodyFormData.set('dayid', dayid);

	console.log(courseid, couponcode, dayid);

	instance.post('api/v1/validatecoupon', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		if (res.data.status == "success") { // if success only
			dispatch({
				type: _checkoutCoupon,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: _checkoutCoupon,
				payload: res.data.message
			})
		}
	}).catch((error) => {
		dispatch({
			type: _checkoutCoupon,
			payload: "error"
		});
	});
}

export const paymentCashOnDelivery = (userid,
	courseid,
	couponcode,
	dayid,
	payinclass_details,
	order_address,
	pickupmethod,
	location,
	contact_number,
	word_problem,
	logic_reasoning,
	reading_comprehennsion,
	grammer_vocabulary,
	lincoln_method,
	macmillan_education,
	mcgraw_hill,
	scholastic,
	combination_resources
) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('userid', userid);
	bodyFormData.set('courseid', courseid);
	bodyFormData.set('couponcode', couponcode);
	bodyFormData.set('dayid', dayid);
	bodyFormData.set('payinclass_details', payinclass_details);
	bodyFormData.set('order_address', order_address);
	bodyFormData.set('pickupmethod', pickupmethod);
	bodyFormData.set('location', location);
	bodyFormData.set('contact_number', contact_number);
	bodyFormData.set('word_problem', word_problem);
	bodyFormData.set('logic_reasoning', logic_reasoning);
	bodyFormData.set('reading_comprehennsion', reading_comprehennsion);
	bodyFormData.set('grammer_vocabulary', grammer_vocabulary);
	bodyFormData.set('lincoln_method', lincoln_method);
	bodyFormData.set('macmillan_education', macmillan_education);
	bodyFormData.set('mcgraw_hill', mcgraw_hill);
	bodyFormData.set('scholastic', scholastic);
	bodyFormData.set('combination_resources', combination_resources);


	console.log('userid', userid);
	console.log('courseid', courseid);
	console.log('couponcode', couponcode);
	console.log('dayid', dayid);
	console.log('payinclass_details', payinclass_details);
	console.log('order_address', order_address);
	console.log('pickupmethod', pickupmethod);
	console.log('location', location);
	console.log('contact_number', contact_number);
	console.log('word_problem', word_problem);
	console.log('logic_reasoning', logic_reasoning);
	console.log('reading_comprehennsion', reading_comprehennsion);
	console.log('grammer_vocabulary', grammer_vocabulary);
	console.log('lincoln_method', lincoln_method);
	console.log('macmillan_education', macmillan_education);
	console.log('mcgraw_hill', mcgraw_hill);
	console.log('scholastic', scholastic);
	console.log('combination_resources', combination_resources);

	instance.post('api/v1/class/checkout', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		console.log(res);
		if (res.data == "") {
			dispatch({
				type: _checkoutCashOnDevliverySuccess,
				payload: {
					orderStatus: res.data.orderStatus,
					response: "already" + Math.random()
				}
			});
		}
		else {
			if (res.data.status == "success" && res.data.orderStatus != 0) { // if success only
				dispatch({
					type: _checkoutCashOnDevliverySuccess,
					payload: {
						orderStatus: res.data.orderStatus,
						response: "success" + Math.random()
					}
				});
			}
			else {
				dispatch({
					type: _checkoutCashOnDevliverySuccess,
					payload: {
						orderStatus: res.data.orderStatus,
						response: "error" + Math.random()
					}
				})
			}
		}
	}).catch((error) => {
		dispatch({
			type: _checkoutCashOnDevliverySuccess,
			payload: {
				orderStatus: "",
				response: "error" + Math.random()
			}
		});
	});
}

export const paymentJazzCashMethod = (userid,
	courseid,
	couponcode,
	dayid,
	// payinclass_details,
	order_address,
	pickupmethod,
	location,
	contact_number,
	word_problem,
	logic_reasoning,
	reading_comprehennsion,
	grammer_vocabulary,
	lincoln_method,
	macmillan_education,
	mcgraw_hill,
	scholastic,
	combination_resources
) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('userid', userid);
	bodyFormData.set('courseid', courseid);
	bodyFormData.set('couponcode', couponcode);
	bodyFormData.set('dayid', dayid);
	// bodyFormData.set('payinclass_details', payinclass_details);
	bodyFormData.set('order_address', order_address);
	bodyFormData.set('pickupmethod', pickupmethod);
	bodyFormData.set('location', location);
	bodyFormData.set('contact_number', contact_number);
	bodyFormData.set('word_problem', word_problem);
	bodyFormData.set('logic_reasoning', logic_reasoning);
	bodyFormData.set('reading_comprehennsion', reading_comprehennsion);
	bodyFormData.set('grammer_vocabulary', grammer_vocabulary);
	bodyFormData.set('lincoln_method', lincoln_method);
	bodyFormData.set('macmillan_education', macmillan_education);
	bodyFormData.set('mcgraw_hill', mcgraw_hill);
	bodyFormData.set('scholastic', scholastic);
	bodyFormData.set('combination_resources', combination_resources);


	console.log('userid', userid);
	console.log('courseid', courseid);
	console.log('couponcode', couponcode);
	console.log('dayid', dayid);
	// console.log('payinclass_details', payinclass_details);
	console.log('order_address', order_address);
	console.log('pickupmethod', pickupmethod);
	console.log('location', location);
	console.log('contact_number', contact_number);
	console.log('word_problem', word_problem);
	console.log('logic_reasoning', logic_reasoning);
	console.log('reading_comprehennsion', reading_comprehennsion);
	console.log('grammer_vocabulary', grammer_vocabulary);
	console.log('lincoln_method', lincoln_method);
	console.log('macmillan_education', macmillan_education);
	console.log('mcgraw_hill', mcgraw_hill);
	console.log('scholastic', scholastic);
	console.log('combination_resources', combination_resources);

	instance.post('api/v1/addorderjazzcash', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		console.log(res);
		dispatch({
			type: _checkoutJazzCashSuccess,
			payload: res.data
		})
		// if(res.data == ""){
		// 	dispatch({
		// 		type: _checkoutCashOnDevliverySuccess,
		// 		payload: "already" + Math.random()
		// 	});
		// }
		// else{
		// 	if (res.data.status == "success") { // if success only
		// 		dispatch({
		// 			type: _checkoutCashOnDevliverySuccess,
		// 			payload: "success" + Math.random()
		// 		});
		// 	}
		// 	else {
		// 		dispatch({
		// 			type: _checkoutCashOnDevliverySuccess,
		// 			payload: "success" + Math.random()
		// 		})
		// 	}
		// }
	}).catch((error) => {
		console.log(error);
		dispatch({
			type: _checkoutJazzCashSuccess,
			payload: error
		})
		// dispatch({
		// 	type: _checkoutCashOnDevliverySuccess,
		// 	payload: "error"
		// });
	});
}


export const forgotPasswordAction = (email) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('txt_email', email);

	instance.post('api/v1/password/forgot', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		console.log(res);
		dispatch({
			type: _forgotPasswordMessage,
			payload: res
		})
	}).catch((error) => {
		console.log(error);
		dispatch({
			type: _forgotPasswordMessage,
			payload: error
		})
	});
}

export const validateCourseAvailability = (userid, courseid, usertype, clientid) => async (dispatch) => {

	var bodyFormData = new FormData();
	var currentClient = getCurrentClient();

	bodyFormData.set('userid', userid);
	bodyFormData.set('courseid', courseid);
	bodyFormData.set('usertype', usertype);
	bodyFormData.set('clientid', currentClient.id);

	instance.post('api/v1/course/enrolled', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		dispatch({
			type: _validateEnroll,
			payload: res
		})
	}).catch((error) => {
		dispatch({
			type: _validateEnroll,
			payload: error
		})
	});
}

export const validateIfEmailExists = (user_email, formType) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('user_email', user_email);

	instance.post('api/v1/user/email', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		dispatch({
			type: _validateEmailExists,
			payload: {
				data: res,
				formType: formType,
				random: Math.random()
			}
		})
	}).catch((error) => {
		dispatch({
			type: _validateEmailExists,
			payload: error
		})
	});
}

export const fetchJazzCashAddiotionalData = (user_id) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('user_id', user_id);

	instance.post('api/v1/lm/beforecheckout', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		dispatch({
			type: _addiotionalJazzCheckoutDetails,
			payload: {
				data: res,
				user_id: user_id,
				random: Math.random()
			}
		})
	}).catch((error) => {
		dispatch({
			type: _addiotionalJazzCheckoutDetails,
			payload: error
		})
	});
}

export const sendPhoneNumber = (phone) => async (dispatch) => {

	var bodyFormData = new FormData();

	var currentClient = getCurrentClient();

	bodyFormData.set('phone_number', phone);
	bodyFormData.set('client_id', currentClient.id);

	instance.post('api/v1/send/verification/code', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {

		dispatch({
			type: _checkPhoneNumber,
			payload: {
				data: res,
				phone: phone,
				random: Math.random()
			}
		})

	}).catch((error) => {
		dispatch({
			type: _checkPhoneNumber,
			payload: error
		})
	});
}

// export const sendPhoneNumber = ( phone ) => async (dispatch) => {

// 	var bodyFormData = new FormData();

// 	var currentClient = getCurrentClient();

// 	bodyFormData.set('column', 'phone_number');
// 	bodyFormData.set('client_id', currentClient.id);
// 	bodyFormData.set('val', phone);

// 	instance.post('api/v1/validate/user/column', bodyFormData, {
// 		headers: {
// 			'Content-Type': 'multipart/form-data'
// 		}
// 	}).then((res) => {

// 		if(res.data.result == "0") { // there's no phone added so generate verification code

// 			var bodyFormData2 = new FormData();
// 			bodyFormData2.set('phone_number', phone);
// 			bodyFormData2.set('client_id', currentClient.id);

// 			instance.post('api/v1/send/verification/code', bodyFormData2, {
// 				headers: {
// 					'Content-Type': 'multipart/form-data'
// 				}
// 			}).then((res) => {		
// 				dispatch({
// 					type: _checkPhoneNumber,
// 					payload: {
// 						data : res,
// 						phone : phone,
// 						random : Math.random()
// 					}
// 				})
// 			}).catch((error) => {
// 				dispatch({
// 					type: _checkPhoneNumber,
// 					payload: error
// 				})
// 			});

// 		} else {

// 			dispatch({
// 				type: _checkPhoneNumber,
// 				payload: {
// 					data : res,
// 					random : Math.random()
// 				}
// 			})

// 		}

// 	}).catch((error) => {
// 		dispatch({
// 			type: _checkPhoneNumber,
// 			payload: error
// 		})
// 	});
// }

export const sendVerificationCode = (verification_code, phone) => async (dispatch) => {

	var bodyFormData = new FormData();

	var currentClient = getCurrentClient();

	bodyFormData.set('verification_code', verification_code);
	bodyFormData.set('phone_number', phone);
	bodyFormData.set('client_id', currentClient.id);

	instance.post('api/v1/validate/verification/code', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		// console.log(res);
		dispatch({
			type: _validatePhoneVerification,
			payload: {
				data: res,
				random: Math.random()
			}
		})
	}).catch((error) => {
		// console.log(error);
		dispatch({
			type: _validatePhoneVerification,
			payload: error
		})
	});
}


export const getPhoneUsersList = (phone) => async (dispatch) => {

	var bodyFormData = new FormData();

	var currentClient = getCurrentClient();

	bodyFormData.set('column', 'phone_number');
	bodyFormData.set('client_id', currentClient.id);
	bodyFormData.set('val', phone);

	instance.post('api/v1/validate/user/column', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {

		dispatch({
			type: _checkIfUsersExists,
			payload: {
				data: res,
				random: Math.random()
			}
		})

	}).catch((error) => {
		dispatch({
			type: _checkIfUsersExists,
			payload: error
		})
	});

}

export const triggerNewUserType = (type) => async (dispatch) => {

	var isChild = 0;
	if (type == 'child') {
		isChild = 1;
	}

	dispatch({
		type: _triggerNewUserType,
		payload: {
			isChild: isChild,
			random: Math.random()
		}
	})

}

export const sendUsername = (username) => async (dispatch) => {

	var bodyFormData = new FormData();

	var currentClient = getCurrentClient();

	bodyFormData.set('column', 'user_loginname');
	bodyFormData.set('client_id', currentClient.id);
	bodyFormData.set('val', username);

	instance.post('api/v1/validate/user/column', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {

		dispatch({
			type: _checkIfUserNameExists,
			payload: {
				data: res,
				username: username,
				random: Math.random()
			}
		})

	}).catch((error) => {
		dispatch({
			type: _checkIfUserNameExists,
			payload: error
		})
	});

}


export const updateUserPassword = (password) => async (dispatch) => {

	dispatch({
		type: _triggerNewUserPassword,
		payload: {
			password: password,
			random: Math.random()
		}
	})

}

export const doneWithUserDetails = (details) => async (dispatch) => {

	dispatch({
		type: _triggerNewUserDetails,
		payload: {
			userDetails: {
				firstname: details.firstname,
				lastname: details.lastname,
				gender: details.gender,
				birthDate: details.birthDate
			},
			random: Math.random()
		}
	})

}

export const doneWithUserEmail = (email) => async (dispatch) => {

	dispatch({
		type: _triggerNewUserEmail,
		payload: {
			email: email,
			random: Math.random()
		}
	})

}

export const doRegisterWithPhone = (data) => async (dispatch) => {

	var bodyFormData = new FormData();
	var currentClient = getCurrentClient();

	bodyFormData.set('client_id', currentClient.id);
	bodyFormData.set('session_id', data.session_id);
	bodyFormData.set('user_name', data.user_name);
	bodyFormData.set('user_password', data.user_password);
	bodyFormData.set('user_fname', data.user_fname);
	bodyFormData.set('user_lname', data.user_lname);
	bodyFormData.set('user_gender', data.user_gender == "Male" ? 1 : data.user_gender == "Female" ? 2 : 3);
	bodyFormData.set('user_dob', data.user_dob);
	bodyFormData.set('user_email', data.user_email);
	bodyFormData.set('prog_id', data.prog_id); // program id
	bodyFormData.set('user_course', data.user_course); // course name
	bodyFormData.set('courseid', data.courseid); // course id
	bodyFormData.set('purchase', data.purchase);
	bodyFormData.set('clientUrl', data.clientUrl);
	bodyFormData.set('clientUrlProtocol', data.clientUrlProtocol);

	instance.post('api/v1/user/register', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {
		dispatch({
			type: _signupSuccessPhone,
			payload: {
				data: res,
				random: Math.random()
			}
		})
	}).catch((error) => {
		dispatch({
			type: _signupSuccessPhone,
			payload: "error"
		});
	});
}

export const validateColumn = (column_name, column_value) => async (dispatch) => {

	var bodyFormData = new FormData();

	var currentClient = getCurrentClient();

	bodyFormData.set('column', column_name);
	bodyFormData.set('client_id', currentClient.id);
	bodyFormData.set('val', column_value);

	instance.post('api/v1/validate/user/column', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then((res) => {

		dispatch({
			type: _checkIfThisColumnExists,
			payload: {
				data: res,
				column: column_name,
				random: Math.random()
			}
		})

	}).catch((error) => {
		dispatch({
			type: _checkIfThisColumnExists,
			payload: error
		})
	});

}




export const creatpaymentmethod = (candidateid, cardresponse, amount, description, currency , couponCode , couponId) => async (dispatch) => {

	var bodyFormData = new FormData();

	// var currentClient = getCurrentClient();

	bodyFormData.set('candidateid', candidateid);
	bodyFormData.set('cardresponse', JSON.stringify(cardresponse));
	bodyFormData.set('amount', amount);
	bodyFormData.set('description', description);
	bodyFormData.set('currancy', currency);
	bodyFormData.set('coupon_code', couponCode);
	bodyFormData.set('couponid', couponId);
	debugger;
	// bodyFormData.set('val', column_value);

	instance.post('api/v1/creatpaymentmethod', bodyFormData).then((res) => {
		console.log(res)
		dispatch({
			type: _creatpaymentmethod,
			payload: {
				data: res
			}
		})

	}).catch((error) => {
		console.log(error)
		dispatch({
			type: _creatpaymentmethod,
			payload: error
		})
	});

}

export const saveCard = (dataSet, pay_type) => async (dispatch) => {
	console.log(dataSet);

	let data = {
		"data": dataSet
	}

	var endpoint = "";
	var headers = {};
	if (pay_type == 'credit') {
		endpoint = "payment_methods";
		headers = {
			headers: {
				'authorization': LiveKeysPayMango['key1'],
				'content-type': 'application/json',
			}
		};
	} else if (pay_type == 'gcash' || pay_type == 'grabpay') {
		endpoint = "sources";
		headers = {
			headers: {
				'authorization': LiveKeysPayMango['key2'],
				'content-type': 'application/json',
			}
		};
	}

	axios.post("https://api.paymongo.com/v1/" + endpoint, data, headers).then((res) => {
		console.log("paymango", res);

		if (pay_type == 'credit') {

			dispatch({
				type: _saveCard,
				payload: {
					data: res
				}
			})

		}
		else {
			dispatch({
				type: _saveCardDirect,
				payload: {
					data: res
				}
			})
		}

	}).catch((error) => {
		if (pay_type == 'credit') {
			dispatch({
				type: _saveCard,
				payload: error
			})
		}
		else {
			dispatch({
				type: _saveCardDirect,
				payload: error
			})
		}
	});

}

export const doneMangoFinalPayment = (datahere, payMongoId) => async (dispatch) => {

	var headers = {
		headers: {
			'authorization': LiveKeysPayMango['key2'],
			'content-type': 'application/json',
		}
	};

	axios.get('https://api.paymongo.com/v1/sources/' + payMongoId, headers).then((res) => {
		console.log(res);

		if (res.data.data.attributes.status == "paid") {
			toast.success("Your payment is already done for the id: " + payMongoId, {
				position: "top-center",
				autoClose: false
			});
		}

		if (res.data.data.attributes.status == "chargeable") { // this is it's valid paymongo id/token and payment can be done now.

			var bodyFormData = new FormData();

			bodyFormData.set('candidateid', datahere.candidateid);
			bodyFormData.set('amount', datahere.amount);
			bodyFormData.set('course_id', datahere.course_id);
			bodyFormData.set('course_name', datahere.course_name);
			bodyFormData.set('currency', datahere.currency);
			bodyFormData.set('id', datahere.id);
			bodyFormData.set('statement_descriptor', datahere.statement_descriptor);
			bodyFormData.set('coupon_code', datahere.couponCode);
			bodyFormData.set('couponid', datahere.couponId);

			// bodyFormData.set('val', column_value);

			instance.post('api/v1/creatpaymentgcashgrab', bodyFormData).then((res) => {
				console.log(res);
				dispatch({
					type: _checkoutDoneFinal,
					payload: {
						data: res,
						random: Math.random()
					}
				})

			}).catch((error) => {
				console.log(error)
				dispatch({
					type: _checkoutDoneFinal,
					payload: {
						data: error,
						random: Math.random()
					}
				})
			});

		}

	}).catch((error) => {
		console.log(error);
		toast.error("There's some error in payment: " + error, {
			position: "top-center",
			autoClose: false
		});
	});

}

export const verifypaymongopayment = (id , candidateid) =>  {
	var bodyFormData = new FormData();
	bodyFormData.set('course_id', id);
	bodyFormData.set('candidateid', candidateid);

	return instance.post('api/v1/verifypaymongopayment', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
		.then((res) => {
			console.log(res)
			if (res.data.status == "success") { // if success only
				return res
			}
			else {
				return res
			}
		})
		.catch((error) => {
			return error
			console.log(error)
			
		});

};

// export const updateCart = ( course, dayid ) => async (dispatch) => {

// 	console.log(course, dayid);

// 	var cart = [];
// 	var cartData = localStorage.getItem('cartData');
// 	if(cartData && cartData != undefined && cartData.length > 0) {

// 		cart = JSON.parse(cartData);

// 		var flag = true, length = 1;
// 		for(var key in cart) {
// 			var condition = cart[key].courseId == course.course.id;
// 			if(condition) { // already exists
// 				if(course.course.is_online != 2){
// 					return false;
// 				}
// 				else{
// 					flag = false;
// 					cart[key].quantity = parseInt(cart[key].quantity) + 1;
// 				}
// 			}

// 			if(flag == true && length == cart.length){
// 				cart.push({
// 					courseId : course.course.id,
// 					selectedCourse : course.course,
// 					selectedDays : course.daysPrices,
// 					selectedDayId : dayid,
// 					selectedLocations : course.locations,
// 					quantity : 1
// 				});
// 			}

// 			length++;
// 		}

// 	}
// 	else{
// 		cart.push({
// 			courseId : course.course.id,
// 			selectedCourse : course.course,
// 			selectedDays : course.daysPrices,
// 			selectedDayId : dayid,
// 			selectedLocations : course.locations,
// 			quantity : 1
// 		})
// 	}


// 	localStorage.setItem('cartData', JSON.stringify(cart));

// 	if(localStorage.getItem('cartDataLength')){ 
// 		localStorage.setItem('cartDataLength', parseInt(localStorage.getItem('cartDataLength')) + 1 );
// 	}
// 	else{
// 		localStorage.setItem('cartDataLength', 1 );
// 	}


// 	dispatch({
// 		type: _cartUpdateDone,
// 		payload: {
// 			course : course,
// 			random : Math.random()
// 		}
// 	})

// }

// export const removeFromCart = ( course ) => async (dispatch) => {

// 	var courseObj = JSON.parse(course);
// 	console.log('action course: ' + course);
// 	console.log(courseObj)

// 	var cart = [];
// 	var cartData = localStorage.getItem('cartData');
// 	if(cartData && cartData != undefined) {

// 		cart = JSON.parse(cartData);

// 		for(var key in cart) {
// 			console.log(key)
// 			var condition = cart[key].courseId == courseObj.courseId;
// 			if(condition) { // already exists
// 				cart.splice(key, 1); // removing the one desired item from array
// 				console.log(cart.length);
// 				if(cart.length == 0){
// 					localStorage.removeItem('cartData');
// 					localStorage.removeItem('cartDataLength');
// 				}
// 				else{
// 					localStorage.setItem('cartData', JSON.stringify(cart));
// 				}
// 			}
// 		}

// 	}

// 	if(localStorage.getItem('cartDataLength')){ 
// 		if(parseInt(localStorage.getItem('cartDataLength')) > 0) {
// 			localStorage.setItem('cartDataLength', parseInt(localStorage.getItem('cartDataLength')) - parseInt(courseObj.quantity) );
// 		}
// 	}

// 	dispatch({
// 		type: _cartRemoveDone,
// 		payload: {
// 			course : courseObj,
// 			random : Math.random()
// 		}
// 	})

// }