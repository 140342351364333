import React, { Component } from "react";
import store from './../../store';
import PropTypes from 'prop-types';
import { sendPhoneNumber } from './../../actions/webRedActions';

import SelectSearch from 'react-select-search';

import countryData from './../../actions/countryData';

import { connect } from 'react-redux';

import { isMobileBrowser, validatePhone } from './../../actions/utility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    CustomInput,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    Label
  } from "reactstrap";

let additionalFields = []; // extrafields array global\

class PhoneNumber extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phonecode : "92",
            phonenumber : "",
            selectOptions : [],
            selectValue : "PAK",
            phoneError : false
        };

        this.submitPhone = this.submitPhone.bind(this);
        // this.validateField = this.validateField.bind(this);

    }
    
    componentDidMount() {

        console.log(countryData);
        this.setState({
            selectOptions : countryData.map((country, index) => {
                return {
                    id : index,
                    name : country.name,
                    value : country.alpha3Code,
                    callingCode : country.callingCodes[0],
                    flag : country.flag
                }
            })
        }, () => {
            console.log(this.state.selectOptions)
        })

    }
    
    componentWillReceiveProps(nextProps, thisProps) {

        

    }

    submitPhone(number) {

        console.log(number);

    }

    updateValue = (val) => {
        this.setState({
            selectValue : val
        }, () => {
            console.log(this.state.selectValue);

            this.state.selectOptions.map(country => {
                if(country.value == this.state.selectValue){
                    this.setState({
                        phonecode : country.callingCode
                    })
                }
            })
        })
    }

    render() {

        // const formType = this.props.formType;
        // const isCredentialActive = this.props.isCredentialActive;
        // const extraFields = this.props.extraFields;
        // const fieldsKeys = Object.keys(extraFields);
        const course = store.getState().webRed.courseDetail.course;
        const {selectOptions, selectValue, phonecode, phonenumber} = this.state;

        // code for adding extra fields to array to send it to action - starts here
        // if(additionalFields.length < 1){
        //     formType !== 'child' && fieldsKeys.map((field)=> {
        //         var fieldValue = extraFields[field];
        //         additionalFields.push({
        //             extra_field_id : fieldValue.id,
        //             value : ''
        //         })
        //     })
        // }
        // else{
        //     formType !== 'child' && fieldsKeys.map((field, key)=> {
        //         additionalFields[key].extra_field_id = '';
        //         additionalFields[key].value = '';
        //     })
        // }
        // code for adding extra fields to array to send it to action - ends here

        return (
            <div style={{padding: '1vh 0'}} className="PhoneNumber" style={{
                background: 'f4eb49',
                color: '75a9f9'
            }}>
                <div className="wrapper"></div>
                
                <Container>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h3>
                                Login with your phone number
                            </h3>

                            <p style={{textAlign: 'center', fontWeight: '600', marginBottom: '7px', color: "#fff"}}>
                                Enter your phone number
                            </p>
                            
                            <SelectSearch
                                options={selectOptions}
                                value={selectValue}
                                name="countryCode"
                                placeholder="Choose country"
                                // search={ isMobileBrowser() ? true : false}
                                onChange={(e) => {
                                    if(e && e != null && e != undefined){
                                        this.updateValue(e)
                                    }
                                }}
                            />

                            {
                                selectValue && selectValue != "" ?
                                    <Row className="phoneStyle">
                                        <Col xs={{size : 4}}>
                                            <FormGroup style={{position: 'relative'}}>
                                                {
                                                    selectOptions.map((country, key) => {
                                                        if(country.value == selectValue){
                                                            return (
                                                            <div key={key}>
                                                                <Media className="flagImg" object src={country.flag} alt={country.flag} />
                                                                <Input disabled type="text" name="callingCode" id="callingCode" value={ "+" + country.callingCode } />
                                                            </div>
                                                            )
                                                        }
                                                    })
                                                }
                                                
                                            </FormGroup>
                                        </Col>
                                        <Col xs={{size : 8}}>
                                            <FormGroup style={{position: 'relative'}}>
                                                <Input type="tel" name="callingNum" id="callingNum" value={phonenumber} onChange={(e) => this.setState({
                                                    phonenumber : e.target.value
                                                }, () => {
                                                    console.log(phonenumber)
                                                })} />
                                            </FormGroup>
                                            <hr />
                                        </Col>
                                    </Row>
                                : null
                            }
                            
                            {
                                this.state.phoneError ?
                                    <p style={{color: 'red', fontSize: '12px'}}>{this.state.phoneError}</p>
                                : null
                            }

                            {
                                phonecode !== "" && phonenumber !== "" ?

                                    <Button 
                                        className="nextButtonNew"
                                        onClick={(e) => {
                                            var thisPhone = phonecode+phonenumber;
                                            
                                            this.setState({
                                                phoneError : false
                                            }, () => {
                                                console.log(validatePhone(thisPhone))
                                                if(!validatePhone(thisPhone)){
                                                    this.setState({
                                                        phoneError : "Enter a valid number. Dash, dot or any other special characters are not applicable. ex: +923456123568"
                                                    })
                                                } else {
                                                    this.props.sendPhoneNumber(thisPhone);
                                                }
                                            })
                                        }}
                                        size="lg" color="secondary" block>
                                            Next
                                            <FontAwesomeIcon icon={faArrowRight} size="2x" color="#fff" />
                                        </Button>

                                : null
                            }
                            

                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

PhoneNumber.propTypes = {
    sendPhoneNumber: PropTypes.func.isRequired,
    // validateIfPhoneExists: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // phoneNumRes : state.webRed.phoneNumRes,
    // validatePhoneExistsRes : state.webRed.validatePhoneExistsRes
    // extraSignUpFields:state.webRed.extraSignUpFields
})

export default connect(mapStatesToProps,{ sendPhoneNumber })(PhoneNumber);