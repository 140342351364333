import React from 'react';
import {
    Row,
    Col,
    Table,
    Card,
    Tab,
    Nav,
    Button,
    Form ,
    Collapse
} from 'react-bootstrap';

import {
    Spinner,
  } from "reactstrap";

// import Aux from "./../../../hoc/_Aux";
import MaskedInput from 'react-text-mask';
import prod1 from '././../../public/images/checkout/product/prod-1.jpg';
import prod2 from '././../../public/images/checkout/product/prod-2.jpg';
import prod12 from '././../../public/images/checkout/product/prod-12.jpg';
import paypal from '././../../public/images/checkout/product/paypal.png';
import card from '././../../public/images/checkout/product/card.png';
import cc from '././../../public/images/checkout/product/cc.png';
import validator from 'validator';
import { ValidationForm, TextInput, BaseFormControl, SelectGroup, FileInput, Checkbox, Radio } from 'react-bootstrap4-form-validation';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Header from '../website/Header';
import Footer from '../website/Footer';
import { saveCard, creatpaymentmethod } from './../../actions/webRedActions';
import { validatePhone } from './../../actions/utility';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { data } from 'jquery';
import { toast } from 'react-toastify';

// // import DEMO from "../../../store/constant";
// class MaskWithValidation extends BaseFormControl {
//     constructor(props){
//         super(props);
//         this.inputRef = React.createRef();
//     }

//     getInputRef(){
//         return this.inputRef.current.inputElement;
//     }

//     handleChange = (e) => {
//         this.checkError();
//         if(this.props.onChange) this.props.onChange(e);
//     };

//     render () {
//         return (
//             <React.Fragment>
//                 <MaskedInput ref={this.inputRef} {...this.filterProps()} onChange={this.handleChange}/>
//                 { this.displayErrorMessage() }
//                 { this.displaySuccessMessage() }
//             </React.Fragment>
//         )
//     }
// }

class Checkout extends React.Component {
    state = {
        accordionKey: 0,
        tabchanged:'bi',
        country:'PH',
        userDetail : "",
        isPhoneCorrect : true,
        isMonthCorrect : true,
        selectedPayType : "",
        isCheckoutLoading  : false,
    };

    componentDidMount() {
        // this.props.saveCard()
        console.log("Course Checkout" , JSON.parse(localStorage.getItem('checkoutCourse')));
        var userdata = JSON.parse(localStorage.getItem('loginData'));
        if(userdata && userdata != null && userdata.user) {
            this.setState({
                userDetail : userdata.user
            }, () => {
                const {userDetail} = this.state;
                var name = userDetail.user_name.split(' ');
                this.setState({
                    firstName : name[0],
                    lastName : name[1],
                    email : userDetail.user_email,
                    cardname : userDetail.user_name
                    //phone : name[1]
                })
            })
        }

        // check payment gateway type from storage and play accordingly
        var pay_type = localStorage.getItem('paymentType');
        if(pay_type && pay_type != null && pay_type != '') {
            this.setState({
                selectedPayType : pay_type
            })
        }
        
        
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleSubmit = (e, formData, inputs) => {
        e.preventDefault();
        //alert(JSON.stringify(formData, null, 2));
        console.log(this.state)
        // this.setState({
        //     tabchanged:'si'
        // })
        this.saveCards();
    };

    saveCards= () => {
        
        // check payment gateway type from storage and play accordingly
        var pay_type = localStorage.getItem('paymentType');
        var data = '';
        // console.log(pay_type);
        let login = localStorage.getItem('loginData');
        let checkoutCourse = localStorage.getItem('checkoutCourse');

        let couponApplied = localStorage.getItem('couponApplied');
        let discountedAmount = localStorage.getItem('discountedAmount');
        
        if(login)
        {
            login = JSON.parse(login);
            checkoutCourse = JSON.parse(checkoutCourse);
            debugger;
            if(pay_type && pay_type != null && pay_type != '') {

                if(pay_type == 'credit') { // if Credit-Debit payment
    
                    data =  {
                        "attributes": {
                          "details": {
                            "card_number": this.state.card,
                            "exp_month": parseInt(this.state.expirymonth),
                            "exp_year": parseInt(this.state.expiryyear),
                            "cvc": this.state.cvv
                          },
                          "billing": {
                            "address": {
                              "line1": this.state.Address,
                              "city": this.state.TownCity,
                              "state":this.state.States,
                              "postal_code":this.state.Postal,
                              "country": this.state.country
                            },
                            "name": this.state.cardname,
                            "email": this.state.email,
                            "phone": this.state.phone
                          },
                          "type": "card"
                        }
                    }
    
                } else if(pay_type == 'gcash') { // if G-Cash payment
    
                    data = {
                        "attributes": {
                            "amount":  couponApplied == true ? parseInt(discountedAmount) * 100 : parseInt(checkoutCourse.course_price) * 100,
                            "redirect": {
                                "success": "https://skillupz.com/#/success-checkout",
                                "failed": "https://skillupz.com/#/failed-checkout"
                            },
                            "billing": {
                                "address": {
                                    "line1": this.state.Address,
                                    "state": this.state.States,
                                    "postal_code": this.state.Postal,
                                    "city": this.state.TownCity,
                                    "country": this.state.country
                                },
                                "name": this.state.firstName + " " + this.state.lastName,
                                "phone": this.state.phone,
                                "email": this.state.email
                            },
                            "type": "gcash",
                            "currency": "PHP"
                        }
                    };
    
                } else if(pay_type == 'grabpay') { // if Grab Pay payment

                    data = {
                        "attributes": {
                            "amount": couponApplied == true ? parseInt(discountedAmount) * 100 : parseInt(checkoutCourse.course_price) * 100,
                            "redirect": {
                                "success": "https://skillupz.com/#/success-checkout",
                                "failed": "https://skillupz.com/#/failed-checkout"
                            },
                            "billing": {
                                "address": {
                                    "line1": this.state.Address,
                                    "state": this.state.States,
                                    "postal_code": this.state.Postal,
                                    "city": this.state.TownCity,
                                    "country": this.state.country
                                },
                                "name": this.state.firstName + " " + this.state.lastName,
                                "phone": this.state.phone,
                                "email": this.state.email
                            },
                            "type": "grab_pay",
                            "currency": "PHP"
                        }
                    };

                }

                
                console.log(data);
                this.setState({
                    // enable loading for submitting the form checkout
                    isCheckoutLoading : true
                }, () => {
                    this.props.saveCard(data, pay_type);
                })
            
            }


        }
        else {
            toast.error("You are not logged in, Please login to continue", {
                position: "top-center",
                autoClose: false
            });
        }

    }

    handleErrorSubmit = (e, formData, errorInputs) => {
        //console.log(errorInputs);
    };

    matchPassword = (value) => {
        return value && value === this.state.password;
    };
    
    componentWillReceiveProps = (nextProps, thisProps) => {

        if(this.props.saveCardData != nextProps.saveCardData) {
            console.log('paymango', nextProps.saveCardData);
            

            try {
                // this means success
                if(nextProps.saveCardData.data.status == 200) {
                    let login = localStorage.getItem('loginData');
                    let checkoutCourse = localStorage.getItem('checkoutCourse');

                    let couponApplied = localStorage.getItem('couponApplied');
                    let discountedAmount = localStorage.getItem('discountedAmount');
                    let couponId = localStorage.getItem('couponId');
                    let couponCode = localStorage.getItem('couponCode');
                    
                    if(login)
                    {
                        login = JSON.parse(login);
                        checkoutCourse = JSON.parse(checkoutCourse);
                        var c_description = {
                            course_id : checkoutCourse.id,
                            course_name : checkoutCourse.course_name
                        };
                        this.props.creatpaymentmethod(login.user.id, nextProps.saveCardData.data, couponApplied == "true" ? parseInt(discountedAmount) : parseInt(checkoutCourse.course_price), JSON.stringify(c_description), "PHP" , couponCode , couponId);
                    }
                    
                }
            }
            catch (e) {
                this.setState({
                    isCheckoutLoading : false
                })
                toast.error("There's some error in payment: " + nextProps.saveCardData, {
                    position: "top-center",
                    autoClose: false
                });
            }

        }

        
        if(this.props.saveCardDataDirect != nextProps.saveCardDataDirect) {
            console.log('paymango direct method', nextProps.saveCardDataDirect);
            this.setState({
                isCheckoutLoading : false
            })
            try {
                if(nextProps.saveCardDataDirect.data.data.data.id) {
                    localStorage.setItem('payMongoResponseId', nextProps.saveCardDataDirect.data.data.data.id);
                    window.location.href = nextProps.saveCardDataDirect.data.data.data.attributes.redirect.checkout_url;
                }
            } catch(e) {
                console.log(e);
            }
            
        }
        

        if(this.props.creatpaymentmethodData != nextProps.creatpaymentmethodData) {
            console.log('Done with checkout.', nextProps.creatpaymentmethodData);
            debugger;
            if(nextProps.creatpaymentmethodData.data.data.status == 'success' && typeof nextProps.creatpaymentmethodData.data.data.result.data.attributes.next_action?.type == "undefined") {
                debugger;
                this.setState({
                    isCheckoutLoading : false
                })
                
                confirmAlert({
                    title: 'Congratulations.',
                    message: "You are successfully enrolled to " + nextProps.creatpaymentmethodData.data.data.result.data.attributes.description,
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {
                                window.location = "/#/courses";
                            }
                        }
                    ]
                })

            }
            else if(nextProps.creatpaymentmethodData.data.data.status == 'success' && nextProps.creatpaymentmethodData.data.data.result.data.attributes.next_action?.type == "redirect"){
                this.setState({
                    isCheckoutLoading : false
                })
                debugger;
                
                confirmAlert({
                    title: 'Last Step.',
                    message: "Click on Verify Button to authenticate your 3D Secure card",
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    buttons: [
                        {
                            label: 'Verify',
                            onClick: () => {
                                window.location = nextProps.creatpaymentmethodData.data.data.result.data.attributes.next_action.redirect.url;
                            }
                        }
                    ]
                })
            }
            if(nextProps.creatpaymentmethodData.data.data.status == 'fail') {
                toast.error(nextProps.creatpaymentmethodData.data.data.result, {
                    position: "top-center",
                    autoClose: false
                });
                this.setState({
                    isCheckoutLoading : false
                })
            }
        }
    };

    updatePayType = (type) => {
        localStorage.setItem('paymentType' , type);
        window.location.reload();
    }

    render() {
        const { accordionKey, isPhoneCorrect, isMonthCorrect, selectedPayType } = this.state;

        return (
            <div className="checkoutpayment">
                <Header />
                    <div className="container" style={{padding: '50px 0'}}>
                        <Row className='btn-page'>
                            <Col md={12}>
                                <Tab.Container onSelect={(evt)=>{this.setState({tabchanged:evt})}} activeKey={this.state.tabchanged} defaultActiveKey="bi">
                                    <Card>
                                        <Card.Body>
                                            <Nav variant="pills" className="bg-nav-pills nav-justified mb-0">
                                                <Nav.Item>
                                                    <Nav.Link style={{background: selectedPayType == 'credit' ? '#ffb400' : 'transparent'}} disabled={selectedPayType == 'credit'} onClick={(e) => this.updatePayType('credit')}>Credit/Debit Card</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link style={{background: selectedPayType == 'gcash' ? '#ffb400' : 'transparent'}} disabled={selectedPayType == 'gcash'} onClick={(e) => this.updatePayType('gcash')}>G Cash</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link style={{background: selectedPayType == 'grabpay' ? '#ffb400' : 'transparent'}} disabled={selectedPayType == 'grabpay'} onClick={(e) => this.updatePayType('grabpay')}>Grab Pay</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Card.Body>
                                    </Card>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="bi">
                                       
                                        <div className="card" style={{padding:20}}>
                                         <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}>
                                     
                                       
                                    <Form.Row>
                                        <Col md={12}><h5>Customer Information</h5></Col>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label htmlFor="firstName">First name</Form.Label>
                                            <TextInput
                                                name="firstName"
                                                id="firstName"
                                                placeholder="First Name"
                                                required
                                                value={this.state.firstName}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label htmlFor="lastName">Last name</Form.Label>
                                            <TextInput
                                                name="lastName"
                                                id="lastName"
                                                placeholder="Last Name"
                                                minLength="1"
                                                value={this.state.lastName}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label htmlFor="email">Email</Form.Label>
                                            <TextInput
                                                name="email"
                                                id="email"
                                                type="email"
                                                placeholder="Email Address"
                                                validator={validator.isEmail}
                                                errorMessage={{validator:"Please enter a valid email"}}
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label htmlFor="phone">Phone</Form.Label>
                                            <TextInput
                                                style={{backgroundImage : !isPhoneCorrect ? 'none' : '', borderColor : !isPhoneCorrect ? 'red' : 'green' }}
                                                name="phone"
                                                id="phone"
                                                // type="phone"
                                                placeholder="ex: +639661645400"
                                                required
                                                // pattern="/((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/"
                                                // validator={validator.isMobilePhone}
                                                errorMessage={{required:"Phone number required"}}
                                                value={this.state.password}
                                                onChange={(e) => validatePhone(e.target.value) ? this.setState({ isPhoneCorrect : true, phone: e.target.value } ) : this.setState({isPhoneCorrect : false})}
                                                autoComplete="off"
                                            />
                                            {
                                                !isPhoneCorrect ?
                                                    <div style={{fontSize: '12px', color: 'red'}}>Please enter a valid Phone number. ex: +639661645400</div>
                                                : null
                                            }
                                        </Form.Group>
                                        <Col md={12}> <h5>Billing Information</h5></Col>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label htmlFor="Address">Address</Form.Label>
                                            <TextInput
                                                name="Address"
                                                id="Address"
                                                type="Address"
                                                placeholder="Enter full address"
                                                required
                                                // validator={this.matchPassword}
                                                errorMessage={{required:"Address is required"}}
                                                value={this.state.Address}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label htmlFor="TownCity">Town/City</Form.Label>
                                            <TextInput
                                                name="TownCity"
                                                id="TownCity"
                                                type="TownCity"
                                                placeholder="Enter your city name"
                                                required
                                                // validator={this.matchPassword}
                                                errorMessage={{required:"Town/City is required"}}
                                                value={this.state.TownCity}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label htmlFor="State">State</Form.Label>
                                            <TextInput
                                                name="States"
                                                id="States"
                                                type="States"
                                                placeholder="Enter your state"
                                                required
                                                // validator={this.matchPassword}
                                                errorMessage={{required:"State is required"}}
                                                value={this.state.States}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label htmlFor="Postal">Zip / Postal Code</Form.Label>
                                            <TextInput
                                                name="Postal"
                                                id="Postal"
                                                type="Postal"
                                                placeholder="Enter your zip code"
                                                required
                                                // validator={this.matchPassword}
                                                errorMessage={{required:"Postal is required"}}
                                                value={this.state.Postal}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label htmlFor="country">Country</Form.Label>
                                            <SelectGroup
                                                name="country"
                                                id="country"
                                                value={this.state.country}
                                                required
                                                errorMessage="Please select a country."
                                                onChange={this.handleChange}>
                                                                   <option value="PH">Philippines</option>
                                                                    <option value="AF">Afghanistan</option>
                                                                    <option value="AL">Albania</option>
                                                                    <option value="DZ">Algeria</option>
                                                                    <option value="AS">American Samoa</option>
                                                                    <option value="AD">Andorra</option>
                                                                    <option value="AO">Angola</option>
                                                                    <option value="AI">Anguilla</option>
                                                                    <option value="AQ">Antarctica</option>
                                                                    <option value="AR">Argentina</option>
                                                                    <option value="AM">Armenia</option>
                                                                    <option value="AW">Aruba</option>
                                                                    <option value="AU">Australia</option>
                                                                    <option value="AT">Austria</option>
                                                                    <option value="AZ">Azerbaijan</option>
                                                                    <option value="BS">Bahamas</option>
                                                                    <option value="BH">Bahrain</option>
                                                                    <option value="BD">Bangladesh</option>
                                                                    <option value="BB">Barbados</option>
                                                                    <option value="BY">Belarus</option>
                                                                    <option value="BE">Belgium</option>
                                                                    <option value="BZ">Belize</option>
                                                                    <option value="BJ">Benin</option>
                                                                    <option value="BM">Bermuda</option>
                                                                    <option value="BT">Bhutan</option>
                                                                    <option value="BO">Bolivia</option>
                                                                    <option value="BW">Botswana</option>
                                                                    <option value="BV">Bouvet Island</option>
                                                                    <option value="BR">Brazil</option>
                                                                    <option value="BN">Brunei Darussalam</option>
                                                                    <option value="BG">Bulgaria</option>
                                                                    <option value="BF">Burkina Faso</option>
                                                                    <option value="BI">Burundi</option>
                                                                    <option value="KH">Cambodia</option>
                                                                    <option value="CM">Cameroon</option>
                                                                    <option value="CA">Canada</option>
                                                                    <option value="CV">Cape Verde</option>
                                                                    <option value="KY">Cayman Islands</option>
                                                                    <option value="CF">Central African Republic</option>
                                                                    <option value="TD">Chad</option>
                                                                    <option value="CL">Chile</option>
                                                                    <option value="CN">China</option>
                                                                    <option value="CX">Christmas Island</option>
                                                                    <option value="CC">Cocos (Keeling) Islands</option>
                                                                    <option value="CO">Colombia</option>
                                                                    <option value="KM">Comoros</option>
                                                                    <option value="CG">Congo</option>
                                                                    <option value="CK">Cook Islands</option>
                                                                    <option value="CR">Costa Rica</option>
                                                                    <option value="CI">Cote d'Ivoire</option>
                                                                    <option value="HR">Croatia (Hrvatska)</option>
                                                                    <option value="CU">Cuba</option>
                                                                    <option value="CY">Cyprus</option>
                                                                    <option value="CZ">Czech Republic</option>
                                                                    <option value="DK">Denmark</option>
                                                                    <option value="DJ">Djibouti</option>
                                                                    <option value="DM">Dominica</option>
                                                                    <option value="DO">Dominican Republic</option>
                                                                    <option value="EC">Ecuador</option>
                                                                    <option value="EG">Egypt</option>
                                                                    <option value="SV">El Salvador</option>
                                                                    <option value="GQ">Equatorial Guinea</option>
                                                                    <option value="ER">Eritrea</option>
                                                                    <option value="EE">Estonia</option>
                                                                    <option value="ET">Ethiopia</option>
                                                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                                                    <option value="FO">Faroe Islands</option>
                                                                    <option value="FJ">Fiji</option>
                                                                    <option value="FI">Finland</option>
                                                                    <option value="FR">France</option>
                                                                    <option value="GF">French Guiana</option>
                                                                    <option value="PF">French Polynesia</option>
                                                                    <option value="GA">Gabon</option>
                                                                    <option value="GM">Gambia</option>
                                                                    <option value="GE">Georgia</option>
                                                                    <option value="DE">Germany</option>
                                                                    <option value="GH">Ghana</option>
                                                                    <option value="GI">Gibraltar</option>
                                                                    <option value="GR">Greece</option>
                                                                    <option value="GL">Greenland</option>
                                                                    <option value="GD">Grenada</option>
                                                                    <option value="GP">Guadeloupe</option>
                                                                    <option value="GU">Guam</option>
                                                                    <option value="GT">Guatemala</option>
                                                                    <option value="GN">Guinea</option>
                                                                    <option value="GW">Guinea-Bissau</option>
                                                                    <option value="GY">Guyana</option>
                                                                    <option value="HT">Haiti</option>
                                                                    <option value="HN">Honduras</option>
                                                                    <option value="HK">Hong Kong</option>
                                                                    <option value="HU">Hungary</option>
                                                                    <option value="IS">Iceland</option>
                                                                    <option value="IN">India</option>
                                                                    <option value="ID">Indonesia</option>
                                                                    <option value="IQ">Iraq</option>
                                                                    <option value="IE">Ireland</option>
                                                                    <option value="IL">Israel</option>
                                                                    <option value="IT">Italy</option>
                                                                    <option value="JM">Jamaica</option>
                                                                    <option value="JP">Japan</option>
                                                                    <option value="JO">Jordan</option>
                                                                    <option value="KZ">Kazakhstan</option>
                                                                    <option value="KE">Kenya</option>
                                                                    <option value="KI">Kiribati</option>
                                                                    <option value="KR">Korea, Republic of</option>
                                                                    <option value="KW">Kuwait</option>
                                                                    <option value="KG">Kyrgyzstan</option>
                                                                    <option value="LV">Latvia</option>
                                                                    <option value="LB">Lebanon</option>
                                                                    <option value="LS">Lesotho</option>
                                                                    <option value="LR">Liberia</option>
                                                                    <option value="LY">Libyan Arab Jamahiriya</option>
                                                                    <option value="LI">Liechtenstein</option>
                                                                    <option value="LT">Lithuania</option>
                                                                    <option value="LU">Luxembourg</option>
                                                                    <option value="MO">Macau</option>
                                                                    <option value="MG">Madagascar</option>
                                                                    <option value="MW">Malawi</option>
                                                                    <option value="MY">Malaysia</option>
                                                                    <option value="MV">Maldives</option>
                                                                    <option value="ML">Mali</option>
                                                                    <option value="MT">Malta</option>
                                                                    <option value="MH">Marshall Islands</option>
                                                                    <option value="MQ">Martinique</option>
                                                                    <option value="MR">Mauritania</option>
                                                                    <option value="MU">Mauritius</option>
                                                                    <option value="YT">Mayotte</option>
                                                                    <option value="MX">Mexico</option>
                                                                    <option value="MD">Moldova, Republic of</option>
                                                                    <option value="MC">Monaco</option>
                                                                    <option value="MN">Mongolia</option>
                                                                    <option value="MS">Montserrat</option>
                                                                    <option value="MA">Morocco</option>
                                                                    <option value="MZ">Mozambique</option>
                                                                    <option value="MM">Myanmar</option>
                                                                    <option value="NA">Namibia</option>
                                                                    <option value="NR">Nauru</option>
                                                                    <option value="NP">Nepal</option>
                                                                    <option value="NL">Netherlands</option>
                                                                    <option value="AN">Netherlands Antilles</option>
                                                                    <option value="NC">New Caledonia</option>
                                                                    <option value="NZ">New Zealand</option>
                                                                    <option value="NI">Nicaragua</option>
                                                                    <option value="NE">Niger</option>
                                                                    <option value="NG">Nigeria</option>
                                                                    <option value="NU">Niue</option>
                                                                    <option value="NF">Norfolk Island</option>
                                                                    <option value="MP">Northern Mariana Islands</option>
                                                                    <option value="NO">Norway</option>
                                                                    <option value="OM">Oman</option>
                                                                    <option value="PW">Palau</option>
                                                                    <option value="PA">Panama</option>
                                                                    <option value="PG">Papua New Guinea</option>
                                                                    <option value="PY">Paraguay</option>
                                                                    <option value="PE">Peru</option>
                                                                    <option value="PK">Pakistan</option>
                                                                    <option value="PN">Pitcairn</option>
                                                                    <option value="PL">Poland</option>
                                                                    <option value="PT">Portugal</option>
                                                                    <option value="PR">Puerto Rico</option>
                                                                    <option value="QA">Qatar</option>
                                                                    <option value="RE">Reunion</option>
                                                                    <option value="RO">Romania</option>
                                                                    <option value="RU">Russian Federation</option>
                                                                    <option value="RW">Rwanda</option>
                                                                    <option value="KN">Saint Kitts and Nevis</option>
                                                                    <option value="LC">Saint LUCIA</option>
                                                                    <option value="WS">Samoa</option>
                                                                    <option value="SM">San Marino</option>
                                                                    <option value="ST">Sao Tome and Principe</option>
                                                                    <option value="SA">Saudi Arabia</option>
                                                                    <option value="SN">Senegal</option>
                                                                    <option value="SC">Seychelles</option>
                                                                    <option value="SL">Sierra Leone</option>
                                                                    <option value="SG">Singapore</option>
                                                                    <option value="SK">Slovakia (Slovak Republic)</option>
                                                                    <option value="SI">Slovenia</option>
                                                                    <option value="SB">Solomon Islands</option>
                                                                    <option value="SO">Somalia</option>
                                                                    <option value="ZA">South Africa</option>
                                                                    <option value="ES">Spain</option>
                                                                    <option value="LK">Sri Lanka</option>
                                                                    <option value="SH">St. Helena</option>
                                                                    <option value="PM">St. Pierre and Miquelon</option>
                                                                    <option value="SD">Sudan</option>
                                                                    <option value="SR">Suriname</option>
                                                                    <option value="SZ">Swaziland</option>
                                                                    <option value="SE">Sweden</option>
                                                                    <option value="CH">Switzerland</option>
                                                                    <option value="SY">Syrian Arab Republic</option>
                                                                    <option value="TW">Taiwan, Province of China</option>
                                                                    <option value="TJ">Tajikistan</option>
                                                                    <option value="TZ">Tanzania, United Republic of</option>
                                                                    <option value="TH">Thailand</option>
                                                                    <option value="TG">Togo</option>
                                                                    <option value="TK">Tokelau</option>
                                                                    <option value="TO">Tonga</option>
                                                                    <option value="TT">Trinidad and Tobago</option>
                                                                    <option value="TN">Tunisia</option>
                                                                    <option value="TR">Turkey</option>
                                                                    <option value="TM">Turkmenistan</option>
                                                                    <option value="TC">Turks and Caicos Islands</option>
                                                                    <option value="TV">Tuvalu</option>
                                                                    <option value="UG">Uganda</option>
                                                                    <option value="UA">Ukraine</option>
                                                                    <option value="AE">United Arab Emirates</option>
                                                                    <option value="GB">United Kingdom</option>
                                                                    <option value="US">United States</option>
                                                                    <option value="UY">Uruguay</option>
                                                                    <option value="UZ">Uzbekistan</option>
                                                                    <option value="VU">Vanuatu</option>
                                                                    <option value="VE">Venezuela</option>
                                                                    <option value="VN">Viet Nam</option>
                                                                    <option value="VG">Virgin Islands (British)</option>
                                                                    <option value="VI">Virgin Islands (U.S.)</option>
                                                                    <option value="WF">Wallis and Futuna Islands</option>
                                                                    <option value="EH">Western Sahara</option>
                                                                    <option value="YE">Yemen</option>
                                                                    <option value="ZM">Zambia</option>
                                                                    <option value="ZW">Zimbabwe</option>
                                            </SelectGroup>
                                        </Form.Group>
                                     
                                     {
                                        selectedPayType == 'credit' ?
                                        <Row>
                                            <Col md={12}>
                                        <h5>Card Information</h5>
                                                      
                                     </Col>
                                     <Row style={{marginBottom:20}}>
                                                <Col sm={8}>
                                                    <p className="mb-0 pl-3 pt-1">Safe money transfer using your bank account. We support Mastercard, Visa, Discover and Stripe.</p>
                                                </Col>
                                                <Col sm={4} className="text-sm-right mt-3 mt-sm-0">
                                                    <img src={card} height="24" alt="payment-images"/>
                                                </Col>
                                            </Row>

                                            <Form.Group as={Col} md="12">
                                            <Form.Label htmlFor="card">Card Number</Form.Label>
                                            <TextInput
                                                name="card"
                                                id="card"
                                                type="card"
                                                placeholder="4242 4242 4242 4242"
                                                required
                                                maxLength={16}
                                               minLength={16}
                                                // validator={this.matchPassword}
                                                errorMessage={{required:"Card no is required"}}
                                                value={this.state.card}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="6">
                                            <Form.Label htmlFor="cardname">Name on card</Form.Label>
                                            <TextInput
                                                name="cardname"
                                                id="cardname"
                                                type="cardname"
                                                placeholder="Master Shreyu"
                                                required
                                                // validator={this.matchPassword}
                                                errorMessage={{required:"Name on Card is required"}}
                                                value={this.state.cardname}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label style={{display: 'block'}} >Expiry date</Form.Label>
                                            <TextInput
                                                style={{display: 'inline-block', width: '40%', backgroundImage : !isMonthCorrect ? 'none' : '', borderColor : !isMonthCorrect ? 'red' : 'green'}}
                                                name="expirymonth"
                                                id="expirymonth"
                                                type="expirymonth"
                                                maxLength="2"
                                                placeholder="MM"
                                                required
                                                validator={validator.isNumeric}
                                                errorMessage={{validator: "Month is required"}}
                                                value={this.state.expirymonth}
                                                onChange={(e) => e.target.value <= 12 ? this.setState({ isMonthCorrect : true, expirymonth: e.target.value }) : this.setState({isMonthCorrect : false})}

                                                autoComplete="off"
                                            />
                                            {
                                                !isMonthCorrect ?
                                                    <div style={{fontSize: '12px', color: 'red'}}>Please enter a valid Month.</div>
                                                : null
                                            }

                                            <TextInput
                                                style={{display: 'inline-block', width: '40%'}}
                                                name="expiryyear"
                                                id="expiryyear"
                                                type="expiryyear"
                                                placeholder="YY"
                                                maxLength="2"
                                                required
                                                validator={validator.isNumeric}
                                                errorMessage={{validator: "Year is required"}}
                                                value={this.state.expiryyear}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                              
                                        <Form.Group as={Col} md="6">
                                            <Form.Label htmlFor="cvv">CVV code</Form.Label>
                                            <TextInput
                                                name="cvv"
                                                id="cvv"
                                                type="cvv"
                                                placeholder="012"
                                                required
                                                minLength={3}
                                                maxLength={3}
                                                validator={validator.isNumeric}
                                                errorMessage={{validator: "CVV code is not valid"}}
                                                value={this.state.cvv}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        </Row>
                                        : null
                                     }
                                     
                              
                                
                                
                                 
                                        <Form.Group as={Col} sm={12} className="mt-3">
                                            <Button type="submit" disabled={this.state.isCheckoutLoading}>
                                                {
                                                    this.state.isCheckoutLoading ?
                                                        <Spinner style={{width: '1rem', height: '1rem', marginRight: '10px'}} color="light" />
                                                    : null
                                                }
                                                Checkout
                                            </Button>
                                        </Form.Group>
                                    </Form.Row>
                                  
                                </ValidationForm>
                                </div>
                            </Tab.Pane>
                                        {/* <Tab.Pane eventKey="si">
                                        <div className="card">  
                                            <div className="card-body">
                                            <Row>
                                                <Col md={12}>
                                                <h5>Payment Information</h5>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Description</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>Math course from flasjh sale</p>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Amount</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>90$</p>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Payment Method</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>Credit/Debit Card</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                <h5>Personal Information</h5>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Name</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>{this.state.firstName+" "+this.state.lastName} </p>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Email</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>{this.state.email}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Phone</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>{this.state.phone}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                <h5>Billing Address</h5>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Address</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>{this.state.Address}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Town/City</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>{this.state.TownCity}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>State</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>{this.state.State}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Zip / Postal Code</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>{this.state.Postal}</p>
                                                </Col>
                                                <Col md={4}>
                                                    <h6>Country</h6>
                                                </Col>
                                                <Col  md={8}>
                                                    <p>{this.state.country}</p>
                                                </Col>
                                                
                                            </Row>
                                          
                                            </div>
                                           
                                            <Form.Group className="mt-3">
                                               <Button  onClick={()=>this.saveCards()} >Save Card</Button>
                                            </Form.Group>
                                            </div>
                                          

                                       </Tab.Pane>
                                        <Tab.Pane eventKey="pi">
                                         
                                        </Tab.Pane> */}
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        
                        </Row>
                    </div>
                <Footer/>
            </div>
        );
    }
}
const mapStatesToProps = state => ({
    saveCardData : state.webRed.saveCardData,
    saveCardDataDirect : state.webRed.saveCardDataDirect,
    creatpaymentmethodData : state.webRed.creatpaymentmethodData
    // extraSignUpFields:state.webRed.extraSignUpFields
})
export default connect(mapStatesToProps,{  saveCard, creatpaymentmethod })(Checkout);