import React, { Component } from "react";
import store from './../../store';
import PropTypes from 'prop-types';
import { sendPhoneNumber, sendVerificationCode } from './../../actions/webRedActions';

import { connect } from 'react-redux';

import { isMobileBrowser, validatePhone } from './../../actions/utility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    CustomInput,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    Label
  } from "reactstrap";

let additionalFields = []; // extrafields array global\

class PhoneNumberVerificationCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            verificationCode : "",
            codeError: false,
            showCountDown : false
        };
        

    }
    
    componentDidMount() {
        // setTimeout(() => {
            
            // this.callTimer(60);
        // }, 5000);
    }
    
    componentWillReceiveProps(nextProps, thisProps) {

        // if(nextProps.phoneNumberVerificationRes && this.props.phoneNumberVerificationRes != nextProps.phoneNumberVerificationRes){
        //     console.log(nextProps.phoneNumberVerificationRes);
        //     if(nextProps.phoneNumberVerificationRes.data.data.status == "success") {
        //         if(nextProps.phoneNumberVerificationRes.data.data.result && nextProps.phoneNumberVerificationRes.data.data.result == '0') {

        //             this.setState({
        //                 codeError : 'Invalid code. Please resend'
        //             })
                
        //         }
        //     }
        // }
        
        if(this.props.verifyScreenRandomProp != nextProps.verifyScreenRandomProp) {
            this.setState({
                showCountDown : true
            }, () => {
                this.callTimer(60);
            })
        }

    }

    callTimer = (timer) => {
        var value = timer;
        var interval = setInterval(() => {
            value = value - 1;
            var second = value,
            min = "";
            console.log(value);
            
            // if((value / 60) > 1){
            //     min = parseInt((value / 60)) + " min ";
            // } else {
            //     min = "";
            // }


            try{
                document.getElementById("counterHere").innerHTML = min + second + " sec";
            } catch(e) {
                clearInterval(interval);
            }

        }, 1000);

        setTimeout(() => {
            console.log('setTimeout');
            clearInterval(interval);
            this.setState({
                showCountDown : false
            })
        }, timer * 1000);

    }

    render() {

        const {phonenumber} = this.props;
        const {verificationCode, codeError, showCountDown} = this.state;

        return (
            <div style={{padding: '1vh 0'}} className="PhoneNumberVerificationCode" style={{
                background: 'f4eb49',
                color: '75a9f9'
            }}>
                <div className="wrapper"></div>
                
                <Container>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h3>
                                We have texted you a verification code to your phone number: <br/>{phonenumber}
                            </h3>
                            

                            <p style={{textAlign: 'center', fontWeight: '400', marginBottom: '7px', color: "#fff", fontSize: '1.5rem', marginTop: '35px'}}>
                                Enter <span style={{fontWeight: '700'}}>3 digit</span> code
                            </p>
                        </Col>
                    </Row>
                    
                    <Row className="phoneStyle">
                        <Col xs={{size : 8, offset: 2}}>
                            <FormGroup style={{position: 'relative'}}>
                                <Input type="number" name="verifyCode" id="verifyCode" value={verificationCode}
                                    onChange={(e) => {
                                        this.setState({
                                            verificationCode : e.target.value
                                        })
                                    }}
                                    maxLength="3"
                                    style={{
                                        textAlign: 'center',
                                        color: '#007bff',
                                        fontSize: '2rem',
                                        letterSpacing: '15px',
                                        borderRadius: '10px'
                                    }}
                                />
                            </FormGroup>

                        </Col>
                        
                        <Col md={{size: 12}}>
                            
                            {
                                showCountDown ?
                                    <div className="countDownBlockVerify">
                                        <span style={{color: '#f9a622'}}>Click here to resend in</span> <br/>
                                        <span id="counterHere" 
                                            style={{marginRight: '5px'}}
                                        ></span>
                                    </div>
                                : 
                                    <p style={{textAlign: 'center', color: "#fff", fontSize: '16px', cursor: 'pointer', margin: '30px 0', fontWeight: 'bold'}}
                                        onClick={(e) => this.props.sendPhoneNumber(phonenumber)}>
                                        Click here to resend
                                    </p>
                            }

                            {
                                verificationCode.length == 3 ?

                                    <Button 
                                        className="nextButtonNew"
                                        style={{ margin: '35px auto' }}
                                        onClick={(e) => {
                                            this.props.sendVerificationCode(verificationCode, phonenumber);
                                        }}
                                        size="lg" color="secondary" block>
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} size="2x" color="#fff" />
                                    </Button>

                                : null
                            }
                            
                            {
                                codeError && codeError.length ?
                                    <p className="errorClass">
                                        {codeError}
                                    </p>
                                : null
                            }

                        </Col>

                        
                    </Row>

                </Container>

            </div>
        );
    }
}

PhoneNumberVerificationCode.propTypes = {
    sendPhoneNumber: PropTypes.func.isRequired,
    sendVerificationCode: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // phoneNumberVerificationRes : state.webRed.phoneNumberVerificationRes,
    // validatePhoneExistsRes : state.webRed.validatePhoneExistsRes
    // extraSignUpFields:state.webRed.extraSignUpFields
})

export default connect(mapStatesToProps,{ sendPhoneNumber, sendVerificationCode })(PhoneNumberVerificationCode);