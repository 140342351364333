import React, { Component } from "react";
import store from './../../store';
import PropTypes from 'prop-types';
import { doneWithUserDetails } from './../../actions/webRedActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import SelectSearch from 'react-select-search';

import countryData from './../../actions/countryData';

import { connect } from 'react-redux';

import { isMobileBrowser, validatePhone } from './../../actions/utility';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    CustomInput,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    Label
  } from "reactstrap";

class UserDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            gender: '',
            birthDate: '',
            b_date : '',
            b_month : '',
            b_year : '',
            formError : false,
            
            errors: {
                firstname: '',
                lastname: '',
                gender: '',
                b_date: '',
                b_month: '',
                b_year: ''
            }
        };
        

    }
    
    componentDidMount() {

    }
    
    componentWillReceiveProps(nextProps, thisProps) {
                
    }
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
      
        switch (name) {
          case 'firstname': 
            errors.firstname = 
              value.length < 1
                ? 'This field is required'
                : '';
            break;
          case 'lastname': 
            errors.lastname = 
                value.length < 1
                    ? 'This field is required'
                    : '';
            break;
          case 'gender': 
            errors.gender = 
                value.length < 1
                    ? 'gender field is required'
                    : '';
            break;
            
          case 'b_name': 
            errors.birthDate = 
              value.length < 1
                  ? 'Date is required'
                  : '';
            break;
            
            case 'b_month': 
              errors.birthDate = 
                value.length < 1
                    ? 'Month is required'
                    : '';
              break;

            case 'b_year': 
                errors.birthDate = 
                value.length < 1
                    ? 'Year is required'
                    : '';
                break;
        //   case 'birthDate': 
        //     errors.birthDate = 
        //         value.length < 1
        //             ? 'Date is required'
        //             : '';
        //     break;
            
          default:
            break;
        }
      
        this.setState({errors, [name]: value}, () => {
            // console.log(errors)
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        // alert('asdsad')
        if(this.validateForm(this.state.errors)) {
            
            // hence, form is validated. now call the action to submit
            this.setState({
                formError : false,
                birthDate : this.state.b_month + "/" + this.state.b_date + "/" + this.state.b_year
            }, () => {
                
                const {firstname, lastname, gender, birthDate} = this.state;
                var userDetails = {
                    firstname: firstname,
                    lastname: lastname,
                    gender: gender,
                    birthDate: birthDate
                };
                this.props.doneWithUserDetails(userDetails);
            })

        } else{
            console.error('Invalid Form');
            this.setState({
                formError : true
                // formSuccess : false
            }, () => {
                console.log(this.state.formError)
            })
        }
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
    
    render() {

        const {newUserName} = this.props;
        const {firstname, lastname, gender, b_date, b_month, b_year, birthDate, errors, formError} = this.state;

        const errorStyle = {
            color: '#fff', fontSize: '12px', textAlign: 'right', paddingRight: '10px'
        };

        
        const genderClass = {
            background : 'transparent', borderColor: '#fff', margin : '0 4px'
        }

        const genderActive = {
            background : '#fff', color: '#ff00a2', borderColor: '#fff', margin : '0 4px'
        }

        return (
            <div style={{padding: '1vh 0'}} className="UserDetails">
                <div className="wrapper"></div>
                
                <Container>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h3 style={{margin: "50px 0px 50px 0px"}}>
                                Who is {newUserName} ?
                            </h3>
                        </Col>
                    </Row>
                    
                    <Row className="phoneStyle">
                        <Col xs={{size : 12}}>

                            <FormGroup style={{position: 'relative'}}>
                                <label>First name</label>
                                <Input type="text" name="firstname" id="firstname" value={firstname}
                                    onChange={evt => this.handleChange(evt)}
                                    style={{
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                />
                                <p style={errorStyle}>{errors.firstname}</p>
                            </FormGroup>

                            <FormGroup style={{position: 'relative'}}>
                                <label>Last name</label>
                                <Input type="text" name="lastname" id="lastname" value={lastname}
                                    onChange={evt => this.handleChange(evt)}
                                    style={{
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                />
                                <p style={errorStyle}>{errors.lastname}</p>
                            </FormGroup>

                            <FormGroup style={{position: 'relative', textAlign: 'center', width: '90%', margin: 'auto'}}>
                                <label style={{marginLeft: 0, paddingLeft: '8px'}}>Gender</label><br/>

                                <Button value={"Male"}
                                    name="gender"
                                    onClick={evt => this.handleChange(evt)}
                                    style={gender == 'Male' ? genderActive : genderClass}
                                    size="md" color="secondary">Male</Button>

                                <Button value={"Female"}
                                    name="gender"
                                    onClick={evt => this.handleChange(evt)}
                                    style={gender == 'Female' ? genderActive : genderClass}
                                    size="md" color="secondary">Female</Button>
                                
                                <Button value={"Non-binary"}
                                    name="gender"
                                    onClick={evt => this.handleChange(evt)}
                                    style={gender == 'Non-binary' ? genderActive : genderClass}
                                    size="md" color="secondary">Non-binary</Button>

                                <p style={errorStyle}>{errors.gender}</p>
                            </FormGroup>

                            <FormGroup style={{position: 'relative', padding: '0 15px'}}>
                                <label style={{
                                    paddingLeft: "7px",
                                    // borderBottom: '1px #fafafa solid'
                                }}>Date of birth</label>
                                <Row>
                                    <Col xs={{size: 4}} style={{paddingRight: "5px"}}>
                                        <Input type="select" name="b_date" id="b_date" 
                                            style={{padding: 0, textAlign: 'center'}}
                                            onChange={evt => this.handleChange(evt)}
                                        >
                                            <option value="" disabled selected >day</option>
                                            <option value="01">1</option>
                                            <option value="02">2</option>
                                            <option value="03">3</option>
                                            <option value="04">4</option>
                                            <option value="05">5</option>
                                            <option value="06">6</option>
                                            <option value="07">7</option>
                                            <option value="08">8</option>
                                            <option value="09">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                        </Input>
                                        <label>Day</label>
                                    </Col>
                                    <Col xs={{size: 4}} style={{padding: 0}}>
                                        <Input type="select" name="b_month" id="b_month"
                                            style={{padding: 0, textAlign: 'center'}}
                                            onChange={evt => this.handleChange(evt)}
                                        >
                                            <option value="" disabled selected >month</option>
                                            <option value="01">1</option>
                                            <option value="02">2</option>
                                            <option value="03">3</option>
                                            <option value="04">4</option>
                                            <option value="05">5</option>
                                            <option value="06">6</option>
                                            <option value="07">7</option>
                                            <option value="08">8</option>
                                            <option value="09">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </Input>
                                        <label>Month</label>
                                    </Col>
                                    <Col xs={{size: 4}} style={{paddingLeft: "5px"}}>
                                        <Input type="select" name="b_year" id="b_year"
                                            style={{padding: 0, textAlign: 'center'}}
                                            onChange={evt => this.handleChange(evt)}
                                        >
                                            <option value="" disabled selected >year</option>
                                            <option value="1920">1920</option>
                                            <option value="1921">1921</option>
                                            <option value="1922">1922</option>
                                            <option value="1923">1923</option>
                                            <option value="1924">1924</option>
                                            <option value="1925">1925</option>
                                            <option value="1926">1926</option>
                                            <option value="1927">1927</option>
                                            <option value="1928">1928</option>
                                            <option value="1929">1929</option>
                                            <option value="1930">1930</option>
                                            <option value="1931">1931</option>
                                            <option value="1932">1932</option>
                                            <option value="1933">1933</option>
                                            <option value="1934">1934</option>
                                            <option value="1935">1935</option>
                                            <option value="1936">1936</option>
                                            <option value="1937">1937</option>
                                            <option value="1938">1938</option>
                                            <option value="1939">1939</option>
                                            <option value="1940">1940</option>
                                            <option value="1941">1941</option>
                                            <option value="1942">1942</option>
                                            <option value="1943">1943</option>
                                            <option value="1944">1944</option>
                                            <option value="1945">1945</option>
                                            <option value="1946">1946</option>
                                            <option value="1947">1947</option>
                                            <option value="1948">1948</option>
                                            <option value="1949">1949</option>
                                            <option value="1950">1950</option>
                                            <option value="1951">1951</option>
                                            <option value="1952">1952</option>
                                            <option value="1953">1953</option>
                                            <option value="1954">1954</option>
                                            <option value="1955">1955</option>
                                            <option value="1956">1956</option>
                                            <option value="1957">1957</option>
                                            <option value="1958">1958</option>
                                            <option value="1959">1959</option>
                                            <option value="1960">1960</option>
                                            <option value="1961">1961</option>
                                            <option value="1962">1962</option>
                                            <option value="1963">1963</option>
                                            <option value="1964">1964</option>
                                            <option value="1965">1965</option>
                                            <option value="1966">1966</option>
                                            <option value="1967">1967</option>
                                            <option value="1968">1968</option>
                                            <option value="1969">1969</option>
                                            <option value="1970">1970</option>
                                            <option value="1971">1971</option>
                                            <option value="1972">1972</option>
                                            <option value="1973">1973</option>
                                            <option value="1974">1974</option>
                                            <option value="1971">1971</option>
                                            <option value="1972">1972</option>
                                            <option value="1973">1973</option>
                                            <option value="1975">1975</option>
                                            <option value="1976">1976</option>
                                            <option value="1977">1977</option>
                                            <option value="1978">1978</option>
                                            <option value="1979">1979</option>
                                            <option value="1980">1980</option>
                                            <option value="1981">1981</option>
                                            <option value="1982">1982</option>
                                            <option value="1983">1983</option>
                                            <option value="1984">1984</option>
                                            <option value="1985">1985</option>
                                            <option value="1986">1986</option>
                                            <option value="1987">1987</option>
                                            <option value="1988">1988</option>
                                            <option value="1989">1989</option>
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                            <option value="1994">1994</option>
                                            <option value="1995">1995</option>
                                            <option value="1996">1996</option>
                                            <option value="1997">1997</option>
                                            <option value="1998">1998</option>
                                            <option value="1999">1999</option>
                                            <option value="2000">2000</option>
                                            <option value="2001">2001</option>
                                            <option value="2002">2002</option>
                                            <option value="2003">2003</option>
                                            <option value="2004">2004</option>
                                            <option value="2005">2005</option>
                                            <option value="2006">2006</option>
                                            <option value="2007">2007</option>
                                            <option value="2008">2008</option>
                                            <option value="2009">2009</option>
                                            <option value="2010">2010</option>
                                            <option value="2011">2011</option>
                                            <option value="2012">2012</option>
                                            <option value="2013">2013</option>
                                            <option value="2014">2014</option>
                                            <option value="2015">2015</option>
                                            <option value="2016">2016</option>
                                            <option value="2017">2017</option>
                                            <option value="2018">2018</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                        </Input>
                                        <label>Year</label>
                                    </Col>
                                </Row>
                                {/* <Input type="date" name="birthDate" id="birthDate" value={birthDate}
                                    onChange={evt => this.handleChange(evt)}
                                    style={{
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                /> */}
                                <p style={errorStyle}>{errors.birthDate}</p>
                            </FormGroup>
                            

                        </Col>
                        
                        <Col md={{size: 12}}>

                            {
                                formError ?
                                    <p style={{color: '#fff', textAlign : 'center'}}>All fields are required.</p>
                                : null
                            }
                            {
                                firstname.length && lastname.length && gender.length && b_date.length && b_month.length && b_year.length ?
                                    
                                    <Button 
                                        className="nextButtonNew"
                                        style={{ margin: '35px auto' }}
                                        onClick={(e) => {
                                            this.handleSubmit(e);
                                        }}
                                        size="lg" color="secondary" block>
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} size="2x" color="#fff" />
                                    </Button>

                                : null
                            }
                                
                        </Col>
                        
                    </Row>

                </Container>

            </div>
        );
    }
}

UserDetails.propTypes = {
    doneWithUserDetails: PropTypes.func.isRequired,
    // sendVerificationCode: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // isUserNameExists : state.webRed.isUserNameExists
    // validatePhoneExistsRes : state.webRed.validatePhoneExistsRes
    // extraSignUpFields:state.webRed.extraSignUpFields
})

export default connect(mapStatesToProps,{ doneWithUserDetails })(UserDetails);