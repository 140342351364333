import React, { Component } from "react";
import Header from './Header';
import Footer from "./Footer";

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";


class RefundPolicyComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

    componentDidMount() {
        document.title = "Refund Policy - Lincoln Method";
    }
    
    render() {
        
        return (
        // <DocumentMeta {...meta}>
            <div className="RefundPolicyComponent">

                <Header />

                    <div className="homeEdtech" style={{backgroundColor: '#121625'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <h2>Refund Policy</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="researchContent" style={{padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row style={{paddingBottom: '10px'}}>
                                <Col md={{ size: 12 }}>
                                    <p>
                                    If you paid for an individual course, you can request a refund within 2 weeks of your payment. You can’t get a refund if you’ve completed the course. Or if 2 weeks have been passed.
                                    <br/>
If you subscribed to a Course or a Specialization, your subscription payments are not refundable, but you can cancel the subscription to end future payments.
<br/>
To request a refund, please contact us.
<br/>
“When you request a refund:”<br/>
You’ll get a confirmation email when we get your refund request.<br/>
You’ll be un-enrolled from the course.<br/>
It can take up to 7 days for funds to return to your account after a requested refund. If it has been longer than 7 days, contact us about the problem.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default RefundPolicyComponent;