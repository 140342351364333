import React, { Component } from "react";

// import store from './../../store';
import PropTypes from 'prop-types';
import { triggerCongratulationsAnimation, doLoginSession } from './../../actions/webRedActions';
import { connect } from 'react-redux';

import Header from './../website/Header';
import Footer from "./../website/Footer";

import {
      Container,
      Row,
      Col,
      Button
    } from "reactstrap";


class ConfirmEmailComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            detailEncoded : this.props.match.params.detail,
            userEmail : ""
            // isCourseButton : false,
            // courseId : ''
        }

    }

    componentDidMount() {
        document.title = "Confirm email verification";
        
        if(this.state.detailEncoded && this.state.detailEncoded != '' && this.state.detailEncoded != undefined){
            
            var decodedDetail = atob(this.state.detailEncoded);
            
            var url = new URLSearchParams(decodedDetail);
            var email = url.get("email");

            this.setState({
                userEmail : email
            })
            // fullname = url.get("fullname");

        }
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(nextProps, thisProps) {

    }
    
    render() {
        
        return (
            <div className="ConfirmEmailComponent" style={{color: '#000'}}>

                {/* <Header /> */}

                <div>
                    <Container>
                        <Row>
                            <Col md={{size : 12 }} style={{padding: '30vh 0', zIndex: '9999'}}>
                                <h1 style={{color: '#f8ab24', fontSize: '3rem'}}><strong>Email verification</strong></h1><br/>
                                <h3><strong>{this.state.userEmail}</strong> has been successfully verified.</h3>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
                
                {/* <Footer /> */}
            </div>
        );
    }
}

ConfirmEmailComponent.propTypes = {
    
}

const mapStateToProps = state =>({
    // coursesData : state.webRed.coursesData
    // loginSession : state.webRed.loginSession
})

export default connect(mapStateToProps, {  }) (ConfirmEmailComponent);
  