import React, { Component } from "react";
import store from './../../store';
import PropTypes from 'prop-types';
import { triggerNewUserType, doLogin } from './../../actions/webRedActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import SelectSearch from 'react-select-search';

import countryData from './../../actions/countryData';

import { connect } from 'react-redux';

import { isMobileBrowser, validatePhone } from './../../actions/utility';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    CustomInput,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    Label
  } from "reactstrap";

let additionalFields = []; // extrafields array global\

class UsernamesListings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            askForChild : false,
            isChild : false,
        };
        

    }
    
    componentDidMount() {

    }
    
    componentWillReceiveProps(nextProps, thisProps) {        

    }
    
    render() {

        const {phonenumber, newuser, registeredUsers, selectedCourse} = this.props;
        const {askForChild, isChild} = this.state;

        return (
            <div style={{padding: '1vh 0'}} className="UsernamesListings" style={{
                background: 'f4eb49',
                color: '75a9f9'
            }}>
                <div className="wrapper"></div>
                
                {
                newuser || askForChild ?
                    <Container>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <h3>Who is this for?</h3>
                                <p style={{margin: '15px 0'}}>{selectedCourse.course_name}</p>
                            </Col>
                        </Row>
                        
                        <Row className="phoneStyle" style={{marginTop: '90px'}}>
                            <Col xs={{size : 10, offset: 1}}>
                                <Button style={{ margin: '20px 0', padding: '15px' }} 
                                    onClick={(e) => this.setState({
                                        askForChild : false,
                                        isChild : true
                                    }, () => {
                                        this.props.triggerNewUserType('child');
                                    })}
                                    size="lg" block color="primary"
                                >My child</Button>

                                <Button style={{ margin: '20px 0', padding: '15px' }} 
                                    onClick={(e) => this.setState({
                                        askForChild : false,
                                        isChild : false
                                    }, () => {
                                        this.props.triggerNewUserType('myself');
                                    })}
                                    size="lg" block color="primary"
                                >Myself</Button>
                            
                            </Col>                        
                        </Row>

                    </Container>
                :
                    <Container>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                {/* <h3>Who is this for?</h3> */}
                                <p style={{margin: '50px 0px', fontSize: '22px'}}>
                                    These usernames are already registered under <strong>{phonenumber}</strong>
                                </p>
                            </Col>
                        </Row>
                        
                        <Row className="phoneStyle" style={{marginTop: '90px'}}>
                            <Col xs={{size : 12}}>
                                <ul className="registeredUsersList">
                                    {
                                        registeredUsers.map((user, key) =>
                                            <li key={key} onClick={(e) => {
                                                
                                                var loginType = 'course';
                                                var courseId = selectedCourse.id;
                                                var isPurchase = 1;
                                                var isPassRequired = 0;

                                                this.props.doLogin(user.user_loginname, user.user_password, courseId, isPurchase, loginType, isPassRequired); // calling the action to login in now
                                                
                                            }}>
                                                <Button color="secondary"
                                                    onClick={(e) => console.log(e)}
                                                >use<br/>this</Button>
                                                <p>
                                                    {user.user_loginname} <br/>
                                                    <span>{user.user_name}</span>
                                                </p>
                                            </li>
                                        )
                                    }
                                    
                                    <li>
                                        <p className="seperator">or</p>
                                    </li>
                                    <li>
                                        <Button color="primary" size="lg" block onClick={(e) => this.setState({
                                            askForChild : true
                                        })}>
                                            <FontAwesomeIcon style={{marginRight: '10px'}} icon={faPlus} size="1x" color="#fff" />
                                            Add new username
                                        </Button>
                                    </li>
                                </ul>
                            </Col>                        
                        </Row>

                    </Container>
                }


            </div>
        );
    }
}

UsernamesListings.propTypes = {
    triggerNewUserType: PropTypes.func.isRequired,
    doLogin: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // phoneNumRes : state.webRed.phoneNumRes,
    // validatePhoneExistsRes : state.webRed.validatePhoneExistsRes
    // extraSignUpFields:state.webRed.extraSignUpFields
})

export default connect(mapStatesToProps,{ triggerNewUserType, doLogin })(UsernamesListings);