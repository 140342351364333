import React, { Component } from "react";
import './../../../public/scss/CoursesComponent.scss';

// import store from './../../store';
import PropTypes from 'prop-types';
import { getCourseDetails, getTestPreview, triggerLoginPopup, triggerEnrollPopup, triggerCheckoutPopup, getSignUpFormType, verifypaymongopayment } from './../../../actions/webRedActions';
import { getBaseUrl, isMobileBrowser, reserveBackOnMobile, getLastLinkStorage } from "./../../../actions/utility";
import { connect } from 'react-redux';

import Header from './../Header';
import Footer from "./../Footer";
// import LoginComponent from './../../login/LoginComponent'; // importing login popup component
import CheckoutPaymentComponent from './../../checkout/CheckoutPaymentComponent'; // importing checkout popup component

// import SocialLoginComponent from'./../../login/SocialLoginComponent';

import { formatPrice } from './../../../actions/utility';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Table
} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faMapMarkerAlt, faBook, faVideo, faWifi, faCalendarAlt, faClock, faWallet, faPlus, faMinus, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import moment from "moment-timezone";


class CourseDetailComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            selectedCourse: {},
            daysPrices: [],
            course_price: '',
            course_discount_price: '',
            old_price: '',
            csrfToken: '',
            questionHtml: '',
            // questionHtmlShowPopup : false,
            htmlModal: false,
            htmlModalLoader: false,
            htmlmodalerror: false,
            // showLoginPopup : false,
            // showCheckoutPopup : false,
            selectedDayId: 0,
            enableEnrollButton: true,
            daysErrorTrigger: false,
            isSelectDayFieldBorder: false,
            // courseTimings : localStorage.getItem('timingCourse'),
            // courseTeachers : localStorage.getItem('teacherDetails')
            courseTimings: '',
            courseTeachers: '',
            showModalCoupon: false,
            modalCouponCode: "FREECLASS",
            modalCouponText: "If you want a demo class for this course, please click on the ENROLL button and use the promo code.",
            zone: ""
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.openLoginModal = this.openLoginModal.bind(this);
        this.daysModalTrigger = this.daysModalTrigger.bind(this);
        this.couponModalTrigger = this.couponModalTrigger.bind(this);
        // this.openCheckoutModal = this.openCheckoutModal.bind(this);

    }

    // openLighBox(index) {
    //     this.setState({
    //         photoIndex: index,
    //         isOpen: true
    //     })
    // }

    componentDidMount() {

        document.title = "Course Details - Lincoln Method";
        this.props.getCourseDetails(this.props.match.params.courseid); //getting details for this course

        window.scrollTo(0, 0); //scroll to top

        if (getLastLinkStorage() == '/online-school-demo') {
            this.setState({
                showModalCoupon: true
            })
        } else if (getLastLinkStorage() == '/holy-friday-sale') {
            this.setState({
                showModalCoupon: true,
                modalCouponCode: "FridaySale",
                modalCouponText: "Please click on the ENROLL button, add the promo code, and avail the BLESSED FRIDAY SALE."
            })
        } else if (getLastLinkStorage() == '/affordable-education') {
            this.setState({
                showModalCoupon: true,
                modalCouponCode: "",
                modalCouponText: "On this page you can see the class timings, the teacher, and all the chapters covered in this course. <br/><br/>When you are ready, you can click on the ENROLL button."
            })
        }
        var sone = moment.tz.guess();
        this.setState({
            zone: sone
        })

        if(window.location.href.includes('paymongo_api')) {
            debugger;
            var loginStatus = localStorage.getItem('loginData');
            var studentId = JSON.parse(loginStatus).user.id;
            verifypaymongopayment(this.props.match.params.courseid , studentId)
            .then((res) => {
                debugger;
                if(res.data.status == 'error') {
                    window.location = `/#/course/${this.props.match.params.courseid}`
                }
                else if(res.data.status == 'fail') {
                    confirmAlert({
                        title: 'Payment Fail.',
                        message: "",
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                        buttons: [
                            {
                                label: 'OK',
                                onClick: () => {
                                    window.location = `/#/course/${this.props.match.params.courseid}`;
                                }
                            }
                        ]
                    })
                }
                else if(res.data.status == 'success') {
                    confirmAlert({
                        title: 'Payment Successful.',
                        message: "",
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                        buttons: [
                            {
                                label: 'OK',
                                onClick: () => {
                                    window.location = `/#/course/${this.props.match.params.courseid}`;
                                }
                            }
                        ]
                    })
                }
            })
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {

        if (this.props.courseDetail != nextProps.courseDetail) {
            // console.log(nextProps.courseDetail);
            this.setState({
                selectedCourse: nextProps.courseDetail.course,
                daysPrices: nextProps.courseDetail.daysPrices,
                csrfToken: nextProps.courseDetail._token,
            },
                () => {
                    // console.log(this.state.selectedCourse)
                    this.setState({
                        loading: false,
                        course_price: this.state.selectedCourse.course_price,
                        course_discount_price: this.state.selectedCourse.course_discount_price,
                        old_price: this.state.selectedCourse.old_price,
                        courseTimings: this.state.selectedCourse.timing,
                        courseTeachers: this.state.selectedCourse.teachers_info
                    });

                    // get the courseId and calls action for signup form form
                    this.props.getSignUpFormType(this.state.selectedCourse.id);

                    // if(nextProps.courseDetail.daysPrices.length){
                    //     localStorage.setItem('selectedDayId', nextProps.courseDetail.daysPrices[0].id);
                    // }
                    // else{
                    localStorage.setItem('selectedDayId', this.state.selectedDayId);
                    // }

                    if (this.state.daysPrices.length > 0) { // if there's days to select it'll disable the enroll button
                        this.setState({
                            enableEnrollButton: false
                        })
                    }

                    //check if triggerCheckoutPopupCourse in localstorage trigger the checkout popup automatically
                    var triggerCheckoutPopupCourse = localStorage.getItem('triggerCheckoutPopupCourse');
                    if (triggerCheckoutPopupCourse && (triggerCheckoutPopupCourse == 1 || triggerCheckoutPopupCourse == '1')) {
                        this.openLoginModal();
                        localStorage.setItem('triggerCheckoutPopupCourse', 0);
                    }

                    // console.log(this.state.selectedCourse)
                    // console.log(this.state.daysPrices)

                    // window.scrollTo(0, 0); //scroll to top

                })
        }

        if (this.props.questionHtml != nextProps.questionHtml) {
            console.log(nextProps.questionHtml)
            this.setState({
                questionHtml: nextProps.questionHtml
            }, () => {
                this.setState({
                    htmlModal: true, // show the modal for preview for question
                    htmlModalLoader: false
                }, () => {
                    if (isMobileBrowser()) {
                        reserveBackOnMobile(true);
                    }
                })
            })
        }

        // if(typeof nextProps.loginSuccessWithCourse === 'object' && nextProps.loginSuccessWithCourse.status == 'success' ) {
        //     this.setState({
        //         showLoginPopup : false
        //     }, () => {
        //         this.openCheckoutModal(); //call here payment model
        //     })
        // }

    }

    updatePrice = (e) => {
        var index = e.target.value;
        console.log(index);
        if (index != -1) {
            this.setState({
                course_price: this.state.daysPrices[index].price,
                course_discount_price: this.state.daysPrices[index].discount_price,
                selectedDayId: index,
                enableEnrollButton: true
            }, () => {
                localStorage.setItem('selectedDayId', this.state.selectedDayId);
            })
        }
        else {
            this.setState({
                enableEnrollButton: false
            })
        }
    }

    demoPopupLaunch = (e, program_id, assessment_id, testid, question_id) => {
        // console.log(program_id, assessment_id, testid, question_id);
        var data = {
            token: this.state.csrfToken,
            questionid: question_id,
            assessment_id: assessment_id,
            testid: testid,
            program_id: program_id
        };
        this.props.getTestPreview(data);
        this.setState({
            htmlModalLoader: true
        })
    }

    redirectToDemoDashboard = () => {
        localStorage.setItem('loginType', 'course'); // set loginType course to load login popup with individual course forms
        localStorage.setItem('demoAfterLogin', this.state.selectedCourse.id); // set loginType course to load login popup with individual course forms
        var loginStatus = localStorage.getItem('loginData');
        if (loginStatus != null && loginStatus != "" && typeof JSON.parse(loginStatus) === 'object') {
            window.location = getBaseUrl() + "/takedemo/" + this.state.selectedCourse.id + "/" + JSON.parse(loginStatus).user.id; // redirect to take demo on dashboard
        }
        else {

            // if(this.state.selectedCourse.is_school == 1) {
            //     this.props.triggerEnrollPopup();
            // } else {
            this.props.triggerLoginPopup(); // triggers the login modal
            // }

        }
    }


    toggleModal() {
        this.setState(prevState => ({
            htmlModal: !prevState.htmlModal,
            htmlmodalerror: false
        }));


        if (isMobileBrowser()) {
            reserveBackOnMobile(false);
        }
    }

    daysModalTrigger() {
        this.setState(prevState => ({
            daysErrorTrigger: !prevState.daysErrorTrigger
        }), () => {
            this.setState({
                isSelectDayFieldBorder: true
            })
        });
    }

    couponModalTrigger() {
        this.setState(prevState => ({
            showModalCoupon: !prevState.showModalCoupon
        }))
    }

    openLoginModal() {
        // localStorage.setItem('loginType', 'course'); // set loginType normal to load login popup with normal forms
        // try{
        //     var loginStatus = localStorage.getItem('loginData');

        //     if(loginStatus !=null && typeof JSON.parse(loginStatus) ==='object') {
        //         this.openCheckoutModal(); //call here payment model
        //     }
        //     else {
        //         this.setState({
        //             showLoginPopup : Math.random()
        //         })
        //     }
        // }
        // catch(err) {
        //     this.setState({
        //         showLoginPopup : Math.random()
        //     })
        // }
        if (this.state.enableEnrollButton) {

            localStorage.setItem('loginType', 'course'); // set loginType course to load login popup with individual course forms
            var loginStatus = localStorage.getItem('loginData');
            if (loginStatus != null && loginStatus != "" && typeof JSON.parse(loginStatus) === 'object') {
                this.props.triggerCheckoutPopup(); // triggers the checkout modal
            }
            else {

                // if(this.state.selectedCourse.is_school == 1) {
                //     this.props.triggerEnrollPopup();
                // } else {
                this.props.triggerLoginPopup(); // triggers the login modal
                // }

            }

        }
        else {
            this.setState({
                daysErrorTrigger: true
            })
        }
    }

    // openCheckoutModal() {
    //     this.setState({
    //         showCheckoutPopup : Math.random()
    //     })
    // }

    //TimeZone Functions Start
    AMPMTo24HourConversion = (times) => {
        var patt1 = /\s/g;
        var time = times;
        debugger;
        if (times.match(patt1)) {
            debugger;
            var hours = Number(time.match(/^(\d+)/)[1]);
            var minutes = Number(time.match(/:(\d+)/)[1]);
            var AMPM = time.match(/\s(.*)$/)[1];
            debugger;
            if (AMPM == "PM" && hours < 12) hours = hours + 12;
            if (AMPM == "AM" && hours == 12) hours = hours - 12;
            var sHours = hours.toString();
            var sMinutes = minutes.toString();
            if (hours < 10) sHours = "0" + sHours;
            if (minutes < 10) sMinutes = "0" + sMinutes;
            // alert(sHours + ":" + sMinutes);
            return sHours + ":" + sMinutes;
        }
        else {
            if (time.includes("PM")) {
                debugger;
                time = time.replace("PM", " PM");
            }
            else if (time.includes("AM")) {
                debugger;
                time = time.replace("AM", " AM");
            }
            debugger;
            var hours = Number(time.match(/^(\d+)/)[1]);
            var minutes = Number(time.match(/:(\d+)/)[1]);
            var AMPM = time.match(/\s(.*)$/)[1];
            debugger;
            if (AMPM == "PM" && hours < 12) hours = hours + 12;
            if (AMPM == "AM" && hours == 12) hours = hours - 12;
            var sHours = hours.toString();
            var sMinutes = minutes.toString();
            if (hours < 10) sHours = "0" + sHours;
            if (minutes < 10) sMinutes = "0" + sMinutes;
            // alert(sHours + ":" + sMinutes);
            return sHours + ":" + sMinutes;
        }
    }

    minutesToHour = (n) => {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rminutes == 0) {
            rminutes = "00"
        }
        console.log(num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).");
        return {
            rhours,
            rminutes
        }
    }
    timeConvert24to12 = (time) => {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {                                                 // If time format correct
            time = time.slice(1);                                              // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM';                           // Set AM/PM
            time[0] = +time[0] % 12 || 12;                                     // Adjust hours
        }
        return time.join('');                                                  // return adjusted time or original string
    }
    changeTimeZoneLocation = (time) => {
        var TIME = this.AMPMTo24HourConversion(time);                          // Convert 12 hour to 24 Hour Time System
        var convertedTimeinMinutes = moment.duration(TIME).asMinutes();        // Convert Hours into Minutes
        var makingTimeDifferenceZero = convertedTimeinMinutes - 300;           // Subtract Pakistan's GMT time with Initial Time.
        var usersCountryOffset = moment().tz(this.state.zone).utcOffset();     // Get Client's UTCoffset by timezone
        var timeinMinutes = makingTimeDifferenceZero + usersCountryOffset;     // Add Clients time by initial time
        var timeinHours = this.minutesToHour(timeinMinutes);                   // Convert minutes into Hour
        var ActualConvertedTime = "";
        if (timeinHours.rhours >= 12) {
            ActualConvertedTime = this.timeConvert24to12(`${timeinHours.rhours}:${timeinHours.rminutes}`); //Convert 24 Hours Back to 12 Hours
        }
        else {
            ActualConvertedTime = timeinHours.rhours + ":" + timeinHours.rminutes + " AM"
        }
        // console.log("Time Converted 24 to 12:", ActualConvertedTime)
        // console.log("Time in Minutes", timeinMinutes)
        // console.log("Time in Hours", timeinHours.rhours, timeinHours.rminutes)
        // console.log("User Country Offset", usersCountryOffset)
        // console.log("Converted Time in Minutes", convertedTimeinMinutes)
        // console.log("After Making Time Difference Zero", makingTimeDifferenceZero);
        // console.log("timein Hours", timeinHours.rhours, timeinHours.rminutes);
        return ActualConvertedTime
    }
    //TimeZone Functions End
    returnListTimings = () => {
        let daysArary = [];

        var timings = this.state.courseTimings;
        timings = JSON.parse(timings);

        Object.keys(timings).map((id, key) => {
            var day = timings[id];
            if (day.start != '' && day.end != '') {
                var start_time = day.start;
                var firstCharStart = start_time.split("");
                if (firstCharStart[0] == '0') {
                    start_time = start_time.substring(1);
                }

                var end_time = day.end;
                var firstCharEnd = end_time.split("");
                if (firstCharEnd[0] == '0') {
                    end_time = end_time.substring(1);
                }

                var dayName = day.day.split("");

                let dayData = <tr key={key}>
                    <th style={{ padding: '5px 0px 5px 15px' }}>{dayName[0] + dayName[1] + dayName[2]}</th>
                    <td style={{ padding: '5px 0' }}>
                        <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faClock} size="1x" color="#ff0863" />
                        {this.changeTimeZoneLocation(start_time)} - {this.changeTimeZoneLocation(end_time)}
                    </td>
                </tr>;
                daysArary.push(dayData);
            }
        })

        console.log(daysArary);
        return daysArary;
    }

    showTeacherDetails = () => {
        let teachersArary = [];

        var details = JSON.parse(this.state.courseTeachers);

        Object.keys(details).map((id, key) => {
            var teacher = details[id];
            let item = <li key={key}>
                <Media width="30" className="tutorThumb" style={{ marginRight: '10px' }} object src={teacher.img_src && teacher.img_src !== '' && teacher.img_src !== "https://lincolnmethod.s3.us-east-1.amazonaws.com/profileImages/" ? teacher.img_src : 'https://pngimage.net/wp-content/uploads/2018/06/no-user-image-png.png'} />

                {
                    teacher.name && teacher.name != '' ?
                        teacher.name
                        : ""
                }
            </li>;
            teachersArary.push(item);
        })

        return teachersArary;


    }
    
    render() {
        console.log("Course Timings" , this.state.courseTimings);
        console.log("courseTeachers" , this.state.courseTeachers);


        return (
            // <DocumentMeta {...meta}>
            <div className="CourseDetailComponent">

                <Header />

                {/* <LoginComponent courseid={this.props.match.params.courseid} showPopup={this.state.showLoginPopup} /> */}
                {/* <CheckoutPaymentComponent showPopup={this.state.showCheckoutPopup} /> */}

                <Modal isOpen={this.state.showModalCoupon} centered={true} size="sm">
                    <ModalBody>
                        <p><span dangerouslySetInnerHTML={{ __html: this.state.modalCouponText }}></span><br /><strong>{this.state.modalCouponCode != '' ? "Promo code: " + this.state.modalCouponCode : ''}</strong></p>
                        {/* <h3>{this.state.modalCouponCode}</h3> */}
                        <Button color="primary" size="md" style={{ marginTop: '15px' }} onClick={this.couponModalTrigger}>Thanks</Button>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.daysErrorTrigger} toggle={this.daysModalTrigger} centered={true} size="sm">
                    <ModalBody >
                        Please select days first
                        </ModalBody>
                </Modal>

                {
                    this.state.htmlModal ?
                        <Modal isOpen={this.state.htmlModal} toggle={this.toggleModal} centered={true} size="lg" className="htmlModalMain">
                            <ModalBody style={{ padding: '5px' }}>
                                <p className="closeModalLogin" onClick={this.toggleModal}>X</p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.questionHtml }}></div>
                                {
                                    this.state.htmlmodalerror ?
                                        <p className="htmlmodalerror">You can experience this feature once you purchase the course. &nbsp;
                                        {
                                                this.state.enableEnrollButton ?
                                                    <span onClick={this.openLoginModal} style={{ color: '#ffc107', cursor: 'pointer' }}>{this.state.selectedCourse.is_online == 2 ? 'Buy now' : 'Enroll now'}</span>
                                                    : null
                                            }
                                        </p>
                                        : null
                                }
                            </ModalBody>
                            <ModalFooter>
                                <ButtonGroup style={{ width: '100%' }}>
                                    <Button onClick={this.toggleModal}>Go Back</Button>
                                    <Button onClick={() => this.setState({ htmlmodalerror: true })}>Try Again</Button>
                                    <Button onClick={() => this.setState({ htmlmodalerror: true })}>Show Answer</Button>
                                    <Button onClick={() => this.setState({ htmlmodalerror: true })}>Learn</Button>
                                    <Button onClick={() => this.setState({ htmlmodalerror: true })}>Move On</Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </Modal>
                        :
                        this.state.htmlModalLoader ?
                            <Spinner style={{ width: '3rem', height: '3rem', margin: '30vh -1.5rem', position: 'fixed', zIndex: '999' }} type="grow" color="dark" />
                            : null
                }

                {
                    !this.state.loading ?
                        <div>
                            <Container>
                                <Row>
                                    <Col md={{ size: 12 }}>

                                        {
                                            this.state.selectedCourse.course_img_cover && this.state.selectedCourse.course_img_cover != null && this.state.selectedCourse.course_img_cover != '' ?
                                                <Media width="100%" object src={'https://dashboard.lincolnmethod.com/uploads/' + this.state.selectedCourse.course_img_cover} alt={this.state.selectedCourse.course_name} />
                                                :
                                                this.state.selectedCourse.course_img && this.state.selectedCourse.course_img != null && this.state.selectedCourse.course_img != '' ?
                                                    <Media width="100%" object src={'https://dashboard.lincolnmethod.com/uploads/' + this.state.selectedCourse.course_img} alt={this.state.selectedCourse.course_name} /> : null
                                        }

                                    </Col>
                                    <Col style={{ textAlign: 'left', padding: '5vh 0' }} md={{ size: 12 }}>
                                        <Container>
                                            <Row>
                                                <Col md={{ size: 8 }}>
                                                    {
                                                        this.state.selectedCourse.course_name ?
                                                            <h2 style={{ fontWeight: 'bold' }}>{this.state.selectedCourse.course_name}</h2> : null
                                                    }
                                                </Col>
                                                <Col md={{ size: 4 }} xs={{ size: 12 }} style={{ textAlign: 'right' }}>
                                                    {
                                                        this.state.daysPrices.length ?
                                                            <Input onChange={(e) => this.updatePrice(e)} style={{ width: '220px', display: 'inline-block', marginBottom: '10px', border: !this.state.enableEnrollButton && this.state.isSelectDayFieldBorder ? '2px red solid' : '' }} type="select" name="selectDays" id="selectDays">
                                                                <option value={-1} defaultValue>
                                                                    Select Days
                                                                </option>
                                                                {
                                                                    this.state.daysPrices.map((eachDay, key) =>
                                                                        <option key={key} price={eachDay.price} discount_price={eachDay.discount_price} value={key}>
                                                                            {eachDay.days}
                                                                            {eachDay.days == 1 ? ' day' : ' days'} a week
                                                                        </option>
                                                                    )
                                                                }
                                                            </Input> : null
                                                    }

                                                    <ButtonGroup className="priceBtnCourse" onClick={this.openLoginModal} size="lg">
                                                        <Button color="info">
                                                            {
                                                                this.state.course_discount_price && this.state.course_discount_price != 0 ?
                                                                    <span>
                                                                        <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>{this.state.selectedCourse.symbol + formatPrice(this.state.old_price)}</span>
                                                                        <span>{this.state.selectedCourse.symbol + formatPrice(this.state.course_discount_price)}</span>
                                                                    </span>
                                                                    :
                                                                    <span>{this.state.selectedCourse.symbol + formatPrice(this.state.course_price)}</span>
                                                            }<br />
                                                            {
                                                                this.state.selectedCourse.permonth ?
                                                                    <span style={{ fontSize: '14px' }}>per month</span> : null
                                                            }
                                                        </Button>
                                                        <Button color="warning">
                                                            {
                                                                this.state.selectedCourse.is_online == 2 ?
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faShoppingCart} size="1x" color="#000" />
                                                                        Buy
                                                                    </span> : 'Enroll'
                                                            }
                                                        </Button>
                                                    </ButtonGroup>
                                                    {
                                                        this.state.selectedCourse.program_demo_id != 0 ?
                                                            <Button onClick={this.redirectToDemoDashboard} style={{ maxWidth: '224px', margin: '10px 0', display: 'inline-block' }} block size="md" color="primary">
                                                                View Demo
                                                                </Button>
                                                            : null
                                                    }
                                                </Col>
                                            </Row>
                                            <Row style={{ paddingTop: '5vh 0' }}>
                                                <Col md={{ size: 8 }}>
                                                    {
                                                        this.state.selectedCourse.course_details && this.state.selectedCourse.course_details != '' ?
                                                            <div>
                                                                <h3 style={{ color: 'rgb(248, 171, 36)' }}>About this course</h3>
                                                                <p className="courseDynamicEditorContent" dangerouslySetInnerHTML={{ __html: this.state.selectedCourse.course_details.replace(/\/userfiles/g, 'https://dashboard.lincolnmethod.com/userfiles') }}></p>
                                                            </div>
                                                            : null
                                                    }
                                                </Col>
                                                <Col md={{ size: 4 }}>
                                                    {
                                                        this.state.courseTimings && this.state.courseTimings != undefined && this.state.courseTimings != '' && this.state.courseTeachers != '{}' ?
                                                            <Table className="classTimingsCourse">
                                                                <tbody>
                                                                    <tr>
                                                                        <th colSpan="2">
                                                                            Course Timetable <sup>({this.state.zone})</sup>
                                                                        </th>
                                                                    </tr>
                                                                    {
                                                                        this.returnListTimings()
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                            : null
                                                    }
                                                    {
                                                        this.state.courseTeachers && this.state.courseTeachers != undefined && this.state.courseTeachers != '' && this.state.courseTeachers != '{}' ?
                                                            <ul className="classTeachersCourse">
                                                                <li>
                                                                    <h5 style={{ fontWeight: 'bold', fontSize: '16px' }}>Teachers</h5>
                                                                </li>
                                                                {
                                                                    this.showTeacherDetails()
                                                                }
                                                            </ul>
                                                            : null
                                                    }
                                                </Col>
                                            </Row>

                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                            {
                                this.state.selectedCourse.is_online != 2 ?
                                    <div style={{ background: '#eaeaea' }}>
                                        <Container className="glanceBoxes">
                                            <Row style={{ padding: '8vh 0', textAlign: 'center' }}>
                                                <Col md={{ size: 12 }}>
                                                    <h3 style={{ padding: '0 0 5vh 0' }}>This Course at a glance</h3>
                                                </Col>
                                                {
                                                    this.state.selectedCourse.is_online ?
                                                        <Col>
                                                            <Card style={{ background: '#373f5b', color: '#fff' }}>
                                                                <CardBody>
                                                                    <FontAwesomeIcon icon={faWifi} size="3x" color="#fff" />
                                                                    <CardTitle style={{ paddingTop: '2vh' }}><h2>Online</h2></CardTitle>
                                                                    <CardSubtitle>Course</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> : null
                                                }{
                                                    this.state.selectedCourse.chapters ?
                                                        <Col>
                                                            <Card style={{ background: '#0dc5c7', color: '#fff' }}>
                                                                <CardBody>
                                                                    <FontAwesomeIcon icon={faBook} size="3x" color="#fff" />
                                                                    <CardTitle style={{ paddingTop: '2vh' }}><h2>{this.state.selectedCourse.chapters}</h2></CardTitle>
                                                                    <CardSubtitle>Chapters</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> : null
                                                }{
                                                    this.state.selectedCourse.videos > 0 ?
                                                        <Col>
                                                            <Card style={{ background: '#ffa800', color: '#fff' }}>
                                                                <CardBody>
                                                                    <FontAwesomeIcon icon={faVideo} size="3x" color="#fff" />
                                                                    <CardTitle style={{ paddingTop: '2vh' }}><h2>{this.state.selectedCourse.videos}</h2></CardTitle>
                                                                    <CardSubtitle>Videos</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> : null
                                                }{
                                                    this.state.selectedCourse.course_date == '' && this.state.selectedCourse.course_date != 0 && !this.state.selectedCourse.is_online ?
                                                        <Col>
                                                            <Card style={{ background: '#6050bf', color: '#fff' }}>
                                                                <CardBody>
                                                                    <FontAwesomeIcon icon={faCalendarAlt} size="3x" color="#fff" />
                                                                    <CardTitle style={{ paddingTop: '2vh' }}><h2>{this.state.selectedCourse.course_date}</h2></CardTitle>
                                                                    <CardSubtitle>Starts on</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> : null
                                                }{
                                                    this.state.selectedCourse.course_days && this.state.selectedCourse.course_days != '' && !this.state.selectedCourse.is_online ?
                                                        <Col>
                                                            <Card style={{ background: '#FF9800', color: '#fff' }}>
                                                                <CardBody>
                                                                    <FontAwesomeIcon icon={faClock} size="3x" color="#fff" />
                                                                    <CardTitle style={{ paddingTop: '2vh' }}><h2>{this.state.selectedCourse.course_days}</h2></CardTitle>
                                                                    <CardSubtitle>Days</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> : null
                                                }{
                                                    this.state.selectedCourse.course_time != '' && !this.state.selectedCourse.is_online ?
                                                        <Col>
                                                            <Card style={{ background: '#ff0048', color: '#fff' }}>
                                                                <CardBody>
                                                                    <FontAwesomeIcon icon={faClock} size="3x" color="#fff" />
                                                                    <CardTitle style={{ paddingTop: '2vh' }}><h2>{this.state.selectedCourse.course_time}</h2></CardTitle>
                                                                    <CardSubtitle>Duration</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> : null
                                                }
                                                {
                                                    this.state.selectedCourse.course_location_title != '' && !this.state.selectedCourse.is_online ?
                                                        <Col>
                                                            <Card style={{ background: '#b750bf', color: '#fff' }}>
                                                                <CardBody>
                                                                    <a href={this.state.selectedCourse.course_location != '' ? this.state.selectedCourse.course_location : null} target="_blank">
                                                                        <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" color="#fff" />
                                                                    </a>
                                                                    <CardTitle style={{ paddingTop: '2vh' }}><h2>{this.state.selectedCourse.course_location_title}</h2></CardTitle>
                                                                    <CardSubtitle>Address</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> : null
                                                }{
                                                    this.state.course_price != '' ?
                                                        <Col>
                                                            <Card style={{ background: '#50a0bf', color: '#fff' }}>
                                                                <CardBody>
                                                                    <FontAwesomeIcon icon={faWallet} size="3x" color="#fff" />
                                                                    <CardTitle style={{ paddingTop: '2vh' }}>
                                                                        <h2>
                                                                            {
                                                                                this.state.course_discount_price && this.state.course_discount_price != 0 ?
                                                                                    <span>
                                                                                        <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>{this.state.selectedCourse.symbol + formatPrice(this.state.old_price)}</span>
                                                                                        <span>{this.state.selectedCourse.symbol + formatPrice(this.state.course_discount_price)}</span>
                                                                                    </span>
                                                                                    :
                                                                                    <span>{this.state.selectedCourse.symbol + formatPrice(this.state.course_price)}</span>
                                                                            }
                                                                        </h2>
                                                                    </CardTitle>

                                                                    <CardSubtitle>
                                                                        {
                                                                            this.state.selectedCourse.permonth ?
                                                                                <span style={{ fontSize: '14px' }}>per month<br /></span> : null
                                                                        }
                                                                    Price
                                                                </CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> : null
                                                }

                                            </Row>
                                        </Container>
                                    </div>
                                    : null
                            }
                            {
                                this.state.selectedCourse.assessments.length && this.state.selectedCourse.is_online != 2 ?
                                    <div className="coursesDemo" style={{ padding: '5vh 0' }}>
                                        <Container>
                                            <Row style={{ textAlign: 'left' }}>
                                                <Col md={{ size: 12 }}>
                                                    <h3>Course includes these {this.state.selectedCourse.chapters} chapters <span style={{ color: '#999', fontSize: '12px' }}>Click any to expand</span></h3>
                                                </Col>
                                                {
                                                    this.state.selectedCourse.assessments.map((assessment, key) =>
                                                        <Col key={key} md={{ size: 12 }}>
                                                            <Button color="secondary" block id={"toggler_" + key}>
                                                                {assessment.name}
                                                                <FontAwesomeIcon style={{ float: 'right' }} icon={faPlus} size="1x" color="#fff" />
                                                            </Button>
                                                            <UncontrolledCollapse toggler={"#toggler_" + key}>
                                                                <Card>
                                                                    <CardBody>
                                                                        <ul style={{ listStyleType: 'none' }}>
                                                                            {
                                                                                assessment.tests.length && assessment.tests.map((test, key) =>
                                                                                    <li key={key}>
                                                                                        {test.testname}
                                                                                        {
                                                                                            test.question_id != 0 ?
                                                                                                <Button onClick={(e) => this.demoPopupLaunch(e, this.state.selectedCourse.program_id, assessment.assessment_id, test.testid, test.question_id)} style={{ float: 'right' }} color="dark" size="sm">
                                                                                                    See Demo
                                                                        </Button> : null
                                                                                        }
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        </ul>
                                                                    </CardBody>
                                                                </Card>
                                                            </UncontrolledCollapse>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        </Container>
                                    </div> : null
                            }

                            <div style={{ textAlign: 'left', padding: '12vh 0', background: "url(https://dashboard.lincolnmethod.com/img/stores/hot.gif) left top / cover" }}>
                                <Container>
                                    <Row>
                                        <Col style={{ color: '#fff' }} md={{ size: 6 }}>
                                            <h5>Course:</h5>
                                            <h1>{this.state.selectedCourse.course_name}</h1>
                                        </Col>
                                        <Col md={{ size: 6 }} style={{ textAlign: 'right', padding: '5vh 0' }}>
                                            <ButtonGroup className="priceBtnCourse" onClick={this.openLoginModal} size="lg">
                                                <Button color="info">
                                                    {
                                                        this.state.course_discount_price && this.state.course_discount_price != 0 ?
                                                            <span>
                                                                <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>{this.state.selectedCourse.symbol + formatPrice(this.state.old_price)}</span>
                                                                <span>{this.state.selectedCourse.symbol + formatPrice(this.state.course_discount_price)}</span>
                                                            </span>
                                                            :
                                                            <span>{this.state.selectedCourse.symbol + formatPrice(this.state.course_price)}</span>
                                                    }<br />
                                                    {
                                                        this.state.selectedCourse.permonth ?
                                                            <span style={{ fontSize: '14px' }}>per month</span> : null
                                                    }
                                                </Button>
                                                <Button color="warning">
                                                    {
                                                        this.state.selectedCourse.is_online == 2 ?
                                                            <span>
                                                                <FontAwesomeIcon icon={faShoppingCart} size="1x" color="#000" />
                                                                    Buy
                                                                </span>
                                                            : 'Enroll'
                                                    }
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>

                                </Container>
                            </div>

                        </div>

                        :
                        <Spinner style={{ width: '3rem', height: '3rem', margin: '30vh 0' }} type="grow" color="dark" />
                }



                <Footer />

            </div>
            // </DocumentMeta>
        );
    }
}

CourseDetailComponent.propTypes = {
    getCourseDetails: PropTypes.func.isRequired,
    getTestPreview: PropTypes.func.isRequired,
    triggerLoginPopup: PropTypes.func.isRequired,
    triggerEnrollPopup: PropTypes.func.isRequired,
    triggerCheckoutPopup: PropTypes.func.isRequired,
    getSignUpFormType: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    courseDetail: state.webRed.courseDetail,
    questionHtml: state.webRed.questionHtml,
    loginSuccessWithCourse: state.webRed.loginSuccessWithCourse
})

export default connect(mapStateToProps, { getCourseDetails, getTestPreview, triggerLoginPopup, triggerEnrollPopup, triggerCheckoutPopup, getSignUpFormType })(CourseDetailComponent);
