import React, { Component } from "react";

import store from './../../store';
import PropTypes from 'prop-types';
import { forgotPasswordAction } from './../../actions/webRedActions';
import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label
} from "reactstrap";

class ForgotPasswordComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup : false,
            forgotEmail : '',
            forgotError : ''
        };
    }

    componentDidMount() {
        
        
        
    }

    componentWillReceiveProps(nextProps, thisProps) {
        
        if (nextProps.showPopup !== false && this.props.showPopup != nextProps.showPopup) {
            this.setState({
                showPopup: true
            }, () => {
                this.setState({ 
                    forgotEmail : nextProps.showPopup.emailPersist != '' && nextProps.showPopup.emailPersist != null && validateEmailAddress(nextProps.showPopup.emailPersist) ? nextProps.showPopup.emailPersist : '',
                    forgotError : ''
                })
            });
        }

        if(this.props.forgotPasswordResponse != nextProps.forgotPasswordResponse){
            this.setState({
                forgotError : nextProps.forgotPasswordResponse.data.message
            })
        }

    }

    toggleForgotPassModal = () => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
    }

    submitForgotPassForm = () => {
        if(validateEmailAddress(this.state.forgotEmail)){
            this.props.forgotPasswordAction(this.state.forgotEmail);
        }
        else{
            this.setState({
                forgotError : 'This email is not valid'
            })
        }
    }


    render() {
        return (
            <div className="ForgotPasswordComponent">

                <Modal isOpen={this.state.showPopup} centered={true} size="md">
                    <ModalBody style={{padding: '40px', background: '#1b2843', color: '#fff'}}>
                        <div>
                            <Container>
                                <p className="closeModalLogin" onClick={this.toggleForgotPassModal}>X</p>
                                <Row>
                                    <Col md={{size : 12}}>
                                        <FormGroup style={{position: 'relative'}}>
                                            {/* <Label>What email did you use?</Label> */}
                                            <Input style={{height: '50px'}} type="email" name="forgot_email" id="forgot_email" placeholder="What email did you use?" value={this.state.forgotEmail} onChange={(evt) => {
                                                this.setState({ forgotEmail: evt.target.value })
                                            }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size : 12}}>
                                        <FormGroup>
                                            <Button onClick={this.submitForgotPassForm} block size="md" color="warning">Reset my password</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>   
                                        {
                                            this.state.forgotError && this.state.forgotError.length ?
                                            <p>{ this.state.forgotError }</p> : null
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }

}

ForgotPasswordComponent.propTypes = {
    forgotPasswordAction: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // loginSuccess: state.webRed.loginSuccess
    // extraSignUpFieldsNormal : state.webRed.extraSignUpFieldsNormal,
    // extraSignUpFieldsCourse : state.webRed.extraSignUpFieldsCourse
    // SignUpFormType : state.webRed.SignUpFormType
    forgotPasswordResponse : state.webRed.forgotPasswordResponse
})

export default connect(mapStatesToProps, { forgotPasswordAction })(ForgotPasswordComponent);