import React, { Component } from "react";
import "../../public/scss/snippets/SocialLoginComponent.scss";

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import store from './../../store';
import PropTypes from 'prop-types';
import { _handleSocialLogin } from './../../actions/webRedActions';
import { getCurrentClient, getAppUrl, isMobileBrowser } from './../../actions/utility';
import { connect } from 'react-redux';

class SocialLoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDebug: true,
            socialError : ''
        };
    }
    /**
     * debug log for component 
    */
    debugLog() {

        if (this.state.isDebug)
            console.log(Array.prototype.slice.call(arguments));
    }

    componentDidMount() {
        // this.debugLog('here i am social login component');
    }
    
    /**
     * function handle the facebook login response
     * @param response {object}
     * @return void  
    */
    responseFacebook = (response) => {
        this.debugLog('Faheem in response FB login', response);
        if (typeof response === 'object' && response.hasOwnProperty('userID')) {
            var fbData = { 'name': response.name, 'email': response.email, 'picture': response.picture.data.url };
            this.socialLoginMethod('facebook', fbData);
        }
    }
    /**
     * function handle the google login response
     * @param response {object}
     * @return void  
    */

    responseGoogle = (response) => {
        if (typeof response === 'object' && response.hasOwnProperty('profileObj')) {
            var _profileData = response['profileObj'];
            this.socialLoginMethod('google', _profileData);
        }
        this.debugLog(_profileData, ':Faheem in response Google login:', response);
    }
    /**
     * react life cycle method
     * @param nextProps {object}
     * @param thisProps {object}
     * @return void  
    */

    socialLoginMethod = (socialType, loginData) => {
        var clientData = getCurrentClient();
        var courseId = 0,
        isPurchase = 0;
        if(localStorage.getItem('loginType') && localStorage.getItem('loginType') != null){
            var loginType = localStorage.getItem('loginType');
            if(loginType == 'course'){
                courseId = store.getState().webRed.courseDetail.course.id;
                isPurchase = 1;
            }
        }

        this.props._handleSocialLogin(socialType, loginData, clientData.id, courseId, isPurchase);
    }

    componentWillReceiveProps(nextProps, thisProps) {
        //this.debugLog(nextProps, thisProps);
        
        if (typeof nextProps.successData === 'object' && nextProps.successData.status == 'success' && (typeof nextProps.successData.data !=="undefined" && nextProps.successData.data.hasOwnProperty('login_type'))) {
            //showing here the error for already account on the email
            // alert(nextProps.successData);
            console.log(nextProps.successData);
            // var errorMessage = '';
            this.setState({
                socialError: ""
            })
        }
        else if (typeof nextProps.successData === 'object' && nextProps.successData.status == 'success') {

            try {
                localStorage.setItem('loginData', JSON.stringify(nextProps.successData.result));
                this.setState({
                    socialError : ''
                })
            }
            catch (err) {
                console.log('loginData could not store:', err);
            }
        }
        else {
            console.log('loginData unknow error:', nextProps.loginSuccess);
        }
    }

    render() {
        return (
            <div className="SocialLoginComponent">
                {
                    this.state.socialError != '' ?
                    <p style={{color: 'red', position: 'absolute', top: '0', background: '#1b2843'}}>{this.state.socialError}</p> : null
                }
                <FacebookLogin
                    appId="797880670614708"
                    autoLoad={false}
                    textButton=""
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                    cssClass="facebookLoginButton"
                    isMobile={isMobileBrowser() ? true : false}
                    disableMobileRedirect={true}
                />
                <GoogleLogin
                    clientId="366885272858-7b76vdbksl5fpjnuurref12muhrvheoe.apps.googleusercontent.com"
                    buttonText=""
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    redirectUri={getAppUrl()}
                    className="googleLoginButton"
                />
            </div>
        );
    }
}
/**
 * managing the class proptypes bellow in order to use redux things in this class
 */
SocialLoginComponent.propTypes = {
    _handleSocialLogin: PropTypes.func.isRequired
}
/**
 * map redux things in current class state
 */
const mapStatesToProps = state => ({
    successData: state.webRed.successData
})
export default connect(mapStatesToProps, { _handleSocialLogin })(SocialLoginComponent);