export const getCoursesSuccess = 'getCoursesSuccess';
export const getCoursesSuccessOnlineSchool = 'getCoursesSuccessOnlineSchool';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const getCourseDetailSuccess = 'getCourseDetailSuccess';
export const getQuestionHtmlSuccess = 'getQuestionHtmlSuccess';
export const _handleSocialLoginSuccess = '_handleSocialLoginSuccess';
export const _loginSuccess = '_loginSuccess';
export const _loginSuccess2 = '_loginSuccess2';
export const _signupSuccess = '_signupSuccess';
export const GET_SIGNUP_FORM_TYPE = 'GET_SIGNUP_FORM_TYPE';
export const SHOW_LOGIN_POPUP = 'SHOW_LOGIN_POPUP';
export const SHOW_ENROLLMENT_POPUP = 'SHOW_ENROLLMENT_POPUP';
export const SHOW_FORGOT_PASS = 'SHOW_FORGOT_PASS';
export const SHOW_CHECKOUT_POPUP = 'SHOW_CHECKOUT_POPUP';
export const SHOW_CONGRATULATIONS = 'SHOW_CONGRATULATIONS';
export const GET_EXTRA_STORE_FIELDS = 'GET_EXTRA_STORE_FIELDS';
export const _loginSessionEncrypted = '_loginSessionEncrypted';
export const _checkoutCoupon = '_checkoutCoupon';
export const _checkoutCashOnDevliverySuccess = '_checkoutCashOnDevliverySuccess';
export const _checkoutJazzCashSuccess = '_checkoutJazzCashSuccess';
export const _forgotPasswordMessage = '_forgotPasswordMessage';
export const _validateEnroll = '_validateEnroll';
export const _validateEmailExists = '_validateEmailExists';
export const _addiotionalJazzCheckoutDetails = '_addiotionalJazzCheckoutDetails';
export const _checkPhoneNumber = '_checkPhoneNumber';
export const _validatePhoneVerification = '_validatePhoneVerification';
export const _checkIfUsersExists = '_checkIfUsersExists';
export const _triggerNewUserType = '_triggerNewUserType';
export const _checkIfUserNameExists = '_checkIfUserNameExists';
export const _triggerNewUserPassword = '_triggerNewUserPassword';
export const _triggerNewUserDetails = '_triggerNewUserDetails';
export const _triggerNewUserEmail = '_triggerNewUserEmail';
export const _signupSuccessPhone = '_signupSuccessPhone';
export const _checkIfThisColumnExists = '_checkIfThisColumnExists';
export const _saveCard = '_saveCard';

export const _saveCardDirect = '_saveCardDirect';
export const _creatpaymentmethod = '_creatpaymentmethod';
export const _checkoutDoneFinal = '_checkoutDoneFinal';