import React, { Component } from "react";

// import store from './../../store';
import PropTypes from 'prop-types';
import { triggerCongratulationsAnimation, doLoginSession } from './../../actions/webRedActions';
import { connect } from 'react-redux';

import Header from './../website/Header';
import Footer from "./../website/Footer";

import {
      Container,
      Row,
      Col,
      Button
    } from "reactstrap";


class CongratulationsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            detailEncoded : this.props.match.params.detail,
            isCourseButton : false,
            courseId : ''
        }

    }

    componentDidMount() {
        document.title = "Congratulations.";
        
        if(this.state.detailEncoded && this.state.detailEncoded != '' && this.state.detailEncoded != undefined){
            this.props.triggerCongratulationsAnimation();

            var decodedDetail = atob(this.state.detailEncoded);
            
            var url = new URLSearchParams(decodedDetail);
            var email = url.get("email"),
            password = url.get("password");

            this.props.doLoginSession(email, password); //login with encrypted login credentials

            if(url.has("courseid")){
                this.setState({
                    isCourseButton : true
                }, () => {
                    this.setState({
                        courseId : url.get("courseid")
                    })
                })
            }
            else{
                this.setState({
                    isCourseButton : false
                })
            }

        }
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(nextProps, thisProps){

        console.log(nextProps)

        if (typeof nextProps.loginSession === 'object' && nextProps.loginSession.status == 'success') {
            console.log('login has been succcessfull with session call');
            try {
                localStorage.setItem('loginData', JSON.stringify(nextProps.loginSession.result));
            }
            catch {
                console.log('loginData could not store:');
            }
        }

    }

    redirectToCourses = () => {
        if(this.state.courseId && this.state.courseId != '') { // checking if there's course id we got from url data
            localStorage.setItem('triggerCheckoutPopupCourse', 1);
            this.props.history.push('/course/' + this.state.courseId);
        }
        else{
            localStorage.setItem('triggerCheckoutPopupCourse', 0);
            this.props.history.push('/courses');
        }
    }

    
    render() {
        
        return (
            <div className="CongratulationsComponent" style={{color: '#000'}}>

                <Header />

                <div>
                    <Container>
                        <Row>
                            <Col md={{size : 12 }} style={{padding: '30vh 0', zIndex: '9999'}}>
                                <h1 style={{color: '#f8ab24', fontSize: '3rem'}}><strong>Congratulations</strong></h1><br/>
                                <h3>Your account has been successfully verified.</h3><br/>
                                <Button onClick={e => this.redirectToCourses()} color="warning" size="lg">
                                    { this.state.isCourseButton ? 'View the Course' : 'View Courses' }
                                </Button>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
                
                {/* <Footer /> */}
            </div>
        );
    }
}

CongratulationsComponent.propTypes = {
    triggerCongratulationsAnimation: PropTypes.func.isRequired,
    doLoginSession: PropTypes.func.isRequired
}

const mapStateToProps = state =>({
    // coursesData : state.webRed.coursesData
    loginSession : state.webRed.loginSession
})

export default connect(mapStateToProps, { triggerCongratulationsAnimation, doLoginSession }) (CongratulationsComponent);
  