import React, { Component } from "react";
import "../../../public/scss/Partnerships.scss";
import Header from './..//Header';
import Footer from "./../Footer";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";


import banner from './../../../public/images/partnerships/slum/slumblockbanner.jpeg';

//images for twitter gallery
import twitterimage1 from './../../../public/images/partnerships/slum/1.jpg';
import twitterimage2 from './../../../public/images/partnerships/slum/2.jpg';
import twitterimage3 from './../../../public/images/partnerships/slum/3.jpg';

//success stories
import SBL_01 from './../../../public/images/partnerships/slum/SBL-01.jpg';
import SBL_02 from './../../../public/images/partnerships/slum/SBL-02.jpg';


//images for SLUM gallery
import SLUMimage1 from './../../../public/images/partnerships/slum/SBL-1.jpeg';
import SLUMimage2 from './../../../public/images/partnerships/slum/SBL-2.jpeg';
import SLUMimage3 from './../../../public/images/partnerships/slum/SBL-3.jpeg';
import SLUMimage4 from './../../../public/images/partnerships/slum/SBL-4.jpeg';
import SLUMimage5 from './../../../public/images/partnerships/slum/SBL-5.jpeg';

// importing videos
import video1 from './../../../public/videos/partnerships/blockchain-in-karachi.mp4';
import video2 from './../../../public/videos/partnerships/Liyaari.mp4';
import video3 from './../../../public/videos/partnerships/leni-vedio.mp4';
import video4 from './../../../public/videos/partnerships/samuel-video.mp4';

class SlumBlockMillionaireComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            photoIndex1: 0,
            isOpen1: false,
            video1: video1,
            video2: video2,
            video3: video3,
            video4: video4
        }
    }

    // openLighBox(index) {
    //     this.setState({
    //         photoIndex: index,
    //         isOpen: true
    //     })
    // }
    openLighBox1(index) {
        this.setState({
            photoIndex1: index,
            isOpen1: true
        })
    }

    componentDidMount() {
        document.title = "Slum-Block Millionaire - Lincoln Method";
    }
    
    render() {

        const { photoIndex, isOpen } = this.state;
        const images = [
            twitterimage1,
            twitterimage2,
            twitterimage3
        ];

        const { photoIndex1, isOpen1 } = this.state;
        const images1 = [
            SLUMimage1,
            SLUMimage2,
            SLUMimage3,
            SLUMimage4,
            SLUMimage5
        ];
        
        return (
        // <DocumentMeta {...meta}>
            <div className="SlumBlockMillionaireComponent">

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                )}

                {isOpen1 && (
                    <Lightbox
                        mainSrc={images1[photoIndex1]}
                        nextSrc={images1[(photoIndex1 + 1) % images1.length]}
                        prevSrc={images1[(photoIndex1 + images1.length - 1) % images1.length]}
                        onCloseRequest={() => this.setState({ isOpen1: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex1: (photoIndex1 + images1.length - 1) % images1.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex1: (photoIndex1 + 1) % images1.length,
                        })
                        }
                    />
                )}

                <Header />

                    {/* <div className="homeEdtech" style={{background: '#121625 center fixed / cover'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <h2>banner</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}

                    <div className="partnershipContent" style={{padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row style={{paddingBottom: '10px'}}>
                                <Col md={{ size: 12 }}>
                                    <Media style={{maxWidth: '100%'}} object src={banner} alt="un-nations-sdg-banner" />
                                </Col>
                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <h3>Statement of Interest for the Slum-Block Millionaire project</h3>
                                    <p>We are starting our Slum Block Millionaire Project and we are inviting a few of our close friends and their organizations to express their support in the Initial phase.</p>
                                    <p>In the Initial phase, we want to reach out to all our close friends and have them sign this Statement of Interest for the Slum Block Millionaire Project.</p>
                                    <p>By signing the Statement of Interest, your only obligation would be to speak positively of blockchain and endorse the education of blockchain within your social networks.</p>
                                    <p>We would also post your logo, as a supporter, on the web page that we are creating for the Slum Block Millionaire Project.</p>
                                    <p>We hope you will join us on this exciting journey.</p>
                                </Col>
                            </Row>
                            
                        </Container>

                        <Container style={{padding: '5vh 0'}}>
                            <Row>
                                <Col md={{size: 12}}>
                                    <video width="100%" controls>
                                        <source type="video/mp4" src={this.state.video1} />
                                    </video>
                                </Col>
                                <Col md={{size: 12}}>
                                    <video width="100%" controls>
                                        <source type="video/mp4" src={this.state.video2} />
                                    </video>
                                </Col>
                                <Col md={{size: 12}}>
                                    <video width="100%" controls>
                                        <source type="video/mp4" src={this.state.video3} />
                                    </video>
                                </Col>
                                <Col md={{size: 12}}>
                                    <video width="100%" controls>
                                        <source type="video/mp4" src={this.state.video4} />
                                    </video>
                                </Col>
                                <Col md={{size: 6, offset: 3}}>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/3IB4GREDpKc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </Col>
                            </Row>

                            {/* <Row style={{borderTop: '1px #ccc solid', marginTop: '4vh', paddingTop: '4vh'}}>

                                {
                                    images.map((imageUrl, key) =>
                                        <Col key={key} md={{ size: 4 }}>
                                            <Media style={{maxWidth: '100%', cursor: 'pointer', margin: '15px 0'}} onClick={() => this.openLighBox(key)} object src={imageUrl} />
                                        </Col>
                                    )
                                }
                            </Row> */}

                            <Row style={{borderTop: '1px #ccc solid', marginTop: '4vh', paddingTop: '4vh'}}>
                                <Col md={{size: 12}}>
                                    <h2>Success Stories</h2>
                                    <Media style={{maxWidth: '100%'}} object src={SBL_02} />
                                    <Media style={{maxWidth: '100%'}} object src={SBL_01} />
                                </Col>
                            </Row>

                            <Row style={{borderTop: '1px #ccc solid', marginTop: '4vh', paddingTop: '4vh'}}>
                                <Col md={{size: 12}}>
                                    <h2>Supporters</h2>
                                </Col>
                                {
                                    images1.map((imageUrl, key) =>
                                        <Col key={key} md={{ size: 4 }}>
                                            <Media style={{maxWidth: '100%', cursor: 'pointer', margin: '15px 0'}} onClick={() => this.openLighBox1(key)} object src={imageUrl} />
                                        </Col>
                                    )
                                }

                            </Row>
                            
                        </Container>

                    </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default SlumBlockMillionaireComponent;