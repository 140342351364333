import React, { Component } from "react";
import "../../public/scss/snippets/CheckoutPaymentComponent.scss";

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import store from './../../store';
import PropTypes from 'prop-types';
import { applyCheckoutCoupon, paymentCashOnDelivery, paymentJazzCashMethod, fetchJazzCashAddiotionalData, validateCourseAvailability } from './../../actions/webRedActions';
import { connect } from 'react-redux';

import { formatPrice, validatePhone, getBaseUrl, isMobileBrowser, reserveBackOnMobile, getLastLinkStorage } from './../../actions/utility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    CustomInput,
    Label
} from "reactstrap";

import visacard from '././../../public/images/checkout/visa.png';
import mastercard from '././../../public/images/checkout/master.png';
import discoveredcard from '././../../public/images/checkout/discovered.png';
import americancard from '././../../public/images/checkout/american.png';
import jazzbanner from '././../../public/images/checkout/jazzbanner.png';

class CheckoutPaymentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup : false,
            step : 1,
            selectedCourse : false,
            selectedDays : [],
            selectedLocations : [],
            shippingCharges : 1000,
            choosedLocation : 0,
            order_address : '',
            order_addressError : '',
            couponCode : '',
            updatedPrice : '',
            contactNum : '',
            hideContactNum : true,
            contactNumError : '',
            isCouponApplied : false,
            isCouponEnabled : false,
            isCouponError : '',
            couponDiscountAmount : 0,
            couponDiscountedPrice : 0,

            word_problem : true,
            logic_reasoning : true,
            reading_comprehennsion : true,
            grammer_vocabulary : true,
            lincoln_method : true,
            macmillan_education : true,
            mcgraw_hill : true,
            scholastic : false,
            combination : false,
            sel_pickupmethod : 0,
            paymentMethod : 0,
            paymentMethodType : 0, // this state is specific to payment type 0 which is jazzcash
            precautions : '',
            checkoutSuccessMessage : '',
            checkoutInProgress : false,
            amount:'',
            discountedAmount:'',
            couponid:'',
            txnDateTime:'',
            txnExpiryDateTime:'',
            orderRefNum:'',
            securehashMobile:'',
            securehashCard:'',
            course_name:'',
            courseid:'',
            program_id:'',
            userid:'',
            whichForm:'',

            // these states are specifically for jazcash form submission
            form_amount: '',
            form_discountedAmount: '',
            form_couponid: '',
            form_txnDateTime: '',
            form_txnExpiryDateTime: '',
            form_orderRefNum: '',
            form_securehashMobile: '',
            form_securehashCard: '',
            form_billReference: '',
            form_userid: '',
            form_userid_numeric: '',
            form_course_name: '',
            form_courseid: '',
            form_program_id: '',
            form_whichForm: '',

            form_MerchantID : '', //Your Merchant from transaction Credentials
            form_Password : '',   //Your Password from transaction Credentials
            form_ReturnURL : '',  //Your Return URL
            form_HashKey : '', //Your HashKey integrity salt from transaction Credentials	
            form_PostURL : '',
            form_Language : '',
            form_TxnCurrency : '',
            form_Version : ''
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, thisProps) {
        
        if (this.props.showPopup != nextProps.showPopup) {
            this.setState({
                showPopup: true
            }, () => {
                var selected = store.getState().webRed.courseDetail;
                if(selected){
                    this.setState({
                        selectedCourse : selected.course,
                        selectedDays : selected.daysPrices,
                        selectedLocations : selected.locations
                    }, () => {
                        if(this.state.selectedDays.length){ // if there're selected Days Array
                            this.setState({
                                updatedPrice : this.state.selectedDays[localStorage.getItem('selectedDayId')].price
                            })
                        }
                        else{
                            this.setState({
                                updatedPrice : this.state.selectedCourse.course_price
                            })
                        }

                        this.setState({
                            isCouponApplied : false,
                            isCouponError : '',
                            couponDiscountAmount : 0,
                            couponDiscountedPrice : 0,
                            couponCode : '',
                            shippingCharges : 1000,
                            step : 1,
                            contactNum : '',
                            contactNumError : '',
                            paymentMethod : 0,
                            paymentMethodType : 0,
                            precautions : '',
                            checkoutSuccessMessage : '',
                            checkoutInProgress : false
                        }, () => {

                            // auto apply coupons for sales and compaigns
                            if(getLastLinkStorage() == '/online-school-demo') {
                                this.setState({
                                    isCouponEnabled : true,
                                    couponCode : "FREECLASS"
                                }, () => {
                                    this.applyCouponCode();
                                })
                            } else if(getLastLinkStorage() == '/holy-friday-sale') {
                                this.setState({
                                    isCouponEnabled : true,
                                    couponCode : "FridaySale"
                                }, () => {
                                    this.applyCouponCode();
                                })
                            }

                        })

                        try{
                            this.setState({
                                shippingCharges : this.state.selectedLocations[this.state.choosedLocation].fee,
                            })
                        }
                        catch(e){
                            console.log(e)
                        }

                        var loginStatus = localStorage.getItem('loginData');
                        if (loginStatus != null && loginStatus != '' && typeof JSON.parse(loginStatus) === 'object') {
                            this.props.validateCourseAvailability(JSON.parse(loginStatus).user.id, this.state.selectedCourse.id, JSON.parse(loginStatus).user.user_type); // calls action for check validity for enroll/buy button

                            var primryNumber = JSON.parse(loginStatus).phone_number;

                            if(primryNumber && primryNumber != "" && primryNumber != undefined && primryNumber != null) {
                                this.setState({
                                    contactNum : primryNumber
                                }, () => {
                                    this.setState({
                                        hideContactNum : true
                                    })
                                })
                            }

                        }
                        
                    })
                }

                // if it's mobile phone it'll reserve to go back while popup opened
                if(isMobileBrowser()) {
                    reserveBackOnMobile(true);
                }

            })
        }

        if(this.props.checkoutCouponResponse != nextProps.checkoutCouponResponse){
            console.log(nextProps.checkoutCouponResponse)
            if(nextProps.checkoutCouponResponse.coupon_discount != 0){
                debugger;
                this.setState({
                    isCouponApplied : true,
                    isCouponError : '',
                    couponDiscountAmount : nextProps.checkoutCouponResponse.discount,
                    couponDiscountedPrice : nextProps.checkoutCouponResponse.discountPrice,
                    couponid: nextProps.checkoutCouponResponse.couponid
                })
            }
            else{
                this.setState({
                    isCouponApplied : false,
                    isCouponError : 'this coupon is not valid or applicable',
                    couponDiscountAmount : 0,
                    couponDiscountedPrice : 0
                })
            }
        }
        
        if(this.props.checkoutCashOnDevliveryResponse != nextProps.checkoutCashOnDevliveryResponse) {
            console.log(nextProps.checkoutCashOnDevliveryResponse.response);
            if(nextProps.checkoutCashOnDevliveryResponse.response.indexOf('success') > -1) {
                var message = "Thank you for signing up for the course, please visit Lincoln Method center to make the payment.<br/>In case of any query, call us at <a style={{color: 'inherit'}} href='tel:+923008296228'>+639 29 276 0004</a>";
                if(this.state.selectedCourse.is_online == 0){
                    message = "Thank you for signing up for the course, please visit Lincoln Method center to make the payment.<br/>In case of any query, call us at <a style={{color: 'inherit'}} href='tel:+923008296228'>+639 29 276 0004</a>";
                }
                else if(this.state.selectedCourse.is_online == 2){
                    message = "We will now compile, print and produce this workbook. Before dispatching your order, we will contact you on the number you have provided us: " + this.state.contactNum + "<br/>In case of any query, call us at <a style={{color: 'inherit'}} href='tel:+923008296228'>+639 29 276 0004</a>";
                }

                setTimeout(() => {
                    this.setState({
                        checkoutSuccessMessage : message
                    })
                }, 200);
                
            }
            else if(nextProps.checkoutCashOnDevliveryResponse.response.indexOf('already') > -1) {
                var message = "This course is already added to your dashboard";
                if(this.state.selectedCourse.is_online == 0){
                    message = "You have already signed up for the course, please visit Lincoln Method center to make the payment.<br/>In case of any query, call us at <a style={{color: 'inherit'}} href='tel:+923008296228'>+639 29 276 0004</a>";
                }
                else if(this.state.selectedCourse.is_online == 2){
                    message = "Your order for this article is already booked, please contact our support team for more information.";
                }

                setTimeout(() => {
                    this.setState({
                        checkoutSuccessMessage : message
                    })
                }, 200);
                
            }
            else if(nextProps.checkoutCashOnDevliveryResponse.response.indexOf('error') > -1) {
                setTimeout(() => {
                    this.setState({
                        checkoutSuccessMessage : "Order could not place. Try again. error code: " + nextProps.checkoutCashOnDevliveryResponse.orderStatus
                    })
                }, 200);
            }

            this.setState({
                checkoutInProgress : false
            })

        }

        if(this.props.checkoutJazzCashResponse != nextProps.checkoutJazzCashResponse) {
            
            console.log('checkoutJazzCashResponse:', nextProps.checkoutJazzCashResponse);

            if(nextProps.checkoutJazzCashResponse.result.status != null && nextProps.checkoutJazzCashResponse.result.status == 1) {
                //here we'll redirect user to it's dashboard
                var message = "Thank you for signing up for the course.<br/>In case of any query, call us at <a style={{color: 'inherit'}} href='tel:+923008296228'>+639 29 276 0004</a>";
                
                setTimeout(() => {
                    this.setState({
                        checkoutSuccessMessage : message
                    })
                }, 200);
            }
            else{

                try {
                
                    const {paymentMethodType, selectedCourse} = this.state;
                    
                    this.setState({
                        form_amount: nextProps.checkoutJazzCashResponse.result.Amount,
                        form_discountedAmount: nextProps.checkoutJazzCashResponse.result.DiscountedAmount,
                        form_couponid: nextProps.checkoutJazzCashResponse.result.couponid,
                        form_txnDateTime: nextProps.checkoutJazzCashResponse.result.txndatetime,
                        form_txnExpiryDateTime: nextProps.checkoutJazzCashResponse.result.txnexpirydatetime,
                        form_orderRefNum: nextProps.checkoutJazzCashResponse.result.txnrefnumber,
                        form_securehashMobile: nextProps.checkoutJazzCashResponse.result.securehashMobile,
                        form_securehashCard: nextProps.checkoutJazzCashResponse.result.securehashCard,
                        form_billReference: nextProps.checkoutJazzCashResponse.result.BillReference,
                        form_userid: JSON.parse(localStorage.getItem('loginData')).user.id,
                        form_course_name: selectedCourse.course_name,
                        form_courseid: selectedCourse.orignalid,
                        form_program_id: selectedCourse.program_id,
                        form_whichForm: paymentMethodType == 1 ? 'mobile' : 'card'
                    }, () => {

                        console.log('form_amount', this.state.form_amount);
                        console.log('form_discountedAmount', this.state.form_discountedAmount);
                        console.log('form_couponid', this.state.form_couponid);
                        console.log('form_txnDateTime', this.state.form_txnDateTime);
                        console.log('form_txnExpiryDateTime', this.state.form_txnExpiryDateTime);
                        console.log('form_orderRefNum', this.state.form_orderRefNum);
                        console.log('form_securehashMobile', this.state.form_securehashMobile);
                        console.log('form_securehashCard', this.state.form_securehashCard);
                        console.log('form_billReference', this.state.form_billReference);
                        console.log('form_userid', this.state.form_userid);
                        console.log('form_course_name', this.state.form_course_name);
                        console.log('form_courseid', this.state.form_courseid);
                        console.log('form_program_id', this.state.form_program_id);
                        console.log('form_whichForm', this.state.form_whichForm);                        
                        
                        // calls an action for Jazz Cash Method extra details
                        this.props.fetchJazzCashAddiotionalData(this.state.form_userid);

                    })
                    
                    // var praperFormData = nextProps.checkoutJazzCashResponse.result;
    
                    // praperFormData['amount']            = nextProps.checkoutJazzCashResponse.result.Amount;
                    // praperFormData['discountedAmount']  = nextProps.checkoutJazzCashResponse.result.DiscountedAmount;
                    // praperFormData['couponid']          = nextProps.checkoutJazzCashResponse.result.couponid;
                    // praperFormData['txnDateTime']       = nextProps.checkoutJazzCashResponse.result.txndatetime;
                    // praperFormData['txnExpiryDateTime'] = nextProps.checkoutJazzCashResponse.result.txnexpirydatetime
                    // praperFormData['orderRefNum']       = nextProps.checkoutJazzCashResponse.result.txnrefnumber
                    // praperFormData['securehashMobile']  = nextProps.checkoutJazzCashResponse.result.securehashMobile
                    // praperFormData['securehashCard']    = nextProps.checkoutJazzCashResponse.result.securehashCard
                    // praperFormData['billReference']     = nextProps.checkoutJazzCashResponse.result.BillReference
                    
                    // var userId = JSON.parse(localStorage.getItem('loginData')).user.id;
                    // praperFormData['userid']      = userId;
                    // praperFormData['course_name'] = selectedCourse.course_name;
                    // praperFormData['courseid']    = selectedCourse.orignalid;
                    // praperFormData['program_id']  = selectedCourse.program_id;
                    
                    // if(paymentMethodType == 1 ) {
                    //     praperFormData['whichForm'] = 'mobile';
                    // }
                    // else {
                    //     praperFormData['whichForm'] = 'card';
                    // }
                    
                    
                    // var encodedData = btoa(JSON.stringify(praperFormData))
                    
                    // this.setState({
                    //     praperFormData : encodedData
                    // },()=> {
                    //     document.getElementById('_jazCashForm').submit();
                    // })

                }
                catch(err) {
                    console.log('found error:',err);
                }
                
    
            }
            
        }

        if(this.props.addiotionalJazzCheckoutDetailsRes != nextProps.addiotionalJazzCheckoutDetailsRes) {
            // console.log('addiotionalJazzCheckoutDetailsRes: ', nextProps.addiotionalJazzCheckoutDetailsRes);

            if(nextProps.addiotionalJazzCheckoutDetailsRes.data.data && nextProps.addiotionalJazzCheckoutDetailsRes.data.data.status == "success") {
                var addOndata = nextProps.addiotionalJazzCheckoutDetailsRes.data.data.result;
                addOndata = JSON.parse(atob(addOndata));

                this.setState({
                    form_MerchantID : addOndata.MerchantID, //Your Merchant from transaction Credentials
                    form_Password : addOndata.Password,   //Your Password from transaction Credentials
                    form_ReturnURL : addOndata.ReturnURL,  //Your Return URL
                    form_HashKey : addOndata.HashKey, //Your HashKey integrity salt from transaction Credentials	
                    form_PostURL : addOndata.PostURL,
                    form_Language : addOndata.Language,
                    form_TxnCurrency : addOndata.TxnCurrency,
                    form_Version : addOndata.Version,
                    form_userid_numeric : addOndata.user_id
                }, () => {
                    
                    console.log('form_MerchantID', this.state.form_MerchantID);
                    console.log('form_Password', this.state.form_Password);
                    console.log('form_ReturnURL', this.state.form_ReturnURL);
                    console.log('form_HashKey', this.state.form_HashKey);
                    console.log('form_PostURL', this.state.form_PostURL);
                    console.log('form_Language', this.state.form_Language);
                    console.log('form_TxnCurrency', this.state.form_TxnCurrency);
                    console.log('form_Version', this.state.form_Version);
                    console.log('form_userid_numeric', this.state.form_userid_numeric);

                    var dynFormId = this.state.form_whichForm + 'Form';
                    console.log(dynFormId);
                    // form submission
                    document.getElementById(dynFormId).submit();
                    
                })
            }
        }

        if(this.props.validateEnrollButton != nextProps.validateEnrollButton){
            if(nextProps.validateEnrollButton.data && nextProps.validateEnrollButton.data.status && nextProps.validateEnrollButton.data.status == 'success'){
                console.log(nextProps.validateEnrollButton.data.result)
                var validOption = nextProps.validateEnrollButton.data.result;
                if(validOption.ifEnrolled != 0 && validOption.ifEnrolled != '0'){
                    if(this.state.selectedCourse.is_online != 2){ // check if it's not workbook as multiple workbooks can be ordered
                        this.setState({
                            checkoutSuccessMessage : "you are already enrolled in this course"
                        })
                    }
                }
                else if(validOption.ifInClassCourseRequested != 0 && validOption.ifInClassCourseRequested != '0'){
                    if(this.state.selectedCourse.is_online != 2){ // check if it's not workbook as multiple workbooks can be ordered
                        this.setState({
                            checkoutSuccessMessage : "you already requested enrollment for this course"
                        })
                    }
                }
            }
        }


    }

    toggleLoginModal = () => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
        
        if(isMobileBrowser()) {
            reserveBackOnMobile(false);
        }

    }

    redirectToCheckout = (type, course) => {
        // if(type == 'credit') { // redirect to credit/debit card chekcout
        // }
        localStorage.setItem('paymentType', type);
        localStorage.setItem('checkoutCourse', JSON.stringify(course));
        if(this.state.isCouponApplied) {
            localStorage.setItem('discountedAmount', Math.ceil(this.state.couponDiscountedPrice));
            localStorage.setItem('couponApplied', true);
            localStorage.setItem('couponId', this.state.couponid);
            localStorage.setItem('couponCode', this.state.couponCode);
        }
        else {
            localStorage.setItem('discountedAmount', 0);
            localStorage.setItem('couponApplied', false);
            localStorage.setItem('couponId', null);
            localStorage.setItem('couponCode', null);
        }
        window.location = "/#/checkout";
    }

    stepForward = (index) => {

        // check if grand total is zero
        
        var grandTotal = (this.state.selectedCourse.course_registration != null ? parseInt(this.state.selectedCourse.course_registration) : 0) + parseInt(this.state.updatedPrice) + (this.state.selectedCourse.course_secutiry != null ? parseInt(this.state.selectedCourse.course_secutiry) : 0) + (this.state.sel_pickupmethod == 1 ? parseInt(this.state.shippingCharges) : 0) - parseInt(this.state.couponDiscountAmount) ;

        if(!grandTotal || grandTotal == 0 || grandTotal == '0'){
            this.proceedCheckout(0); // directly call function for checkout
            return 0;
        }
        else{
            if(index == 1){
                this.setState({
                    step : index
                })
            }
            else if(index == 2){ // trying to access payment step
                // if(this.state.contactNum == ''){
                //     this.setState({
                //         contactNumError : 'contact number is required.'
                //     })
                // }
                // else if(!validatePhone(this.state.contactNum)){
                //     this.setState({
                //         contactNumError : 'contact number is not valid.'
                //     })
                // }
                // else{
                //     this.setState({
                //         contactNumError : ''
                //     })
                // }
    
                if(this.state.sel_pickupmethod == 1){
                    if(this.state.order_address == ''){
                        this.setState({
                            order_addressError : 'Address is required.'
                        })
                    }
                    else if(this.state.order_address.length < 10){
                        this.setState({
                            order_addressError : 'Address is too short, please share compelete street address.'
                        })
                    }
                    else{
                        this.setState({
                            order_addressError : ''
                        })
                    }
                }
                else{
                    this.setState({
                        order_addressError : ''
                    })
                }
    
                setTimeout(() => {
                    if(this.state.contactNumError == '' && this.state.order_addressError == ''){
                        this.setState({
                            step : index // going forward to next
                        })
                    }
                }, 200);
    
            }
            else{
                this.setState({
                    step : index
                })
            }
        }

        
    }

    applyCouponCode = () => {
        var selectedDayId = 0;
        if(this.state.selectedDays.length){
            selectedDayId = this.state.selectedDays[localStorage.getItem('selectedDayId')].id;
        }
        this.props.applyCheckoutCoupon(
            this.state.selectedCourse.id, // course id
            this.state.couponCode, // coupon code applied
            selectedDayId // course selected days id
        ); //calling action for applying checkout coupon
    }

    proceedCheckout = (type) => {

        // if(type == 1){
            
            var userid = JSON.parse(localStorage.loginData).user.id;
            var courseid = this.state.selectedCourse.id;
            var couponcode = this.state.couponCode;
            var dayid = 0;
            if(this.state.selectedDays.length){
                dayid = this.state.selectedDays[localStorage.getItem('selectedDayId')].id;
            }
            var payinclass_details = this.state.precautions;
            var pickupmethod = this.state.sel_pickupmethod;
            var order_address = '';
            if(pickupmethod == 1){
                order_address = this.state.order_address;
            }
            var location = 0;
            if(pickupmethod == 1){
                location = this.state.selectedLocations[this.state.choosedLocation].id;
            }
            var contact_number = this.state.contactNum;
            var word_problem = this.mapStateToFormat(this.state.word_problem),
            logic_reasoning = this.mapStateToFormat(this.state.logic_reasoning),
            reading_comprehennsion = this.mapStateToFormat(this.state.reading_comprehennsion),
            grammer_vocabulary = this.mapStateToFormat(this.state.grammer_vocabulary),
            lincoln_method = this.mapStateToFormat(this.state.lincoln_method),
            macmillan_education = this.mapStateToFormat(this.state.macmillan_education),
            mcgraw_hill = this.mapStateToFormat(this.state.mcgraw_hill),
            scholastic = this.mapStateToFormat(this.state.scholastic),
            combination_resources = this.mapStateToFormat(this.state.combination);

            if(type == 1){
                this.props.paymentCashOnDelivery( 
                    userid,
                    courseid,
                    couponcode,
                    dayid,
                    payinclass_details,
                    order_address,
                    pickupmethod,
                    location,
                    contact_number,
                    word_problem,
                    logic_reasoning,
                    reading_comprehennsion,
                    grammer_vocabulary,
                    lincoln_method,
                    macmillan_education,
                    mcgraw_hill,
                    scholastic,
                    combination_resources
                ); // calls action for COD Payment
            }
            else{
                this.props.paymentJazzCashMethod( 
                    userid,
                    courseid,
                    couponcode,
                    dayid,
                    // payinclass_details,
                    order_address,
                    pickupmethod,
                    location,
                    contact_number,
                    word_problem,
                    logic_reasoning,
                    reading_comprehennsion,
                    grammer_vocabulary,
                    lincoln_method,
                    macmillan_education,
                    mcgraw_hill,
                    scholastic,
                    combination_resources
                ); // calls action for Jazz Cash Method

            }
            
            this.setState({
                checkoutInProgress : true
            })

        // }
        // else{
        //     alert("checkout with JazzCash")
        // }
        

    }

    mapStateToFormat = (state) => {
        if(state){
            return 1;
        }
        else{
            return 0;
        }
    }


    loadDashboard() {
        try {
          var userId = JSON.parse(localStorage.getItem('loginData')).user.id;
          window.location = getBaseUrl() + '/home/' + userId;
        }
        catch (error) {
          console.error('there error occured while redirected to user profile:', error);
        }
    }



    render() {
        
        const { form_HashKey, form_Language, form_MerchantID, form_Password, form_PostURL, form_ReturnURL,form_TxnCurrency, form_Version, form_amount, form_billReference, form_couponid, form_course_name, form_courseid, form_discountedAmount, form_orderRefNum, form_program_id, form_securehashCard, form_securehashMobile, form_txnDateTime, form_txnExpiryDateTime, form_userid, form_userid_numeric, form_whichForm, isCouponEnabled } = this.state;

        
        var userInformation = localStorage.getItem('loginData');
        var user_userName = "";
        var user_userFullname = "";
        try {
            user_userName = JSON.parse(userInformation).user.user_loginname;
            user_userFullname = JSON.parse(userInformation).user.user_name;
        } catch(e) {
            console.log(e);
        }
        
        return (
            <div className="CheckoutPaymentComponent">

                <Modal isOpen={this.state.showPopup} centered={true} size={this.state.selectedCourse.is_online == 2 ? "lg":"md"} className="checkoutPopupMain">

                    <ModalBody>
                        
                        {/* <Form action={getBaseUrl() + "/checkout"} method="GET" id="_jazCashForm">
                            <input type="hidden" name="_fd" value={this.state.praperFormData} />
                        </Form> */}


                        {/* Card Payment */}
                        <form method="post" action={ "" + form_PostURL }  id="cardForm">  
                            <input type="hidden" name="pp_Version" value={ "" + form_Version} />
                            <input type="hidden" name="pp_TxnType" value="MIGS" />
                            <input type="hidden" name="pp_Language" value={ "" + form_Language } />
                            <input type="hidden" name="pp_MerchantID" value={ "" + form_MerchantID } />
                            <input type="hidden" name="pp_SubMerchantID" value="" />
                            <input type="hidden" name="pp_Password" value={ "" + form_Password } />
                            <input type="hidden" name="pp_TxnRefNo" value={ "" + form_orderRefNum }/>
                            <input type="hidden" name="pp_Amount" value={"" + form_amount } />
                            <input type="hidden" name="pp_TxnCurrency" value={ "" + form_TxnCurrency }/>
                            <input type="hidden" name="pp_TxnDateTime" value={ "" + form_txnDateTime } />
                            <input type="hidden" name="pp_BillReference" value={ "" + form_billReference } />
                            <input type="hidden" name="pp_Description" value={ "" + form_course_name } />
                            <input type="hidden" name="pp_DiscountedAmount" value={ "" + form_discountedAmount } />
                            <input type="hidden" name="pp_DiscountBank" value="" />
                            <input type="hidden" name="pp_TxnExpiryDateTime" value={"" + form_txnExpiryDateTime } />
                            <input type="hidden" name="pp_ReturnURL" value={"" + form_ReturnURL } />
                            <input type="hidden" name="pp_SecureHash" id="securehashCard" value={"" + form_securehashCard } />
                            <input type="hidden" name="ppmpf_1" value={"" + form_userid_numeric } />
                            <input type="hidden" name="ppmpf_2" value={"" + form_courseid  } />
                            <input type="hidden" name="ppmpf_3" value={"" + form_program_id } />
                            <input type="hidden" name="ppmpf_4" value={"" + form_couponid } />
                            <input type="hidden" name="ppmpf_5" value="" />
                        </form>
                        {/* End Card Payment */}

                        {/* Mobile Payment */}
                        <form method="post" action={ "" + form_PostURL } id="mobileForm">  
                            <input type="hidden" name="pp_Version" value={ "" + form_Version } />
                            <input type="hidden" name="pp_TxnType" value="MWALLET" />
                            <input type="hidden" name="pp_Language" value={ "" + form_Language } />
                            <input type="hidden" name="pp_MerchantID" value={"" + form_MerchantID } />
                            <input type="hidden" name="pp_SubMerchantID" value="" />
                            <input type="hidden" name="pp_Password" value={"" + form_Password } />
                            <input type="hidden" name="pp_TxnRefNo" value={"" + form_orderRefNum }/>
                            <input type="hidden" name="pp_Amount" value={"" + form_amount } />
                            <input type="hidden" name="pp_TxnCurrency" value={"" + form_TxnCurrency }/>
                            <input type="hidden" name="pp_TxnDateTime" value={"" + form_txnDateTime } />
                            <input type="hidden" name="pp_BillReference" value={"" + form_billReference } />
                            <input type="hidden" name="pp_Description" value={"" + form_course_name } />
                            <input type="hidden" name="pp_DiscountedAmount" value={ "" + form_discountedAmount } />
                            <input type="hidden" name="pp_DiscountBank" value="" />
                            <input type="hidden" name="pp_TxnExpiryDateTime" value={"" + form_txnExpiryDateTime } />
                            <input type="hidden" name="pp_ReturnURL" value={"" + form_ReturnURL } />
                            <input type="hidden" name="pp_SecureHash" id="securehashMobile" value={"" +  form_securehashMobile } />
                            <input type="hidden" name="ppmpf_1" value={ "" + form_userid_numeric } />
                            <input type="hidden" name="ppmpf_2" value={ "" + form_courseid } />
                            <input type="hidden" name="ppmpf_3" value={ "" + form_program_id } />
                            <input type="hidden" name="ppmpf_4" value={ "" + form_couponid } />
                            <input type="hidden" name="ppmpf_5" value="" />
                        </form>
                        {/* End Mobile Payment */}

                        
                        {
                        this.state.checkoutSuccessMessage != '' ?
                            // this.state.checkoutSuccessMessage == "you are already enrolled in this course" ?
                            //     <div>
                            //         <h3 style={{padding: '10px'}}>{this.state.checkoutSuccessMessage}</h3>
                            //         <Link to="/course/">
                            //             <Button size="md" color="primary">
                            //                 View this course
                            //             </Button>
                            //         </Link>
                            //     </div>
                            // : 
                            <>
                                <h3 style={{padding: '30px'}} dangerouslySetInnerHTML={{__html: this.state.checkoutSuccessMessage}}></h3>
                                <Button size="lg" color="light" style={{marginLeft: '35%'}} onClick={() => this.loadDashboard()}>Go to Dashboard</Button>
                            </>
                        :
                        <div style={{textAlign: 'center'}}>
                            {
                                this.state.selectedCourse ?
                                <Container fluid style={{textAlign: 'left', padding: '0'}}>
                                    {/* <Row style={{padding: '1vh 0px 2vh 0'}}>
                                        <Col>
                                            <h3>Let's process your order</h3>
                                        </Col>
                                    </Row>
                                    <Row style={{textAlign: 'center', paddingBottom: '3vh'}}>
                                        <Col>
                                            <ButtonGroup size="lg" style={{width: '100%'}} >
                                                <Button color="primary" active={this.state.step == 1}><span>1</span> Place Order</Button>
                                                <Button color="primary" active={this.state.step == 2}><span>2</span> Payment Type</Button>
                                                <Button color="primary" active={this.state.step == 3}><span>3</span> Checkout</Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row> */}
                                    {
                                        this.state.step == 1 ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <p style={{margin: '0', fontSize: '14px'}}>{ user_userFullname } {
                                                        this.state.selectedCourse.is_online == 2 ? "ordering" : "enrolling in"
                                                    }:</p>
                                                    <h5 style={{fontSize: '16px', marginBottom : 0}}>{this.state.selectedCourse.course_name}</h5>
                                                </Col>
                                            </Row> 
                                            <hr/>
                                            {
                                                this.state.selectedCourse.is_online == 2 ?
                                                <Row>
                                                    <Col md={{size: 12}}>
                                                        <p>Workbook can be customized for your child’s needs.<br/>
                                                            What does your child need? <sup>(Select all that apply)</sup></p>
                                                        
                                                        <FormGroup className="termsBox">
                                                            <Label>
                                                                <CustomInput checked={this.state.word_problem} type="checkbox" id="word_problem" name="word_problem" value="Word problems" label="Word problems" onChange={(evt) => {
                                                                    this.setState({ word_problem : !this.state.word_problem })
                                                                }} />
                                                            </Label>
                                                            <br/>
                                                            <Label>
                                                                <CustomInput checked={this.state.logic_reasoning} type="checkbox" id="logic_reasoning" name="logic_reasoning" value="Logic & reasoning" label="Logic & reasoning" onChange={(evt) => {
                                                                    this.setState({ logic_reasoning : !this.state.logic_reasoning })
                                                                }} />
                                                            </Label>
                                                            <br/>
                                                            <Label>
                                                                <CustomInput checked={this.state.reading_comprehennsion} type="checkbox" id="reading_comprehennsion" name="reading_comprehennsion" value="Reading comprehension" label="Reading comprehension" onChange={(evt) => {
                                                                    this.setState({ reading_comprehennsion : !this.state.reading_comprehennsion })
                                                                }} />
                                                            </Label>
                                                            <br/>
                                                            <Label>
                                                                <CustomInput checked={this.state.grammer_vocabulary} type="checkbox" id="grammer_vocabulary" name="f" value="Grammar & vocabulary" label="Grammar & vocabulary" onChange={(evt) => {
                                                                    this.setState({ grammer_vocabulary : !this.state.grammer_vocabulary })
                                                                }} />
                                                            </Label>
                                                        </FormGroup>
                                                        <hr/>
                                                        <p>Your workbook will be created by compiling the best royalty-free resources available online that meet your child’s needs.<br />
                                                            Which resources do you prefer? <sup>(Select all that apply)</sup></p>

                                                        <FormGroup className="termsBox">
                                                            <Label>
                                                                <CustomInput checked={this.state.lincoln_method} type="checkbox" id="lincoln_method" name="lincoln_method" value="Lincoln Method" label="Lincoln Method" onChange={(evt) => {
                                                                    this.setState({ lincoln_method : !this.state.lincoln_method })
                                                                }} />
                                                            </Label> <sup>(preferred)</sup>
                                                            <br/>
                                                            <Label>
                                                                <CustomInput checked={this.state.macmillan_education} type="checkbox" id="macmillan_education" name="macmillan_education" value="Macmillan Education" label="Macmillan Education" onChange={(evt) => {
                                                                    this.setState({ macmillan_education : !this.state.macmillan_education })
                                                                }} />
                                                            </Label> <sup>(preferred)</sup>
                                                            <br/>
                                                            <Label>
                                                                <CustomInput checked={this.state.mcgraw_hill} type="checkbox" id="mcgraw_hill" name="mcgraw_hill" value="mcgraw_hill" label="McGraw-Hill" onChange={(evt) => {
                                                                    this.setState({ mcgraw_hill : !this.state.mcgraw_hill })
                                                                }} />
                                                            </Label> <sup>(preferred)</sup>
                                                            <br/>
                                                            <Label>
                                                                <CustomInput checked={this.state.scholastic} type="checkbox" id="scholastic" name="scholastic" value="Scholastic" label="Scholastic" onChange={(evt) => {
                                                                    this.setState({ scholastic : !this.state.scholastic })
                                                                }} />
                                                            </Label>
                                                            <br/>
                                                            <Label>
                                                                <CustomInput checked={this.state.combination} type="checkbox" id="combination_resources" name="combination" value="Combination" label="Combination of the best resources" onChange={(evt) => {
                                                                    this.setState({ combination : !this.state.combination })
                                                                }} />
                                                            </Label>
                                                        </FormGroup>
                                                        <hr />

                                                        <p>You can pickup the workbook from our center located in Block 5 Clifton, Karachi or we can deliver the workbook to you.<br />
                                                            What do you prefer?</p>
                                                            <FormGroup className="genderBox">
                                                                <Label>
                                                                    <CustomInput checked={this.state.sel_pickupmethod == 0} type="radio" id="sel_pickupmethod" name="sel_pickupmethod" label="I will pick it up from the center" onChange={(evt) => {
                                                                        this.setState({ sel_pickupmethod: 0, choosedLocation : 0 }, () => {
                                                                            this.setState({
                                                                                shippingCharges : 0
                                                                            })
                                                                        })
                                                                    }} />
                                                                </Label><br />
                                                                <Label>
                                                                    <CustomInput checked={this.state.sel_pickupmethod == 1} type="radio" id="sel_pickupmethod2" name="sel_pickupmethod" label="Deliver at my address" onChange={(evt) => {
                                                                        this.setState({ sel_pickupmethod: 1 }, () => {
                                                                            this.setState({
                                                                                shippingCharges : this.state.selectedLocations[this.state.choosedLocation].fee
                                                                            })
                                                                        })
                                                                    }} />
                                                                </Label>
                                                                {
                                                                    this.state.sel_pickupmethod == 1 ?
                                                                    <div style={{padding: '20px 30px', background: '#eaeaea'}}>
                                                                        <Row>
                                                                            <Col md={{size: 6}}>
                                                                                <label>
                                                                                    Select city <sup>(delivery charges apply)</sup>
                                                                                </label>
                                                                                <Input type="select" name="sel_location" id="sel_location" bsSize="md" onChange={(evt) => {
                                                                                    this.setState({ choosedLocation : evt.target.value }, () => {
                                                                                        this.setState({
                                                                                            shippingCharges : this.state.selectedLocations[this.state.choosedLocation].fee
                                                                                        })
                                                                                    })
                                                                                }}>
                                                                                    {
                                                                                        this.state.selectedLocations && this.state.selectedLocations.length && this.state.selectedLocations.map((location, key) =>
                                                                                            <option key={key} value={key} selected={this.state.choosedLocation == key}>{ location.location + ' (Rs.' + location.fee + ')' }</option>
                                                                                        )
                                                                                    }
                                                                                </Input>
                                                                            </Col>
                                                                            <Col md={{size: 6}}>
                                                                                <label>
                                                                                    Shipping address
                                                                                </label>
                                                                                <Input style={{borderColor: this.state.order_addressError != '' ? 'red' : ''}} type="textarea" name="order_address" id="order_address" value={this.state.order_address} onChange={(evt) => {
                                                                                    this.setState({ order_address : evt.target.value })
                                                                                }}/>
                                                                                {
                                                                                    this.state.order_addressError != '' ?
                                                                                    <label style={{fontSize: '12px', color: 'red'}}>{this.state.order_addressError}</label> : null
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </div> : null
                                                                }
                                                            </FormGroup>

                                                            <hr/>
                                                    </Col>
                                                    
                                                </Row>
                                                : null
                                            }
                                            {
                                                !isCouponEnabled ? 
                                                    <Row>
                                                        <Button className="promoCodeButton" color="primary" size="lg" block onClick={(e) => this.setState({
                                                            isCouponEnabled : true
                                                        })}>
                                                            <FontAwesomeIcon style={{marginRight: '10px'}} icon={faPlus} size="1x" color="#fff" />
                                                            I have a promo code
                                                        </Button>
                                                    </Row>
                                                :   
                                                    <Row>
                                                        <Col md={{size : 12}}>
                                                            <label style={{fontSize: '12px', margin : 0}}>promo code</label>
                                                            {
                                                                this.state.isCouponError != '' ?
                                                                    <label style={{color:'red', fontSize: '12px', fontWeight: 'bold', marginLeft: '10px'}}>{this.state.isCouponError}</label>
                                                                : null
                                                            }
                                                        </Col>
                                                        <Col md={{ size: 12 }}>
                                                            <Row className="couponBoxDesign">
                                                                <Col md={{ size : 9 }} xs={{ size : 9 }}>
                                                                    <FormGroup>
                                                                        <Input type="text" name="couponCode" id="couponCode" placeholder="Enter" value={this.state.couponCode} onChange={(evt) => {
                                                                            this.setState({ couponCode: evt.target.value })
                                                                        }} />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={{ size : 3 }} xs={{ size : 3 }}>
                                                                    <Button onClick={e => this.applyCouponCode()} block color="warning" disabled={this.state.couponCode == ''}>Apply</Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                            }
                                            <div style={{clear: 'both', marginTop: '100px'}}></div>
                                            <Row style={{display : this.state.hideContactNum ? 'none' : ''}}>
                                                <Col md={{ size : 6 }}>
                                                    {
                                                        this.state.selectedCourse.is_online == 2 ?
                                                        <label>We will call you to confirm your order.<br/>
                                                        What is your contact number?</label> : null
                                                    }
                                                    <Input style={{ borderColor: this.state.contactNumError != '' ? 'red' : '' }} value={this.state.contactNum} type="text" name="contactNum" id="contactNum" placeholder="Contact number" onChange={(evt) => {
                                                        this.setState({ contactNum: evt.target.value })
                                                    }} />
                                                    {
                                                        this.state.contactNumError != '' ?
                                                        <label style={{fontSize: '12px', color: 'red'}}>{this.state.contactNumError}</label> : null
                                                    }
                                                </Col>
                                            </Row>
                                            {
                                                !this.state.hideContactNum ? <hr/> : null
                                            }
                                            <Row>
                                                <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                    <label>
                                                        {
                                                            this.state.selectedCourse.is_online == 2 ?
                                                            'Total fee for compiling, printing and producing: '
                                                            :
                                                            'Price: '
                                                        }
                                                    </label>
                                                </Col>
                                                
                                                <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                    <label>
                                                        <strong>{this.state.selectedCourse.symbol + formatPrice(this.state.updatedPrice)}</strong>
                                                    </label>
                                                </Col>
                                            </Row>
                                            {
                                            this.state.isCouponApplied ?
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Promo discount: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong>{this.state.selectedCourse.symbol + '-' + parseInt(formatPrice(this.state.couponDiscountAmount))}</strong></label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            : null 
                                            }
                                            
                                            {
                                            this.state.sel_pickupmethod == 1 ?
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Shipping charges: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong style={{marginLeft: '-15px'}}>{'+ ' + this.state.selectedCourse.symbol + formatPrice(this.state.shippingCharges)}</strong></label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            : null 
                                            }
                                            
                                            {
                                            this.state.selectedCourse.course_secutiry != '0' && parseInt(this.state.selectedCourse.course_secutiry) > 0 ?
                                            
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Security Deposit: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong style={{marginLeft: '-15px'}}>{'+ ' + this.state.selectedCourse.symbol + formatPrice(this.state.selectedCourse.course_secutiry)}</strong></label>
                                                            <label style={{fontSize: '10px', position: 'relative', top: '-4px', display: 'block'}}>Security deposit is refundable</label>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            : null 
                                            }
                                            
                                            {
                                            this.state.selectedCourse.course_registration != '0' && parseInt(this.state.selectedCourse.course_registration) > 0 ?
                                            
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Registration Fee: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong style={{marginLeft: '-15px'}}>{'+ ' + this.state.selectedCourse.symbol + formatPrice(this.state.selectedCourse.course_registration)}</strong></label>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            : null 
                                            }

                                            <hr />
                                            
                                            {
                                            (this.state.selectedCourse.course_registration != '0' && parseInt(this.state.selectedCourse.course_registration) > 0)
                                            || (this.state.selectedCourse.course_secutiry != '0' && parseInt(this.state.selectedCourse.course_secutiry) > 0)
                                            || this.state.isCouponApplied
                                            || this.state.sel_pickupmethod == 1 ?
                                            
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Total price: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong style={{fontSize : '20px'}}>{this.state.selectedCourse.symbol + formatPrice( (this.state.selectedCourse.course_registration != null ? parseInt(this.state.selectedCourse.course_registration) : 0) + parseInt(this.state.updatedPrice) + (this.state.selectedCourse.course_secutiry != null ? parseInt(this.state.selectedCourse.course_secutiry) : 0) + (this.state.sel_pickupmethod == 1 ? parseInt(this.state.shippingCharges) : 0) - parseInt(this.state.couponDiscountAmount) ) }</strong></label>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            : null 
                                            }
                                        </div> : null
                                    }
                                    {
                                        this.state.step == 2 ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <p style={{margin: '0', fontSize: '14px'}}>{ user_userFullname } {
                                                        this.state.selectedCourse.is_online == 2 ? "ordering" : "enrolling in"
                                                    }:</p>
                                                    <h5 style={{fontSize: '16px', marginBottom: 0}}>{this.state.selectedCourse.course_name}</h5>
                                                </Col>
                                            </Row> 
                                            <hr/>
                                            <Row style={{textAlign:'left'}}>
                                                <Col>
                                                    <p style={{fontSize : '12px', margin: 0}}>
                                                        How would you like to pay ?
                                                    </p>
                                                </Col>
                                                <Col md={{size: 12}} style={{marginTop:10}} 
                                                    onClick={(e) => this.redirectToCheckout('credit', this.state.selectedCourse)}
                                                >
                                                    <img className={'payimg'} src={require('./../../public/images/cardtransfer.png')}/>
                                                    <p className={'paytext'}> Credit/Debit Card</p>
                                                </Col>
                                                
                                                <Col md={{size: 12}}  style={{marginTop:10}} 
                                                    onClick={(e) => this.redirectToCheckout('gcash', this.state.selectedCourse)}
                                                >
                                                    <img className={'payimg'} src={require('./../../public/images/gcash.png')}/>
                                                    <p className={'paytext'}> G Cash</p>
                                                </Col>
   
                                                <Col md={{size: 12}}  style={{marginTop:10}} 
                                                    onClick={(e) => this.redirectToCheckout('grabpay', this.state.selectedCourse)}
                                                >
                                                    <img className={'payimg'} src={require('./../../public/images/grabpay.png')}/>
                                                    <p className={'paytext'}> Grab Pay</p>
                                                </Col>
                                                <Col md={{size: 12}}>
                                                    {
                                                        this.state.selectedCourse.is_online != 1 ?
                                                        <div onClick={e => this.setState({ paymentMethod: 1 }, () => {
                                                            this.stepForward(this.state.step + 1)
                                                        })} className={this.state.paymentMethod == 1 ? 'checkoutBox selected' : 'checkoutBox' }>
                                                            <p>
                                                                {
                                                                    this.state.selectedCourse.is_online == 2 ?
                                                                    "Cash on delivery"
                                                                    : "I will pay in cash (later)"
                                                                }
                                                            </p>
                                                        </div> : null
                                                    }
                                                </Col>
                                            </Row>
                                            
                                            <div style={{clear: 'both', marginTop: '10px'}}></div>

                                            <Row>
                                                <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                    <label>
                                                        {
                                                            this.state.selectedCourse.is_online == 2 ?
                                                            'Total fee for compiling, printing and producing: '
                                                            :
                                                            'Price: '
                                                        }
                                                    </label>
                                                </Col>
                                                
                                                <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                    <label>
                                                        <strong>{this.state.selectedCourse.symbol + formatPrice(this.state.updatedPrice)}</strong>
                                                    </label>
                                                </Col>
                                            </Row>
                                            {
                                            this.state.isCouponApplied ?
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Promo discount: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong>{this.state.selectedCourse.symbol + '-' + formatPrice(this.state.couponDiscountAmount)}</strong></label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            : null 
                                            }
                                            
                                            {
                                            this.state.sel_pickupmethod == 1 ?
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Shipping charges: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong style={{marginLeft: '-15px'}}>{'+ ' + this.state.selectedCourse.symbol + formatPrice(this.state.shippingCharges)}</strong></label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            : null 
                                            }
                                            
                                            {
                                            this.state.selectedCourse.course_secutiry != '0' && parseInt(this.state.selectedCourse.course_secutiry) > 0 ?
                                            
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Security Deposit: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong style={{marginLeft: '-15px'}}>{'+ ' + this.state.selectedCourse.symbol + formatPrice(this.state.selectedCourse.course_secutiry)}</strong></label>
                                                            <label style={{fontSize: '10px', position: 'relative', top: '-4px', display: 'block'}}>Security deposit is refundable</label>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            : null 
                                            }
                                            
                                            {
                                            this.state.selectedCourse.course_registration != '0' && parseInt(this.state.selectedCourse.course_registration) > 0 ?
                                            
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Registration Fee: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong style={{marginLeft: '-15px'}}>{'+ ' + this.state.selectedCourse.symbol + formatPrice(this.state.selectedCourse.course_registration)}</strong></label>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            : null 
                                            }

                                            <hr />
                                            
                                            {
                                            (this.state.selectedCourse.course_registration != '0' && parseInt(this.state.selectedCourse.course_registration) > 0)
                                            || (this.state.selectedCourse.course_secutiry != '0' && parseInt(this.state.selectedCourse.course_secutiry) > 0)
                                            || this.state.isCouponApplied
                                            || this.state.sel_pickupmethod == 1 ?
                                            
                                                <Row>
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label>Total price: </label>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col xs={{size : 6}} style={{textAlign: 'left'}} >
                                                        <div>
                                                            <label><strong style={{fontSize : '20px'}}>{this.state.selectedCourse.symbol + formatPrice( (this.state.selectedCourse.course_registration != null ? parseInt(this.state.selectedCourse.course_registration) : 0) + parseInt(this.state.updatedPrice) + (this.state.selectedCourse.course_secutiry != null ? parseInt(this.state.selectedCourse.course_secutiry) : 0) + (this.state.sel_pickupmethod == 1 ? parseInt(this.state.shippingCharges) : 0) - parseInt(this.state.couponDiscountAmount) ) }</strong></label>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            : null 
                                            }
                                        </div> : null
                                    }
                                    {
                                        this.state.step == 3 ?

                                            this.state.paymentMethod == 0 ?
                                            <div>
                                                <Row>
                                                    <Col md={{size: '10', offset: 1}}>
                                                        {/* <Media width="100%" object src={jazzbanner} alt="jazzbanner" /> */}
                                                        {/* <hr/> */}
                                                        <h5 style={{marginBottom: '2vh', marginTop: '10px', paddingLeft : '15px'}}>Select Payment Type</h5>
                                                        <FormGroup style={{paddingLeft : '15px'}} className="genderBox">
                                                            <Label style={{display: 'block'}}>
                                                                <CustomInput checked={this.state.paymentMethodType == 0} type="radio" id="card_payment" name="card_payment" label="Debit/Credit Card" onChange={(evt) => {
                                                                    this.setState({ paymentMethodType: 0 })
                                                                }} />
                                                            </Label>
                                                            <Label style={{display: 'block'}}>
                                                                <CustomInput checked={this.state.paymentMethodType == 1} type="radio" id="mobile_account" name="mobile_account" label="Jazzcash mobile account" onChange={(evt) => {
                                                                    this.setState({ paymentMethodType: 1 })
                                                                }} />
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            :
                                            this.state.paymentMethod == 2 ?
                                                <div>
                                                    <Row>
                                                        <Col md={{size: '10', offset: 1}} style={{padding: '10px', background: "#fff", borderRadius: '10px', color: "#000"}}>
                                                            {/* <Alert color="primary">
                                                                NOTE: Please try to use this referral code while making transaction in description or details. It'll help us tracking this order. If you're unable to do this that's absolutely fine 
                                                            </Alert> */}
                                                            <p>Here are our account details for bank transfer:</p>
                                                            <p style={{marginTop: '10px'}}>
                                                                <ul>
                                                                    <li><strong>Account Title:</strong>
                                                                    <br/>Lincoln Method </li>
                                                                    <li><strong>Account Number:</strong><br/> 1511-0016-3931-0005</li>
                                                                    <li><strong>Account Type:</strong> <br/>MIB Hidayat Current Account</li>
                                                                    <li><strong>Bank name:</strong> <br/>MIB Bank (MCB Islamic Bank)</li>
                                                                    <li><strong>IBAN Number:</strong> <br/>PK11 MCIB 1511 0016 3931 0005</li>
                                                                </ul>
                                                            </p>
                                                            <p>Please make sure to WhatsApp your deposit receipt at our official number <a style={{fontWeight: 'bold'}} href="callto:03008296228">03008296228</a> to proceed further.</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            :
                                                <div>
                                                    <Row>
                                                        <Col md={{size: '10', offset: 1}}>
                                                            {
                                                                this.state.selectedCourse.is_online == 2 ?
                                                                    <p>Please share any additional information.</p>
                                                                :
                                                                    <p>Please share any necessary information regarding your child, this could be any allergies that your child may have. This will help us in taking appropriate precautions.</p>
                                                            }
                                                            <Input type="textarea" name="precautions" id="precautions" value={this.state.precautions} onChange={(evt) => {
                                                                this.setState({ precautions : evt.target.value })
                                                            }}/>
                                                        </Col>
                                                    </Row>
                                                </div>
                                        : null
                                    }

                                </Container> : null
                            } 
                            
                        </div>
                    }
                    </ModalBody>
                    <ModalFooter style={{border: 'none'}}>
                        <Button size="md" color="secondary" onClick={this.toggleLoginModal}>
                        {
                            this.state.checkoutSuccessMessage == '' ? 'cancel' : 'Done'
                        }
                        </Button>
                        {
                            this.state.step != 1 && this.state.checkoutSuccessMessage == '' ?
                            <Button size="md" color="primary" onClick={e => this.stepForward(this.state.step - 1)}>Back</Button> : null
                        }
                        {
                            this.state.step != 3 && this.state.step != 2 && this.state.checkoutSuccessMessage == '' ?
                            <Button size="md" color="primary" onClick={e => this.stepForward(this.state.step + 1)}>Next</Button>: null
                        }
                        {
                            this.state.step == 3 && this.state.checkoutSuccessMessage == '' && this.state.paymentMethod != 2 ?
                            <Button disabled={this.state.checkoutInProgress} size="md" color="warning" onClick={e => this.proceedCheckout(this.state.paymentMethod)}>
                                {
                                    this.state.checkoutInProgress ?
                                        <Spinner style={{width: '1rem', height: '1rem', marginRight: '10px'}} color="dark" />
                                    : null
                                }
                                Checkout
                            </Button>: null
                        }
                        
                        {
                            this.state.step == 3 && this.state.checkoutSuccessMessage == '' && this.state.paymentMethod == 2 ?
                            <Button disabled={this.state.checkoutInProgress} size="md" color="warning" onClick={e => this.proceedCheckout(1)}>
                                Thank you
                            </Button>: null
                        }
                    </ModalFooter>
                    
                </Modal>

            </div>
        );
    }

}

CheckoutPaymentComponent.propTypes = {
    applyCheckoutCoupon: PropTypes.func.isRequired,
    paymentCashOnDelivery : PropTypes.func.isRequired,
    paymentJazzCashMethod : PropTypes.func.isRequired,
    fetchJazzCashAddiotionalData : PropTypes.func.isRequired,
    validateCourseAvailability : PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    checkoutCouponResponse: state.webRed.checkoutCouponResponse,
    checkoutCashOnDevliveryResponse: state.webRed.checkoutCashOnDevliveryResponse,
    checkoutJazzCashResponse: state.webRed.checkoutJazzCashResponse,
    validateEnrollButton: state.webRed.validateEnrollButton,
    addiotionalJazzCheckoutDetailsRes: state.webRed.addiotionalJazzCheckoutDetailsRes
})

export default connect(mapStatesToProps, { applyCheckoutCoupon, paymentCashOnDelivery, paymentJazzCashMethod, fetchJazzCashAddiotionalData, validateCourseAvailability })(CheckoutPaymentComponent);