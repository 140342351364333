import React, { Component } from "react";
import store from './../../store';
import PropTypes from 'prop-types';
import { sendUsername } from './../../actions/webRedActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import SelectSearch from 'react-select-search';

import countryData from './../../actions/countryData';

import { connect } from 'react-redux';

import { isMobileBrowser, validatePhone } from './../../actions/utility';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    CustomInput,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    Label
  } from "reactstrap";

let additionalFields = []; // extrafields array global\

class UsernameField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username : "",
            usernameError : false
        };
        

    }
    
    componentDidMount() {

    }
    
    componentWillReceiveProps(nextProps, thisProps) {

        // if(nextProps.isUserNameExists && this.props.isUserNameExists != nextProps.isUserNameExists){
        //     if(nextProps.isUserNameExists.data.data.status == "success") {
        //         if(nextProps.isUserNameExists.data.data.result && nextProps.isUserNameExists.data.data.result == '1') {

        //             this.setState({
        //                 usernameError: 'This username is already in use.'
        //             })

        //         } else {

        //             this.setState({
        //                 usernameError : false
        //             })

        //         }
        //     }
        // }
        
    }
    
    render() {

        const {isChild} = this.props;
        const {username, usernameError} = this.state;

        return (
            <div style={{padding: '1vh 0'}} className="UsernameField" style={{
                background: 'f4eb49',
                color: '75a9f9'
            }}>
                <div className="wrapper"></div>
                
                <Container>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h3 style={{margin: "75px  0"}}>
                                Select a username for {isChild ? "your child" : "yourself"}
                            </h3>
                        </Col>
                    </Row>
                    
                    <Row className="phoneStyle">
                        <Col xs={{size : 12}}>
                            <FormGroup style={{position: 'relative'}}>
                                <Input type="text" name="username" id="username" value={username}
                                    placeholder="Enter username"
                                    onChange={(e) => {
                                        this.setState({
                                            username : e.target.value
                                        })
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        color: '#007bff',
                                        fontSize: '1.5rem',
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                />
                                <p style={{color: '#fff', fontSize: "12px", padding: '5px 10px', textAlign: 'center'}}>example: <strong>JohnSmith11</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (no spaces)</p>
                            </FormGroup>

                        </Col>
                        
                        <Col md={{size: 12}}>

                            {
                                usernameError ?
                                    <p style={{color: 'red', textAlign : 'center'}}>{usernameError}</p>
                                : null
                            }

                            {
                                username.length > 0 ?
                                    
                                    <Button 
                                        className="nextButtonNew"
                                        style={{ margin: '35px auto' }}
                                        onClick={(e) => {
                                            this.props.sendUsername(username);
                                        }}
                                        size="lg" color="secondary" block>
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} size="2x" color="#fff" />
                                    </Button>

                                : null
                            }

                        </Col>

                        
                    </Row>

                </Container>

            </div>
        );
    }
}

UsernameField.propTypes = {
    sendUsername: PropTypes.func.isRequired,
    // sendVerificationCode: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // isUserNameExists : state.webRed.isUserNameExists
    // validatePhoneExistsRes : state.webRed.validatePhoneExistsRes
    // extraSignUpFields:state.webRed.extraSignUpFields
})

export default connect(mapStatesToProps,{ sendUsername })(UsernameField);