import React, { Component } from "react";
import './../../../public/scss/CoursesComponent.scss';

import store from './../../../store';
import PropTypes from 'prop-types';
import { getAllCourses } from './../../../actions/webRedActions';
import { connect } from 'react-redux';

import Header from './../Header';
import Footer from "./../Footer";

import { formatPrice, isMobileBrowser } from './../../../actions/utility';

import {
    Container,
    Row,
    Col,
    Button,
    ButtonGroup,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Table,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes, faClock, faCalendar, faBook, faVideo, faWifi } from '@fortawesome/free-solid-svg-icons';

import inclass2 from './../../../public/images/courses/inclass2.gif';

import icon_Chapters from './../../../public/images/courses/Chapters.png';
import icon_Location from './../../../public/images/courses/Location.png';
import icon_OnlineLearning from './../../../public/images/courses/Online-Learning.png';
import icon_OnlineVideo from './../../../public/images/courses/Online-Video.png';

import storelistingbg from './../../../public/images/courses/storelistingbg.png';
import noImageFound from './../../../public/images/courses/noImage.png';
import moment from "moment-timezone";

class CoursesComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            grades: {},
            filteredCourses: {},
            sortedCourses: [],
            visibleGradeList: [],
            applyGrid: false,
            showFiltersOnMobile: false,
            popoverOpen: [],
            popOverGradeId: 0,
            popOverCourseId: 0,
            showPopupTime: false,
            zone: ""
        }

        this.courseStateManagement = this.courseStateManagement.bind(this);
    }

    // openLighBox(index) {
    //     this.setState({
    //         photoIndex: index,
    //         isOpen: true
    //     })
    // }

    componentDidMount() {
        document.title = "Our Courses - Skillupz";
        window.addEventListener('scroll', this.handleScroll); // gets the scrolling event

        var dataInStore = store.getState().webRed.coursesData;
        if (dataInStore && dataInStore != "" && dataInStore != "error") { // gets data from store if it already there
            this.courseStateManagement(dataInStore);
        }

        if (isMobileBrowser()) {
            this.setState({
                showFiltersOnMobile: true
            })
        }
        var sone = moment.tz.guess();
        this.setState({
            zone: sone
        })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentWillReceiveProps(nextProps, thisProps) {
        console.log(nextProps)

        if (nextProps.coursesData != 'error' && this.props.coursesData != nextProps.coursesData) { // check if there's change in courses data

            this.courseStateManagement(nextProps.coursesData);

        }

    }

    courseStateManagement(coursesData) {
        // console.log(nextProps.coursesData)
        this.setState({
            grades: coursesData.grades,
            currency: coursesData.currency,
            filteredCourses: coursesData.coursesData.filteredCourses,
            loading: false
        },
            () => {
                console.log(this.state.filteredCourses);
                var sortedCoursesArray = [];
                var sortedPopoverArray = [];
                var coursesKeys = Object.keys(this.state.filteredCourses);
                coursesKeys.map(course => {

                    // console.log(this.state.filteredCourses[course]);
                    var gradeArray = [];
                    var popOverArray = [];
                    var courseKey = Object.keys(this.state.filteredCourses[course]);
                    var gradeName = this.state.filteredCourses[course][courseKey[0]].grade;
                    courseKey.map(_course => {
                        var thisCourse = this.state.filteredCourses[course][_course];
                        gradeArray.push(thisCourse);
                        popOverArray.push(false);
                    })
                    sortedCoursesArray.push({
                        grade: gradeName,
                        courses: gradeArray,
                        visible: true
                    })
                    sortedPopoverArray.push({
                        courses: popOverArray
                    })

                })

                this.setState({
                    sortedCourses: sortedCoursesArray,
                    popoverOpen: sortedPopoverArray
                }, () => {
                    console.log(this.state.sortedCourses);
                    console.log(this.state.popoverOpen);
                })

            })
    }


    filterCourses = (e, grade) => {
        var visibleList = this.state.visibleGradeList;
        if (!visibleList.includes(grade)) {
            visibleList.push(grade);
            this.setState({
                visibleGradeList: visibleList
            },
                () => {
                    // console.log(this.state.visibleGradeList)
                })
        }
        else {
            var index = visibleList.indexOf(grade);
            if (index > -1) {
                visibleList.splice(index, 1);
                this.setState({
                    visibleGradeList: visibleList
                },
                    () => {
                        // console.log(this.state.visibleGradeList)
                    })
            }
        }
    }

    courseCLicked = (e, id) => {
        // console.log(id.splite('?'))

        this.props.history.push('course/' + id.replace('?', ''));
    }

    handleScroll = (event) => {
        var scrollTop = window.scrollY;

        if (scrollTop > 300) {
            if (!this.state.applyGrid) {
                this.setState({
                    applyGrid: true
                })
            }
        }
        else {
            if (this.state.applyGrid) {
                this.setState({
                    applyGrid: false
                })
            }
        }
    }

    // togglePopover = (key, _key) => {
    //     var tempArray = this.state.popoverOpen;
    //     tempArray[key].courses[_key] = !tempArray[key].courses[_key];

    //     this.setState({
    //         popoverOpen : tempArray
    //     }, () => {
    //         // console.log(this.state.popoverOpen);
    //     })
    // }

    toggleTimeTableModal = (key, key2) => {
        this.setState(prevState => ({
            showPopupTime: !prevState.showPopupTime
        }), () => {
            if (this.state.showPopupTime) { // if it's true
                this.setState({
                    popOverGradeId: key,
                    popOverCourseId: key2
                })
            }
        });
    }

    //TimeZone Functions Start
    AMPMTo24HourConversion = (times) => {
        var spaceExist = /\s/g;
        var time = times;
        if (times.match(spaceExist)) {
            var hours = Number(time.match(/^(\d+)/)[1]);
            var minutes = Number(time.match(/:(\d+)/)[1]);
            var AMPM = time.match(/\s(.*)$/)[1];
            if (AMPM == "PM" && hours < 12) hours = hours + 12;
            if (AMPM == "AM" && hours == 12) hours = hours - 12;
            var sHours = hours.toString();
            var sMinutes = minutes.toString();
            if (hours < 10) sHours = "0" + sHours;
            if (minutes < 10) sMinutes = "0" + sMinutes;
            return sHours + ":" + sMinutes;
        }
        else {
            if (time.includes("PM")) {
                time = time.replace("PM", " PM");
            }
            else if (time.includes("AM")) {
                time = time.replace("AM", " AM");
            }
            var hours = Number(time.match(/^(\d+)/)[1]);
            var minutes = Number(time.match(/:(\d+)/)[1]);
            var AMPM = time.match(/\s(.*)$/)[1];
            if (AMPM == "PM" && hours < 12) hours = hours + 12;
            if (AMPM == "AM" && hours == 12) hours = hours - 12;
            var sHours = hours.toString();
            var sMinutes = minutes.toString();
            if (hours < 10) sHours = "0" + sHours;
            if (minutes < 10) sMinutes = "0" + sMinutes;
            return sHours + ":" + sMinutes;
        }
    }

    minutesToHour = (n) => {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rminutes == 0) {
            rminutes = "00"
        }
        console.log(num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).");
        return {
            rhours,
            rminutes
        }
    }
    timeConvert24to12 = (time) => {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {                                                 // If time format correct
            time = time.slice(1);                                              // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM';                           // Set AM/PM
            time[0] = +time[0] % 12 || 12;                                     // Adjust hours
        }
        return time.join('');                                                  // return adjusted time or original string
    }
    changeTimeZoneLocation = (time) => {
        var TIME = this.AMPMTo24HourConversion(time);                          // Convert 12 hour to 24 Hour Time System
        var convertedTimeinMinutes = moment.duration(TIME).asMinutes();        // Convert Hours into Minutes
        var makingTimeDifferenceZero = convertedTimeinMinutes - 300;           // Subtract Pakistan's GMT time with Initial Time.
        var usersCountryOffset = moment().tz(this.state.zone).utcOffset();     // Get Client's UTCoffset by timezone
        var timeinMinutes = makingTimeDifferenceZero + usersCountryOffset;     // Add Clients time by initial time
        var timeinHours = this.minutesToHour(timeinMinutes);                   // Convert minutes into Hour
        var ActualConvertedTime = "";
        if (timeinHours.rhours >= 12) {
            ActualConvertedTime = this.timeConvert24to12(`${timeinHours.rhours}:${timeinHours.rminutes}`); //Convert 24 Hours Back to 12 Hours
        }
        else {
            ActualConvertedTime = timeinHours.rhours + ":" + timeinHours.rminutes + " AM"
        }
        // console.log("Time Converted 24 to 12:", ActualConvertedTime)
        // console.log("Time in Minutes", timeinMinutes)
        // console.log("Time in Hours", timeinHours.rhours, timeinHours.rminutes)
        // console.log("User Country Offset", usersCountryOffset)
        // console.log("Converted Time in Minutes", convertedTimeinMinutes)
        // console.log("After Making Time Difference Zero", makingTimeDifferenceZero);
        // console.log("timein Hours", timeinHours.rhours, timeinHours.rminutes);
        return ActualConvertedTime
    }
    //TimeZone Functions End


    returnListTimings = (gradeId, courseId) => {
        let daysArary = [];

        var timings = this.state.sortedCourses[gradeId].courses[courseId].timing;
        timings = JSON.parse(timings);

        Object.keys(timings).map((id, key) => {
            var day = timings[id];
            if (day.start != '' && day.end != '') {
                var start_time = day.start;
                var firstCharStart = start_time.split("");
                if (firstCharStart[0] == '0') {
                    start_time = start_time.substring(1);
                }

                var end_time = day.end;
                var firstCharEnd = end_time.split("");
                if (firstCharEnd[0] == '0') {
                    end_time = end_time.substring(1);
                }

                var dayName = day.day.split("");

                let dayData = <tr key={key}>
                    <th style={{ padding: '5px 0' }}>{dayName[0] + dayName[1] + dayName[2]}</th>
                    <td style={{ padding: '5px 0' }}>
                        <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faClock} size="1x" color="#ff0863" />
                        {this.changeTimeZoneLocation(start_time)} - {this.changeTimeZoneLocation(end_time)}
                    </td>
                </tr>;
                daysArary.push(dayData);
            }
        })

        console.log(daysArary);
        return daysArary;

        // // Outer loop to create parent
        // for (let i = 0; i < 5; i++) {

        //     let star = <FontAwesomeIcon key={i} icon={faStar} size="1x" color={i < length ? dynamicColor : "#ccc"} />;

        //     stars.push(star)
        // }
        // return stars
    }

    showTeacherDetails = (details, name) => {
        let teachersArary = [];

        var details = JSON.parse(details);

        Object.keys(details).map((id, key) => {
            var teacher = details[id];
            let item = <li key={key} className="teacherDetails">
                <Media width="30" className="tutorThumb" style={{ marginRight: '10px' }} object src={teacher.img_src && teacher.img_src !== '' && teacher.img_src !== "https://lincolnmethod.s3.us-east-1.amazonaws.com/profileImages/" ? teacher.img_src : 'https://pngimage.net/wp-content/uploads/2018/06/no-user-image-png.png'} />

                {
                    teacher.name && teacher.name != '' ?
                        teacher.name
                        : ""
                }
            <sup style={{ marginLeft: 2, fontFamily: 'Caveat', fontSize: 15 }}>Teacher</sup>
            </li>;
            teachersArary.push(item);
        })

        return teachersArary;


    }

    render() {

        const { sortedCourses, popOverGradeId, popOverCourseId } = this.state;

        return (
            // <DocumentMeta {...meta}>
            <div className="CoursesComponent" style={{ background: 'url(' + storelistingbg + ') fixed #ffcc54' }}>

                <Header />
                {

                    sortedCourses && sortedCourses.length ?
                        <Modal isOpen={this.state.showPopupTime} centered={true} size="md">
                            <ModalHeader toggle={e => this.toggleTimeTableModal(0, 0)}>
                                {sortedCourses[popOverGradeId].courses[popOverCourseId].course_name}
                                {/* <sup>({sortedCourses[popOverGradeId].grade})</sup> */}
                            </ModalHeader>
                            <ModalBody>
                                <Col md={{ size: 12 }} style={{ padding: 0 }}>
                                    <p>Timezone: {this.state.zone}</p>
                                    <Table>
                                        <tbody>
                                            {
                                                sortedCourses[popOverGradeId].courses[popOverCourseId].timing &&
                                                    sortedCourses[popOverGradeId].courses[popOverCourseId].timing != '' ?
                                                    this.returnListTimings(popOverGradeId, popOverCourseId)
                                                    : null
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </ModalBody>
                        </Modal>
                        : null
                }

                {/* <div className="homeEdtech" style={{background: 'rgba(0, 0, 0, 0.5)', padding: '4vh 0'}}>
                        <Container>
                            <Row>
                                <Col md={{ size: 12 }} style={{textAlign: 'right'}}>
                                    <h2 style={{margin: 0, color: '#fff'}}>Our Courses</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}

                <div>
                    <Container>
                        <Row>
                            <Col md={{ size: this.state.applyGrid ? 12 : 12 }}>
                                <div className="homeEdtech" style={{ background: 'transparent', padding: '7vh 0' }}>
                                    <Container>
                                        <Row>
                                            {
                                                !this.state.loading ?
                                                    <Col className={this.state.applyGrid ? "filterButtons stick" : "filterButtons"} md={{ size: 12 }}>
                                                        <p>
                                                            <FontAwesomeIcon style={{ marginRight: '15px' }} icon={faFilter} size="1x" color="#000" />
                                                    Filter courses by level
                                                </p>
                                                        <span style={{ marginRight: '15px' }} onClick={e => this.setState({ showFiltersOnMobile: !this.state.showFiltersOnMobile })} className="onlyMobile">
                                                            <FontAwesomeIcon style={{ marginRight: '5px' }} icon={this.state.showFiltersOnMobile ? faFilter : faTimes} size="1x" color="#000" />
                                                    Filter
                                                </span>
                                                        {
                                                            !this.state.showFiltersOnMobile && this.state.grades.length && this.state.grades.map((grade, key) =>
                                                                <Button size={this.state.applyGrid ? 'md' : 'lg'} active={this.state.visibleGradeList.includes(grade.grade)} onClick={e => this.filterCourses(e, grade.grade)} key={key} id={'grade_' + grade.id} outline color="dark" dangerouslySetInnerHTML={{ __html: grade.grade }}></Button>
                                                            )
                                                        }
                                                    </Col>
                                                    : null
                                            }
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col md={{ size: this.state.applyGrid ? 12 : 12 }}>
                                <div className={this.state.applyGrid ? "couresGrid stick" : "couresGrid"} style={{ minHeight: '60vh' }}>
                                    {
                                        this.state.loading ?
                                            <Spinner style={{ width: '3rem', height: '3rem', marginTop: '20vh' }} type="grow" color="dark" />
                                            : null
                                    }
                                    <Container>

                                        {/* <Row> */}
                                        {

                                            this.state.sortedCourses && this.state.sortedCourses.map((course, key) =>

                                                <Row key={key} >
                                                    {
                                                        !this.state.visibleGradeList.length || this.state.visibleGradeList.includes(course.grade) ?
                                                            <Col md={{ size: 12 }}>
                                                                <h5 className="courseCategoryHeading" dangerouslySetInnerHTML={{ __html: course.grade }}></h5>
                                                            </Col>
                                                            : null
                                                    }

                                                    {
                                                        !this.state.visibleGradeList.length || this.state.visibleGradeList.includes(course.grade) ?

                                                            course.courses && course.courses.map((_course, _key) =>
                                                                <Col key={_key} md={{ size: 4 }}>
                                                                    <Card style={{ textAlign: 'left' }}>
                                                                        <Badge color="danger" dangerouslySetInnerHTML={{ __html: _course.grade }}></Badge>
                                                                        <CardImg top width="100%" src={_course.course_img && _course.course_img != null ? "https://dashboard.lincolnmethod.com/uploads/" + _course.course_img : noImageFound} alt={_course.course_name} />
                                                                        <CardBody>
                                                                            <CardTitle>
                                                                                <h5>{_course.course_name}</h5>
                                                                            </CardTitle>
                                                                            <CardSubtitle>
                                                                                <ul>
                                                                                    {
                                                                                        _course.course_location_title ?
                                                                                            <li>
                                                                                                <a target="_new" href={_course.course_location ? _course.course_location : null}>
                                                                                                    {/* <FontAwesomeIcon style={{marginRight: '15px'}} icon={faMapMarkerAlt} size="1x" color="#337ab7" /> */}
                                                                                                    <Media width="20" style={{ marginRight: '15px' }} object src={icon_Location} alt="location" />
                                                                                                    {_course.course_location_title}
                                                                                                </a>
                                                                                            </li>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        _course.chapters && _course.chapters != 0 ?
                                                                                            <li>
                                                                                                {/* <FontAwesomeIcon style={{marginRight: '15px'}} icon={faBook} size="1x" color="#c78800" /> */}
                                                                                                <Media width="20" style={{ marginRight: '15px' }} object src={icon_Chapters} alt="chapter" />
                                                                                                {_course.chapters} Chapters
                                                                        </li>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        _course.videos && _course.videos != 0 ?
                                                                                            <li>
                                                                                                {/* <FontAwesomeIcon style={{marginRight: '15px'}} icon={faVideo} size="1x" color="#dc3545" /> */}
                                                                                                <Media width="20" style={{ marginRight: '15px' }} object src={icon_OnlineVideo} alt="videos" />
                                                                                                {_course.videos} curated online videos
                                                                        </li>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        _course.is_online && _course.is_online != 0 ?
                                                                                            <li>
                                                                                                {/* <FontAwesomeIcon style={{marginRight: '15px'}} icon={faWifi} size="1x" color="#091b3e" /> */}
                                                                                                <Media width="20" style={{ marginRight: '15px' }} object src={icon_OnlineLearning} alt="online" />
                                                                            Online
                                                                        </li>
                                                                                            :
                                                                                            <li>
                                                                                                <Media width="30" style={{ marginRight: '15px' }} object src={inclass2} alt="tuitions" />
                                                                            Tuitions at our center
                                                                        </li>
                                                                                    }
                                                                                    {
                                                                                        sortedCourses[key].courses[_key].timing &&
                                                                                            sortedCourses[key].courses[_key].timing != '' && _course.teachers_info != '{}' ?
                                                                                            <li className="timingsDetails">
                                                                                                {/* <FontAwesomeIcon style={{marginRight: '18px', marginLeft: '0px'}} icon={faClock} size="1x" color="#ff0863" />
                                                                                    Starts in 2 hours... */}
                                                                                                <Button id={"timetableBtn"} color="secondary" size="sm" onClick={e => this.toggleTimeTableModal(key, _key)}>
                                                                                                    <FontAwesomeIcon icon={faCalendar} size="1x" color="#ff0863" />
                                                                                        Course timetable
                                                                                        <span style={{
                                                                                                        color: '#0095ff',
                                                                                                        padding: '1px 2px',
                                                                                                        borderBottom: '1px #0095ff solid',
                                                                                                        marginLeft: '8px'
                                                                                                    }}>view</span>
                                                                                                </Button>

                                                                                            </li>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        _course.teachers_info && _course.teachers_info != undefined && _course.teachers_info != '' ?
                                                                                            this.showTeacherDetails(_course.teachers_info, _course.course_name)
                                                                                            : null
                                                                                    }

                                                                                    {/* {
                                                                        _course.is_online && _course.is_online != 0 ? */}
                                                                                    {/* <li>
                                                                            <FontAwesomeIcon style={{marginRight: '15px'}} icon={faCalendar} size="1x" color="#091b3e" />
                                                                            Course Schedule
                                                                        </li> */}
                                                                                    {/* } */}
                                                                                </ul>
                                                                            </CardSubtitle>
                                                                            {/* <CardText>
                                                                    <p style={{fontSize: '12px'}}>Whether your child’s a bookworm or a struggling reader, sharing a book with others is way more fun than reading solo. It’s more fun to read the same book with your buddies, which is why book clubs are especially great for reluctant readers.</p>
                                                                </CardText> */}

                                                                            {/* <Button onClick={e => this.courseCLicked(e, _course.id)} color="primary" size="md" block>
                                                                    View Details (
                                                                    { _course.course_discount_price && _course.course_discount_price != 0 ?
                                                                        <span>
                                                                            <span style={{textDecoration: 'line-through', color: '#ccc'}}>
                                                                                PKR { formatPrice(_course.course_price) }
                                                                            </span>&nbsp;&nbsp;
                                                                            <span>
                                                                                PKR { formatPrice(_course.course_discount_price) }
                                                                            </span>
                                                                        </span>
                                                                        : 
                                                                        <span>
                                                                            PKR { formatPrice(_course.course_price) }
                                                                        </span>
                                                                    }
                                                                    )
                                                                </Button> */}

                                                                            <ButtonGroup style={{ width: 'calc(100% + 40px)', left: '-20px', bottom: '-20px' }} onClick={e => this.courseCLicked(e, _course.id)} size="md">
                                                                                <Button color="primary">
                                                                                    View Details
                                                                    </Button>
                                                                                <Button color="warning">
                                                                                    {_course.course_discount_price && _course.course_discount_price != 0 ?
                                                                                        <span>
                                                                                            <span style={{ textDecoration: 'line-through', color: 'red' }}>
                                                                                                {formatPrice(_course.course_price) + this.state.currency}
                                                                                            </span><br />
                                                                                            <span>
                                                                                                {formatPrice(_course.course_discount_price) + this.state.currency}
                                                                                            </span>
                                                                                        </span>
                                                                                        :

                                                                                        <span>
                                                                                            {(_course.course_price ? formatPrice(_course.course_price) : '0') + this.state.currency}
                                                                                        </span>
                                                                                    }
                                                                                </Button>
                                                                            </ButtonGroup>

                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            )

                                                            :

                                                            null
                                                    }

                                                </Row>

                                            )}
                                        {/* </Row> */}

                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Footer />
            </div>
            // </DocumentMeta>
        );
    }
}

CoursesComponent.propTypes = {
    getAllCourses: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    coursesData: state.webRed.coursesData
})

export default connect(mapStateToProps, { getAllCourses })(CoursesComponent);
