import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./../../../public/scss/snippets/CareerPageSnippetComponent.scss";

import {
  Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

class CareerPageSnippetComponent extends Component {
  
  constructor(props) {
    super(props);

    console.log(props)

    this.state = {
        heading: props.propData.heading,
        para: props.propData.para,
        btn_text: props.propData.btn_text,
        btn_text_url: props.propData.btn_text_url,
        image_url: props.propData.image_url,
        background: props.propData.background,
        theme: props.propData.themeColor,
        position: props.propData.position
    };
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }

  render() {
    return (
      <div className={"CareerPageSnippetComponent " + this.state.theme} style={{background: 'url(' + this.state.image_url + ')'+ this.state.background +' bottom center / cover'}} >

        <Container>
                {
                    <Row>
                        <Col style={{textAlign:'center', padding: this.state.theme !== 'white' ? '12vh 15px' : ''}} md={{ size: this.state.position == 'full' ? 12 : 7 }}>
                            
                            <h1 className="BannerWithText_heading" dangerouslySetInnerHTML={{__html: this.state.heading}}></h1>
                            
                            {
                            this.state.para && this.state.para != '' ?
                                <p className="BannerWithText_paragraph" dangerouslySetInnerHTML={{__html: this.state.para}}></p>
                            : null
                            }
                            
                            <a href={this.state.btn_text_url} target="_blank">
                            {
                                this.state.btn_text || this.state.btn_text !== '' ?
                                <Button className="BannerWithText_btn" size="lg" color="primary">{this.state.btn_text}</Button>
                                :
                                null
                            }
                            </a>

                        </Col>

                    </Row>
                    
                }
                
        </Container>

      </div>
    );
  }
}

export default CareerPageSnippetComponent;
