import React, { Component } from "react";
import "../../public/scss/snippets/EnrollmentComponent.scss";

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import store from './../../store';
import PropTypes from 'prop-types';
import { getPhoneUsersList, doRegisterWithPhone, doLogin } from '../../actions/webRedActions';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { toast } from 'react-toastify';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup
} from "reactstrap";

import PhoneNumber from './PhoneNumber';
import PhoneNumberVerificationCode from './PhoneNumberVerificationCode';
import UsernamesListings from './UsernamesListings';
import UsernameField from './UsernameField';
import UserPassword from './UserPassword';
import UserDetails from './UserDetails';
import UserEmailAddress from './UserEmailAddress';


import giraffeImage from '././../../public/images/login/giraffe.png';
import giraffeImageAngry from '././../../public/images/login/giraffe_angry.png';
import { isMobileBrowser, reserveBackOnMobile, getGradeId } from "../../actions/utility";

class EnrollmentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup : false,
            enrollStep : 1,
            givenPhoneNumber : "",
            isNewUser : false,
            isChild : false,
            registeredUsers : [],
            sessionId : '',
            selectedCourse : '',
            newUserName : '',
            userPassword : '',
            userDetails : '',
            userEmail : '',
            verifyScreenRandomProp : "",

            
            isLoginForm : true,
            signupFormType : 'normal',
            isCredentialActive : 0,
            extraFields : [],
            // signupFormData : {}
        };
    }

    componentDidMount() {
        

    }

    componentWillReceiveProps(nextProps, thisProps) {
        
        if (this.props.showPopup != nextProps.showPopup) {
            this.setState({
                showPopup: nextProps.showPopup != false ? true : nextProps.showPopup
            }, () => {
                
                var selected = store.getState().webRed.courseDetail;
                if(selected){
                    this.setState({
                        selectedCourse : selected.course
                    }, () => {
                        console.log(this.state.selectedCourse);
                    })
                }

                this.setState({
                    enrollStep : 1
                }, () => {
                    // if(isMobileBrowser()) {
                        reserveBackOnMobile(true);
                    // }
                })

            });
        }
        // else{
        //     alert('allow')
        //     this.setState({
        //         showPopup: false
        //     })
        // }

        if(nextProps.phoneNumberSentRes && this.props.phoneNumberSentRes != nextProps.phoneNumberSentRes){
            if(nextProps.phoneNumberSentRes.data.data.status == "success") {
                if(nextProps.phoneNumberSentRes.data.data.result && nextProps.phoneNumberSentRes.data.data.result !== undefined) {
                    this.setState({
                        givenPhoneNumber : nextProps.phoneNumberSentRes.phone
                    }, () => {
                        toast("We have texted you a verification code to your phone number: " + this.state.givenPhoneNumber);
                        this.changeFormStep(2); // ask for verification code
                    })
                }
            }
            else if(nextProps.phoneNumberSentRes.data.data.status == "error") {
                var message = nextProps.phoneNumberSentRes.data.data.message;
                
                toast.error(message.replace('[HTTP 400]', ''));
            }
        }

        if(nextProps.phoneNumberVerificationRes && this.props.phoneNumberVerificationRes != nextProps.phoneNumberVerificationRes){
            if(nextProps.phoneNumberVerificationRes.data.data.status == "success") {
                if(nextProps.phoneNumberVerificationRes.data.data.result && nextProps.phoneNumberVerificationRes.data.data.result == 1) {

                    this.props.getPhoneUsersList(this.state.givenPhoneNumber); //get the list of users registered against this phone number
                
                } else {
                    toast.error("Verification code is not valid");
                }
            }
        }


        if(nextProps.registeredUsersPhoneList && this.props.registeredUsersPhoneList != nextProps.registeredUsersPhoneList){
            if(nextProps.registeredUsersPhoneList.data.data.status == "success") {
                if(nextProps.registeredUsersPhoneList.data.data.child_accounts && nextProps.registeredUsersPhoneList.data.data.child_accounts.length > 0) {

                    this.setState({
                        isNewUser : false,
                        registeredUsers : nextProps.registeredUsersPhoneList.data.data.child_accounts,
                        sessionId : nextProps.registeredUsersPhoneList.data.data.session_id
                    }, () => {
                        this.changeFormStep(3);
                    })

                } else {
                    
                    this.setState({
                        isNewUser : true,
                        registeredUsers : [],
                        sessionId : nextProps.registeredUsersPhoneList.data.data.session_id
                    }, () => {
                        this.changeFormStep(3);
                    })

                }
            }
        }

        
        if(nextProps.newUserType && this.props.newUserType != nextProps.newUserType){
            if(nextProps.newUserType.isChild && nextProps.newUserType.isChild == 1) {
                this.setState({
                    isChild : true
                }, () => {
                    this.changeFormStep(4);
                })
            } else {
                this.setState({
                    isChild : false
                }, () => {
                    this.changeFormStep(4);
                })
            }
        }

        if(nextProps.isUserNameExists && this.props.isUserNameExists != nextProps.isUserNameExists){
            if(nextProps.isUserNameExists.data.data.status == "success") {
                if(nextProps.isUserNameExists.data.data.result && nextProps.isUserNameExists.data.data.result == '0') {
                    
                    this.setState({
                        newUserName : nextProps.isUserNameExists.username
                    }, () => {
                        this.changeFormStep(5);
                    })

                }
                else if(nextProps.isUserNameExists.data.data.result && nextProps.isUserNameExists.data.data.result == '1') {
                    
                    toast.warning("Username already in use, Please enter another one.");
                }
            }
        }

        if(nextProps.newUserPassword && this.props.newUserPassword != nextProps.newUserPassword){
            if(nextProps.newUserPassword.password) {
                this.setState({
                    userPassword : nextProps.newUserPassword.password
                }, () => {
                    this.changeFormStep(6);
                })
            }
        }

        if(nextProps.newUserDetails && this.props.newUserDetails != nextProps.newUserDetails) {
            if(nextProps.newUserDetails.userDetails) {
                this.setState({
                    userDetails : nextProps.newUserDetails.userDetails
                }, () => {

                    if(!this.state.isNewUser) {
                        var email_already = this.state.registeredUsers[0].user_email;
                        this.setState({
                            userEmail : email_already ? email_already : ""
                        }, () => {
                            this.doRegistrationNow();
                        })
                    } else {
                        this.changeFormStep(7);
                    }

                })
            }
        }

        if(nextProps.newUserEmail && this.props.newUserEmail != nextProps.newUserEmail) {
            if(nextProps.newUserEmail.email) {
                this.setState({
                    userEmail : nextProps.newUserEmail.email
                }, () => {
                    this.doRegistrationNow();
                })
            }
        }

        if(nextProps.signupSuccessWithPhone && this.props.signupSuccessWithPhone != nextProps.signupSuccessWithPhone) {
            if(nextProps.signupSuccessWithPhone.data.data.result && nextProps.signupSuccessWithPhone.data.data.result == '1') {

                
                var loginType = 'course';
                var courseId = this.state.selectedCourse.id;
                var isPurchase = 1;
                var isPassRequired = 1;

                this.props.doLogin(this.state.newUserName, this.state.userPassword, courseId, isPurchase, loginType, isPassRequired); // calling the action to login in now

                // this.changeFormStep(8); // proceed to checkout now

            } else if(nextProps.signupSuccessWithPhone == 'error') {
                toast.warning("There's some issue in registration, please try again.");
            }
        }

        // for course login response
        if(this.props.loginSuccessWithCourse != nextProps.loginSuccessWithCourse) {

            if (typeof nextProps.loginSuccessWithCourse === 'object' && (nextProps.loginSuccessWithCourse.message && nextProps.loginSuccessWithCourse.message.indexOf('password is incorrect') > 0)) {
                // showing here the incorrect credentials msg            
                toast.warning(nextProps.loginSuccessWithCourse.message);
            }
            else if (typeof nextProps.loginSuccessWithCourse === 'object' && nextProps.loginSuccessWithCourse.status == 'success') {
                console.log('login has been succcessfull');
                try {
                    localStorage.setItem('loginData', JSON.stringify(nextProps.loginSuccessWithCourse.result));
                }
                catch (err) {
                    console.log('loginData could not store:', err);
                }
            }
        }

    }

    doRegistrationNow = () => {
                    
        const { enrollStep, givenPhoneNumber, isNewUser, registeredUsers, sessionId, selectedCourse, isChild, newUserName, userPassword, userDetails, userEmail } = this.state;
                    
        var data = {
            session_id : sessionId,
            user_name : newUserName,
            user_password : userPassword,
            user_fname : userDetails.firstname,
            user_lname : userDetails.lastname,
            user_gender : userDetails.gender,
            user_dob : userDetails.birthDate,
            user_email : userEmail,
            prog_id : selectedCourse.program_id,
            user_course : selectedCourse.course_name,
            courseid : selectedCourse.id,
            purchase : 1,
            clientUrl : window.location.host,
            clientUrlProtocol : window.location.protocol
        }

        this.props.doRegisterWithPhone(data);

    }


    toggleLoginModal = () => {

        confirmAlert({
            title: 'Are you sure ?',
            message: "You don't want to enroll in "+ this.state.selectedCourse.course_name,
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [{
                    label: "I don't want to enroll",
                    onClick: () => {
                        
                        this.setState(prevState => ({
                            showPopup: !prevState.showPopup
                        }));

                        // if(isMobileBrowser()) {
                            reserveBackOnMobile(false);
                        // }
                    }
                }, {
                    label: 'Go back',
                    onClick: () => {
                        console.log('not.');
                    }
                }]
        })

    }

    changeFormStep = (index) => {
        this.setState({
            enrollStep: index
        }, () => {
            if(this.state.enrollStep == 2) {
                
                this.setState({
                    verifyScreenRandomProp : Math.random()
                })
            }
        });
    }

    render() {

        const { enrollStep, givenPhoneNumber, isNewUser, registeredUsers, selectedCourse, isChild, newUserName, userPassword, verifyScreenRandomProp } = this.state;

        return (
            <div className="EnrollmentComponent">

                <Modal isOpen={this.state.showPopup} centered={true} size="sm" className={"loginPopupMain enrollmentModal"}>
                    <ModalBody style={{padding: 0}}>
                        <div>
                            <Container style={{padding: 0}}>
                                {
                                    enrollStep !== 2 ?
                                        <p className="closeModalLogin" onClick={this.toggleLoginModal}>X</p>
                                    : null
                                }
                                {
                                    enrollStep > 1 && enrollStep != 2 ?
                                        <div className="backButtonModal"
                                            onClick={(e) => this.changeFormStep(enrollStep - 1)} 
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowLeft} size="2x" color="#fff" />
                                        </div>
                                    : null
                                }
                                <Row>
                                    <Col md={{size: 12, offset: 0}}>
                                        {/* <h3 className="learnHeading" style={{margin: '1vh 0 2vh 0'}}>Enroll</h3> */}
                                        
                                        {
                                            enrollStep == 1 ?
                                                <PhoneNumber />
                                            : null
                                        }
                                        
                                        {
                                            enrollStep == 2 ?
                                                <PhoneNumberVerificationCode phonenumber={givenPhoneNumber} verifyScreenRandomProp={verifyScreenRandomProp} />
                                            : null
                                        }
                                        
                                        {
                                            enrollStep == 3 ?
                                                <UsernamesListings phonenumber={givenPhoneNumber} newuser={isNewUser} registeredUsers={registeredUsers} selectedCourse={selectedCourse} />
                                            : null
                                        }

                                        {
                                            enrollStep == 4 ?
                                                <UsernameField isChild={isChild} />
                                            : null
                                        }

                                        {
                                            enrollStep == 5 ?
                                                <UserPassword newUserName={newUserName} />
                                            : null
                                        }

                                        {
                                            enrollStep == 6 ?
                                                <UserDetails newUserName={newUserName} />
                                            : null
                                        }

                                        {
                                            enrollStep == 7 && isNewUser ?
                                                <UserEmailAddress newUserName={newUserName} selectedCourse={selectedCourse} />
                                            : null
                                        }

                                        
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }

}

EnrollmentComponent.propTypes = {
    getPhoneUsersList: PropTypes.func.isRequired,
    doRegisterWithPhone: PropTypes.func.isRequired,
    doLogin: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    phoneNumberSentRes: state.webRed.phoneNumberSentRes,
    phoneNumberVerificationRes: state.webRed.phoneNumberVerificationRes,
    registeredUsersPhoneList : state.webRed.registeredUsersPhoneList,
    newUserType : state.webRed.newUserType,
    isUserNameExists : state.webRed.isUserNameExists,
    newUserPassword : state.webRed.newUserPassword,
    newUserDetails : state.webRed.newUserDetails,
    newUserEmail : state.webRed.newUserEmail,
    signupSuccessWithPhone : state.webRed.signupSuccessWithPhone,
    
    loginSuccessWithCourse: state.webRed.loginSuccessWithCourse,
    // extraSignUpFieldsCourse : state.webRed.extraSignUpFieldsCourse
    // SignUpFormType : state.webRed.SignUpFormType
})

export default connect(mapStatesToProps, { getPhoneUsersList, doRegisterWithPhone, doLogin })(EnrollmentComponent);