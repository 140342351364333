import React, { Component } from "react";
import store from './../../store';
import PropTypes from 'prop-types';
import { updateUserPassword } from './../../actions/webRedActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import SelectSearch from 'react-select-search';

import countryData from './../../actions/countryData';

import { connect } from 'react-redux';

import { isMobileBrowser, validatePhone } from './../../actions/utility';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    CustomInput,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    Label
  } from "reactstrap";

let additionalFields = []; // extrafields array global\

class UserPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password : "",
            c_password : "",
            passwordError : false
        };
        

    }
    
    componentDidMount() {

    }
    
    componentWillReceiveProps(nextProps, thisProps) {

        if(nextProps.isUserNameExists && this.props.isUserNameExists != nextProps.isUserNameExists){
            if(nextProps.isUserNameExists.data.data.status == "success") {
                if(nextProps.isUserNameExists.data.data.result && nextProps.isUserNameExists.data.data.result == '1') {

                    this.setState({
                        usernameError: 'This username is already in use.'
                    })

                } else {

                    this.setState({
                        usernameError : false
                    })

                }
            }
        }
        
    }

    validateError = () => {
        const {password, c_password} = this.state;

        if(password.length <= 3) {
            this.setState({
                passwordError : 'Password should have more than 4 digits'
            })
        }
        else if(password.length && c_password.length && password != c_password) {
            this.setState({
                passwordError : 'Both passwords should be same.'
            })
        } else {
            this.setState({
                passwordError: false
            })
        }
    }
    
    render() {

        const {newUserName} = this.props;
        const {password, c_password, passwordError} = this.state;

        return (
            <div style={{padding: '1vh 0'}} className="UserPassword" style={{
                background: 'f4eb49',
                color: '75a9f9'
            }}>
                <div className="wrapper"></div>
                
                <Container>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h3 style={{margin: "75px  0"}}>
                                Create a password for {newUserName}
                            </h3>
                        </Col>
                    </Row>
                    
                    <Row className="phoneStyle">
                        <Col xs={{size : 12}}>
                            <FormGroup style={{position: 'relative'}}>
                                <label for="password">Type in password of your choice</label>
                                <Input type="password" name="password" id="password" value={password}
                                    placeholder="password"
                                    onChange={(e) => {
                                        this.setState({
                                            password : e.target.value
                                        }, () => {
                                            this.validateError();
                                        })
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        color: '#007bff',
                                        fontSize: '1.5rem',
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                />

                            </FormGroup>

                            <FormGroup style={{position: 'relative'}}>
                                <label for="c_password">Re-type the password</label>
                                <Input type="password" name="c_password" id="c_password" value={c_password}
                                    placeholder="re-type password"
                                    onChange={(e) => {
                                        this.setState({
                                            c_password : e.target.value
                                        }, () => {
                                            this.validateError();
                                        })
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        color: '#007bff',
                                        fontSize: '1.5rem',
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                />
                                
                            </FormGroup>

                        </Col>
                        
                        <Col md={{size: 12}}>

                            {
                                passwordError ?
                                    <p style={{color: 'red', textAlign : 'center'}}>{passwordError}</p>
                                : null
                            }

                            {
                                !passwordError && password.length > 3 && c_password.length > 3 && password == c_password ?
                                    
                                    <Button 
                                        className="nextButtonNew"
                                        style={{ margin: '35px auto' }}
                                        onClick={(e) => {
                                            this.props.updateUserPassword(password);
                                        }}
                                        size="lg" color="secondary" block>
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} size="2x" color="#fff" />
                                    </Button>

                                : null
                            }

                        </Col>

                        
                    </Row>

                </Container>

            </div>
        );
    }
}

UserPassword.propTypes = {
    updateUserPassword: PropTypes.func.isRequired,
    // sendVerificationCode: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // isUserNameExists : state.webRed.isUserNameExists
    // validatePhoneExistsRes : state.webRed.validatePhoneExistsRes
    // extraSignUpFields:state.webRed.extraSignUpFields
})

export default connect(mapStatesToProps,{ updateUserPassword })(UserPassword);