import React, { Component } from "react";
import "./../../../public/scss/CareersPage.scss";
import Header from './../Header';
// import Footer from "./../Footer";

import Countdown from 'react-countdown';

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

// import CareerPageSnippetComponent from '../snippets/CareerPageSnippetComponent';

import thanks1 from './../../../public/images/careers/thanks1.png';
import thanks2 from './../../../public/images/careers/thanks2.png';
import thanks3 from './../../../public/images/careers/thanks3.png';
import thanks4 from './../../../public/images/careers/thanks4.png';
import thanks5 from './../../../public/images/careers/thanks5.png';
// import thanks6 from './../../../public/images/careers/thanks6.png';
// import thanks7 from './../../../public/images/careers/thanks7.png';


class CareersThanksForApplyingComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showCountDown : true
        }
    }

    componentDidMount() {
        document.title = "Thanks for Applying - Lincoln Method";
    }
    
    render() {
        
        // Random component
        const Completionist = () => <span>Hiring process is ended now.</span>;
        
        // Renderer callback with condition
        const renderer = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {
            if (completed) {
                // Render a completed state
                return <Completionist />;
            } else {
                // Render a countdown
                return <Row className="countDownBlock">
                            <Col xs={{size: 3}}>
                                <h2>{days}</h2>
                                <span>Days</span>
                            </Col>
                            <Col xs={{size: 3}}>
                                <h2>{hours}</h2>
                                <span>Hours</span>
                            </Col>
                            <Col xs={{size: 3}}>
                                <h2>{minutes}</h2>
                                <span>Minutes</span>
                            </Col>
                            <Col xs={{size: 3}}>
                                <h2>{seconds}</h2>
                                <span>Seconds</span>
                            </Col>
                        </Row>;
            }
        };

        return (
        // <DocumentMeta {...meta}>
            <div className="CareersThanksForApplyingComponent">
                {/* <Header /> */}


                <div className="homeEdtech padding-20" style={{ backgroundColor: '#007bff', padding: "6vh 0"}} >
                    <Container>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} style={{textAlign:'center'}}>
                                
                                <a href="https://lincolnmethod.s3.amazonaws.com/What+we+need+from+Tutors+during+the+interview+phase.pdf" target="_blank">
                                    <Button className="glow-on-hover">
                                        Download the PDF to see instructions for next steps
                                    </Button>
                                </a>

                            </Col>
                        </Row>
                    </Container>
                </div>

                <Media object src={thanks1} alt="thanks1" />
                

                <Media object src={thanks2} alt="thanks2" />
                <Media object src={thanks3} alt="thanks3" />
                <Media object src={thanks4} alt="thanks4" />
                <Media object src={thanks5} alt="thanks5" />
                {/* <Media object src={thanks6} alt="thanks6" />
                <Media object src={thanks7} alt="thanks7" /> */}
                {/* <Footer /> */}

                <div className="homeEdtech padding-20" style={{ backgroundColor: '#007bff', padding: "10vh 0 0 0"}} >
                    <Container>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} style={{textAlign:'center'}}>
                                
                                <a href="https://lincolnmethod.s3.amazonaws.com/What+we+need+from+Tutors+during+the+interview+phase.pdf" target="_blank">
                                    <Button className="glow-on-hover">
                                        Download the PDF to see instructions for next steps
                                    </Button>
                                </a>

                            </Col>
                        </Row>
                    </Container>
                </div>
                
                {
                this.state.showCountDown ?
                <div className="homeEdtech padding-20" style={{ backgroundColor: '#007bff', padding: "7vh 0"}} >
                    <Container>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} style={{textAlign:'center'}}>
                                
                                <h3 style={{padding: '5vh 0', color: '#fff'}}>We will stop accepting videos  submission after<br/><strong>14 June 2020</strong></h3>

                                <Countdown
                                    date={1591271156982 + (10*86400*1000)}
                                    renderer={renderer}
                                    daysInHours={true}
                                />

                            </Col>
                        </Row>
                    </Container>
                </div>
                : null
                }

            </div>
        // </DocumentMeta>
        );
    }
}

export default CareersThanksForApplyingComponent;