import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./../../../public/scss/snippets/TextWithImage.scss";

import {
  Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

class TextWithImage extends Component {
  
  constructor(props) {
    super(props);

    console.log(props)

    this.state = {
        heading: props.propData.heading,
        para: props.propData.para,
        btn_text: props.propData.btn_text,
        btn_text_url: props.propData.btn_text_url,
        image_url: props.propData.image_url,
        theme: props.propData.themeColor,
        position: props.propData.position
    };
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }

  render() {
    return (
      <div className={"TextWithImage " + this.state.theme} >

        <Container>
                {
                    this.state.position == 'ltr' ?
                    <Row>
                        <Col style={{textAlign:'left', padding: '12vh 0'}} md={{ size: this.state.image_url ? 6 : 12  }}>
                        
                            <h1 className="BannerWithText_heading" dangerouslySetInnerHTML={{__html: this.state.heading}}></h1>
                            {
                            this.state.para && this.state.para != '' ?
                                <p className="BannerWithText_paragraph" dangerouslySetInnerHTML={{__html: this.state.para}}></p>
                            : null
                            }
                            <a href={this.state.btn_text_url}>
                            {
                                this.state.btn_text || this.state.btn_text !== '' ?
                                <Button className="BannerWithText_btn" size="lg" color="primary">{this.state.btn_text}</Button>
                                :
                                null
                            }
                            </a>

                        </Col>
                        <Col md={{ size: 6 }}>
                            {
                                this.state.image_url ?
                                <Media object src={this.state.image_url} alt="Generic placeholder image" /> : null
                            }

                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col md={{ size: 6 }}>
                            {
                                this.state.image_url ?
                                <Media object src={this.state.image_url} alt="Generic placeholder image" /> : null
                            }

                        </Col>
                        <Col style={{textAlign:'left', padding: '12vh 0'}} md={{ size: this.state.image_url ? 6 : 12  }}>
                        
                            <h1 className="BannerWithText_heading" dangerouslySetInnerHTML={{__html: this.state.heading}}></h1>
                            {
                            this.state.para && this.state.para != '' ?
                                <p className="BannerWithText_paragraph" dangerouslySetInnerHTML={{__html: this.state.para}}></p>
                            : null
                            }
                            <a href={this.state.btn_text_url}>
                            {
                                this.state.btn_text || this.state.btn_text !== '' ?
                                <Button className="BannerWithText_btn" size="lg" color="primary">{this.state.btn_text}</Button>
                                :
                                null
                            }
                            </a>

                        </Col>
                    </Row>
                }
                
        </Container>

        {
            this.state.video_url && this.state.video_url !== '' ?
        
            <video className="slider-video" width="100%" preload="auto" loop={true} autoPlay={true}>
                {/* <source src={this.state.video_url} type="video/webm" /> */}
                <source type="video/mp4" src={this.state.video_url} />
            </video>
        
            : null

        }

      </div>
    );
  }
}

export default TextWithImage;
