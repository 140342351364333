import React, { Component } from "react";
import Header from './Header';
import Footer from "./Footer";

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

    
import BG2 from './../../public/images/BG2.jpg';
import sdg from './../../public/images/sdg.png';
import kidPicture from './../../public/images/kidPicture.png';

class InvestWithUsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        document.title = "How to invest with us - Lincoln Method";
    }
    
    render() {

        return (
            <div className="InvestWithUsComponent">

                <Header />

                <div className="partnershipContent" style={{padding: '10vh 0 0 0', background: "url("+ BG2 +")"}}>
                    <Container style={{textAlign: 'left'}}>
                        <Row>
                            <Col md={{ size: 8 }}>
                                <h1 style={{color: '#fff'}}>We are impact entrepreneurs, and we generate purpose-driven profits</h1>
                                <h2 style={{color: '#fff', padding: '15vh 0 5vh 0'}}>Lincoln Method supports SDGs</h2>
                                <Media style={{maxWidth: '100%'}} object src={sdg} />
                            </Col>
                            <Col md={{size: 4}}>
                                <Media style={{maxWidth: '100%'}} object src={kidPicture} />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="partnershipContent" style={{padding: '10vh 0'}}>
                    <Container style={{textAlign: 'left'}}>
                        <Row>
                            <Col md={{ size: 12 }}>
                                <p>According to <a href="https://www.forbes.com/sites/robynshulman/2017/05/17/global-edtech-investments-and-outlook-10-edtech-companies-you-should-know-about/#c0371615bb30">Forbes</a>, David Bainbridge forecasts that the EdTech industry will reach a global value of $252 billion by the year 2020.</p>
                                <p>
                                According to <a href="https://techcrunch.com/2018/01/19/education-technology-is-a-global-opportunity/">Tech Crunch</a>, IBIS Capital projects that after-school tutoring in China will grow from $50 billion to $90 billion by 2020.</p>
<p>Furthermore the EdTech industry in China, on the whole, is forecast to grow at a rate of nearly 20% annually. To foster this growth, global investors put over $8 billion into the industry over the first 10 months of 2017. Investments into Chinese EdTech companies reached $1 billion for the first time in 2017.</p>

<p>While in Singapore, parents spend more than $70,000 per year on the education of their children. That is nearly twice the global average.</p>

<p>According to <a href="https://www.edsurge.com/news/2018-07-16-2018-halftime-ka-ching-report-u-s-edtech-raises-739m-in-venture-funding">EdSurge</a>, in the first six months of 2018, 62 companies raised $739 million in venture capital.</p>

<p>According to <a href="https://www.technavio.com/report/north-america-education-technology-education-technology-market">Technavio</a>, the EdTech market in North America is expected to continue growing rapidly, with a 9% CAGR forecast through 2019.</p>

<p>According to <a href="https://www.businesswire.com/news/home/20180111006109/en/Growth-Opportunities-Global-Education-Technology-Market-2017">Business Wire</a>, the global EdTech market globally reached nearly $18 billion in revenues by 2017. Forecasts through 2022 have the CAGR reaching 18.3% for the industry.</p>

<p><a href="https://www.austrade.gov.au/ArticleDocuments/5085/Edtech-US-Market-Snapshot.pdf.aspx">Australian Trade and Investment Commission</a> reported that the educational apps market in the United States was valued at $2.5 billion in 2016.</p>

<p>It further mentioned that the U.S. portion of the EdTech market was valued at $33 billion in 2016, and then forecast to reach $43 billion by 2019.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>                
                
                <Footer />
            </div>
        );
    }
}

export default InvestWithUsComponent;