import React, { Component } from "react";
import store from './../../store';
import PropTypes from 'prop-types';
import { doneWithUserEmail } from './../../actions/webRedActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import SelectSearch from 'react-select-search';

import countryData from './../../actions/countryData';

import { connect } from 'react-redux';

import { validateEmailAddress } from './../../actions/utility';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    CustomInput,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    Label
  } from "reactstrap";

class UserEmailAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            c_email: '',

            emailError : false,
            
            errors: {
                email: '',
                c_email: ''
            }
        };
        

    }
    
    componentDidMount() {

    }
    
    componentWillReceiveProps(nextProps, thisProps) {
                
    }
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
      
        switch (name) {
            
          case 'email': 
          errors.email = 
              value.length < 1
                  ? 'Email address is required' :
                  !validateEmailAddress(value) ?
                      'Email address is not valid'
                      : '';
          break;
          
          case 'c_email': 
          errors.c_email = 
              value.length < 1
                  ? 'Email address is required' :
                  !validateEmailAddress(value) ?
                      'Email address is not valid' :
                      this.state.email !== value ?
                      'Both email ids should be same'
                      : '';
          break;
          
          default:
            break;
        }
      
        this.setState({errors, [name]: value}, () => {
            // console.log(errors)
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        // alert('asdsad')
        if(this.validateForm(this.state.errors)) {
            
            // hence, form is validated. now call the action to submit
            this.setState({
                emailError : false
            }, () => {
                
                this.props.doneWithUserEmail(this.state.email);
            })

        }else{
            console.error('Invalid Form');
            this.setState({
                emailError : true
                // formSuccess : false
            }, () => {
                console.log(this.state.emailError)
            })
        }
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
    
    render() {

        const {newUserName, selectedCourse} = this.props;
        const {email, c_email, errors, emailError} = this.state;

        const errorStyle = {
            color: '#fff', fontSize: '12px', textAlign: 'right', paddingRight: '10px'
        };

        return (
            <div style={{padding: '1vh 0'}} className="UserEmailAddress">
                <div className="wrapper"></div>
                
                <Container>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h3 style={{marginTop: '40px'}}>
                                Where should we send you an <strong>email</strong> about
                            </h3>
                            <p style={{margin: '15px 0'}}>{selectedCourse.course_name}</p>
                        </Col>
                    </Row>
                    
                    <Row className="phoneStyle" style={{paddingTop: '40px'}}>
                        <Col xs={{size : 12}}>

                            <FormGroup style={{position: 'relative'}}>
                                <label>Type in your email address</label>
                                <Input type="text" name="email" id="email" value={email}
                                    onChange={evt => this.handleChange(evt)}
                                    style={{
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                />
                                <p style={errorStyle}>{errors.email}</p>
                            </FormGroup>

                            <FormGroup style={{position: 'relative'}}>
                                <label>Retype your email (just to be extra sure)</label>
                                <Input type="text" name="c_email" id="c_email" value={c_email}
                                    onChange={evt => this.handleChange(evt)}
                                    style={{
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                />
                                <p style={errorStyle}>{errors.c_email}</p>
                            </FormGroup>

                        </Col>
                        
                        <Col md={{size: 12}}>

                            {
                                emailError ?
                                    <p style={{color: '#fff', textAlign : 'center'}}>All fields are required.</p>
                                : null
                            }

                            {
                                email.length && c_email.length ?

                                    <Button 
                                        className="nextButtonNew"
                                        style={{ margin: '35px auto' }}
                                        onClick={(e) => {
                                            this.handleSubmit(e);
                                        }}
                                        size="lg" color="secondary" block>
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} size="2x" color="#fff" />
                                    </Button>

                                : null                                
                            }

                                
                        </Col>
                        
                    </Row>

                </Container>

            </div>
        );
    }
}

UserEmailAddress.propTypes = {
    doneWithUserEmail: PropTypes.func.isRequired,
    // sendVerificationCode: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // isUserNameExists : state.webRed.isUserNameExists
    // validatePhoneExistsRes : state.webRed.validatePhoneExistsRes
    // extraSignUpFields:state.webRed.extraSignUpFields
})

export default connect(mapStatesToProps,{ doneWithUserEmail })(UserEmailAddress);