import React, { Component } from "react";
import "./../../../public/scss/CareersPage.scss";
import Header from './../Header';
import Footer from "./../Footer";

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

import CareerPageSnippetComponent from '../snippets/CareerPageSnippetComponent';

import step1Image from './../../../public/images/careers/onlineteacher1.png';
import step2Image from './../../../public/images/careers/onlineteacher2.png';
import step3Image from './../../../public/images/careers/onlineteacher3.png';
import step4Image from './../../../public/images/careers/onlineteacher4.PNG';


class CareersOnlineTeacherComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step1 : {
                heading: "Do you want to teach online",
                para: "",
                btn_text: "Apply now",
                btn_text_url: "http://form.assessmentfund.com/ODcxMQ==",
                image_url: step1Image,
                background : '#ffc000',
                themeColor: "yellow",
                position: "full"
            },
            step2 : {
                heading: "Can you teach English or Math to kids from Grade 1 to 11",
                para: "",
                btn_text: "Apply now",
                btn_text_url: "http://form.assessmentfund.com/ODcxMQ==",
                image_url: "",
                background : '#ffc000',
                themeColor: "blue",
                position: "full"
            },
            step3 : {
                heading: "Teach more classes Earn more money",
                para: "",
                btn_text: "Apply now",
                btn_text_url: "http://form.assessmentfund.com/ODcxMQ==",
                image_url: step2Image,
                background : '#ffc000',
                themeColor: "yellow",
                position: "full"
            },
            step4 : {
                heading: "You will go through training & certification",
                para: "",
                btn_text: "Apply now",
                btn_text_url: "http://form.assessmentfund.com/ODcxMQ==",
                image_url: step3Image,
                background : '#ffc000',
                themeColor: "blue",
                position: "ltr"
            },
            step5 : {
                heading: "Here is what we look for when hiring people to teach online",
                para: "<ol><li>You need to know your subject matter really well</li><li>You need to be comfortable with using technology</li><li>You need to like kids, and know how to be patient with kids </li><li>You need to be extremely organized and punctual</li></ol>",
                btn_text: "",
                btn_text_url: "",
                image_url: step4Image,
                background : '#fafafa',
                themeColor: "white",
                position: "ltr"
            }
        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

    componentDidMount() {
        document.title = "Careers - Online school teacher - Lincoln Method";
    }
    
    render() {
        
        return (
        // <DocumentMeta {...meta}>
            <div className="CareersOnlineTeacherComponent">

                <Header />

                    <CareerPageSnippetComponent propData={this.state.step5} />
                    <CareerPageSnippetComponent propData={this.state.step1} />
                    <CareerPageSnippetComponent propData={this.state.step2} />
                    <CareerPageSnippetComponent propData={this.state.step3} />
                    <CareerPageSnippetComponent propData={this.state.step4} />
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default CareersOnlineTeacherComponent;