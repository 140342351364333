import React, { Component } from "react";
import "../../public/scss/WallOfFameComponent.scss";
import Header from './Header';
import Footer from "./Footer";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

import homeBanner from './../../public/images/homeBanner.png';

// import wallOfFame_1 from './../../public/images/walloffame/1.jpeg';
// import wallOfFame_2 from './../../public/images/walloffame/2.jpeg';
// import wallOfFame_3 from './../../public/images/walloffame/3.jpeg';
// import wallOfFame_4 from './../../public/images/walloffame/4.jpeg';
// import wallOfFame_5 from './../../public/images/walloffame/5.jpeg';
// import wallOfFame_6 from './../../public/images/walloffame/6.jpg';
// import wallOfFame_7 from './../../public/images/walloffame/7.jpg';
// import wallOfFame_8 from './../../public/images/walloffame/8.jpeg';
// import wallOfFame_9 from './../../public/images/walloffame/9.jpeg';
// import wallOfFame_10 from './../../public/images/walloffame/10.jpeg';
// import wallOfFame_11 from './../../public/images/walloffame/11.jpeg';
// import wallOfFame_12 from './../../public/images/walloffame/12.jpeg';
// import wallOfFame_13 from './../../public/images/walloffame/13.jpeg';
// import wallOfFame_14 from './../../public/images/walloffame/14.jpeg';
// import wallOfFame_15 from './../../public/images/walloffame/15.jpeg';
// import wallOfFame_16 from './../../public/images/walloffame/16.jpeg';
// import wallOfFame_17 from './../../public/images/walloffame/17.jpeg';
// import wallOfFame_18 from './../../public/images/walloffame/18.jpeg';
// import wallOfFame_19 from './../../public/images/walloffame/19.jpeg';
// import wallOfFame_20 from './../../public/images/walloffame/20.jpeg';
// import wallOfFame_21 from './../../public/images/walloffame/21.jpeg';
// import wallOfFame_22 from './../../public/images/walloffame/22.jpeg';
// import wallOfFame_23 from './../../public/images/walloffame/23.jpeg';
// import wallOfFame_24 from './../../public/images/walloffame/24.jpeg';
// import wallOfFame_25 from './../../public/images/walloffame/25.jpeg';
// import wallOfFame_26 from './../../public/images/walloffame/26.jpeg';
// import wallOfFame_27 from './../../public/images/walloffame/27.jpeg';
// import wallOfFame_28 from './../../public/images/walloffame/28.jpeg';
// import wallOfFame_29 from './../../public/images/walloffame/29.jpeg';
// import wallOfFame_30 from './../../public/images/walloffame/30.jpeg';
// import wallOfFame_31 from './../../public/images/walloffame/31.jpeg';
// import wallOfFame_32 from './../../public/images/walloffame/32.jpeg';
// import wallOfFame_33 from './../../public/images/walloffame/33.jpeg';
// import wallOfFame_34 from './../../public/images/walloffame/34.jpeg';
// import wallOfFame_35 from './../../public/images/walloffame/35.jpeg';
// import wallOfFame_36 from './../../public/images/walloffame/36.jpeg';
// import wallOfFame_37 from './../../public/images/walloffame/37.jpeg';
// import wallOfFame_38 from './../../public/images/walloffame/38.jpeg';
// import wallOfFame_39 from './../../public/images/walloffame/39.jpeg';
// import wallOfFame_40 from './../../public/images/walloffame/40.jpeg';
// import wallOfFame_41 from './../../public/images/walloffame/41.jpeg';
// import wallOfFame_42 from './../../public/images/walloffame/42.jpeg';
// import wallOfFame_43 from './../../public/images/walloffame/43.jpeg';
// import wallOfFame_44 from './../../public/images/walloffame/44.jpeg';
// import wallOfFame_45 from './../../public/images/walloffame/45.jpeg';
import wallOfFame_46 from './../../public/images/walloffame/46.jpeg';
import wallOfFame_47 from './../../public/images/walloffame/47.jpeg';
import wallOfFame_48 from './../../public/images/walloffame/48.jpeg';
import wallOfFame_49 from './../../public/images/walloffame/49.jpeg';
import wallOfFame_50 from './../../public/images/walloffame/50.jpeg';
import wallOfFame_51 from './../../public/images/walloffame/51.jpeg';
import wallOfFame_52 from './../../public/images/walloffame/52.jpeg';
import wallOfFame_53 from './../../public/images/walloffame/53.jpeg';
import wallOfFame_54 from './../../public/images/walloffame/54.jpeg';
import wallOfFame_55 from './../../public/images/walloffame/55.jpeg';
import wallOfFame_56 from './../../public/images/walloffame/56.jpeg';
// import wallOfFame_57 from './../../public/images/walloffame/57.jpeg';
// import wallOfFame_58 from './../../public/images/walloffame/58.jpeg';
// import wallOfFame_59 from './../../public/images/walloffame/59.jpeg';
// import wallOfFame_60 from './../../public/images/walloffame/60.jpeg';
// import wallOfFame_61 from './../../public/images/walloffame/61.jpeg';
// import wallOfFame_62 from './../../public/images/walloffame/62.jpeg';
// import wallOfFame_63 from './../../public/images/walloffame/63.jpeg';
// import wallOfFame_64 from './../../public/images/walloffame/64.jpeg';
// import wallOfFame_65 from './../../public/images/walloffame/65.jpeg';
// import wallOfFame_66 from './../../public/images/walloffame/66.jpeg';
// import wallOfFame_67 from './../../public/images/walloffame/67.jpeg';
// import wallOfFame_68 from './../../public/images/walloffame/68.jpeg';

class WallOfFameComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false
        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

    componentDidMount() {
        document.title = "Wall of Fame - Lincoln Method"
    }
    
    render() {
        
        const { photoIndex, isOpen } = this.state;
        const images = [
            // wallOfFame_1,
            // wallOfFame_2,
            // wallOfFame_3,
            // wallOfFame_4,
            // wallOfFame_5,
            // wallOfFame_6,
            // wallOfFame_7,
            // wallOfFame_8,
            // wallOfFame_9,
            // wallOfFame_10,
            // wallOfFame_11,
            // wallOfFame_12,
            // wallOfFame_13,
            // wallOfFame_14,
            // wallOfFame_15,
            // wallOfFame_16,
            // wallOfFame_17,
            // wallOfFame_18,
            // wallOfFame_19,
            // wallOfFame_20,
            // wallOfFame_21,
            // wallOfFame_22,
            // wallOfFame_23,
            // wallOfFame_24,
            // wallOfFame_25,
            // wallOfFame_26,
            // wallOfFame_27,
            // wallOfFame_28,
            // wallOfFame_29,
            // wallOfFame_30,
            // wallOfFame_31,
            // wallOfFame_32,
            // wallOfFame_33,
            // wallOfFame_34,
            // wallOfFame_35,
            // wallOfFame_36,
            // wallOfFame_37,
            // wallOfFame_38,
            // wallOfFame_39,
            // wallOfFame_40,
            // wallOfFame_41,
            // wallOfFame_42,
            // wallOfFame_43,
            // wallOfFame_44,
            // wallOfFame_45,
            wallOfFame_46,
            wallOfFame_47,
            wallOfFame_48,
            wallOfFame_49,
            wallOfFame_50,
            wallOfFame_51,
            wallOfFame_52,
            wallOfFame_53,
            wallOfFame_54,
            wallOfFame_55,
            wallOfFame_56,
            // wallOfFame_57,
            // wallOfFame_58,
            // wallOfFame_59,
            // wallOfFame_60,
            // wallOfFame_61,
            // wallOfFame_62,
            // wallOfFame_63,
            // wallOfFame_64,
            // wallOfFame_65,
            // wallOfFame_66,
            // wallOfFame_67,
            // wallOfFame_68
        ];

        // const meta = {
        //     title: 'Wall of Fame - Lincoln Method',
        //     description: 'Lincoln Method is a progressive way of learning, that focuses on addressing learner’s weak areas. The learning happens by supplementing what has already been taught.',
        //     canonical: 'https://lincolnmethod.com/#/wall-of-fame',
        //     meta: {
        //         charset: 'utf-8',
        //         name: {
        //             keywords: 'kids,wall,walloffame,focus,learner,weak,learning,education,skills'
        //         }
        //     }
        // };

        return (
        // <DocumentMeta {...meta}>
            <div className="WallOfFameComponent">

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                )}

                <Header />

                <div className="homeEdtech" style={{backgroundImage: 'url('+ homeBanner +')', backgroundColor: '#000', backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
                    <Container>
                        <Row>
                            <Col md={{ size: 12 }}>
                                <h2>Wall of fame</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div>

                    <Container style={{padding: '7vh 0'}}>
                        <Row>

                            {
                                images.map((imageUrl, key) =>
                                    <Col key={key} md={{ size: 3 }}>
                                        <Media style={{maxWidth: '100%', cursor: 'pointer', margin: '15px 0'}} onClick={() => this.openLighBox(key)} object src={imageUrl} />
                                    </Col>
                                )
                            }

                        </Row>
                    </Container>
                    
                </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default WallOfFameComponent;