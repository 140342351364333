import React from 'react';
import {
    Row,
    Col,
    Table,
    Card,
    Tab,
    Nav,
    Button,
    Form ,
    FormGroup,
    Collapse
} from 'react-bootstrap';

import Header from '../website/Header';
import Footer from '../website/Footer';
// import { doneMangoFinalPayment } from './../../actions/webRedActions';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { data } from 'jquery';

class failedCheckoutPayment extends React.Component {
    state = {
        // isPaymentSuccess : false,
        // checkoutCourse : ''
    };

    componentDidMount() {
        

    }
    
    componentWillReceiveProps = (nextProps, thisProps) => {
        
    };

    render() {

        return (
            <div className="checkoutpaymentFailed">
                <Header />
                    <div className="container" style={{padding: '50px 0'}}>
                        <Row className='btn-page'>
                            <Col md={{size: 12}} style={{
                                padding: '30vh 10vw'
                            }}>
                                <h2 style={{color: 'red'}}>
                                    Your payment is failed. Please verify your checkout details and try again.
                                </h2>
                                
                                <FormGroup>
                                    <Button onClick={() => {window.location = "/#/checkout"}} size="md" color="warning">Checkout</Button>
                                </FormGroup>
                            </Col>
                        
                        </Row>
                    </div>
                <Footer/>
            </div>
        );
    }
}
const mapStatesToProps = state => ({
    finalPaymentDonePayMongo: state.webRed.finalPaymentDonePayMongo
})
export default connect(mapStatesToProps,{  })(failedCheckoutPayment);