import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./../../../public/scss/snippets/IconGridSection.scss";

import {
//   Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

class IconGridSection extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        iconArray: props.iconsData
    };
  }

  componentDidMount(){
      console.log(this.state.iconArray);
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }

  render() {

    return (
      <div className="IconGridSection" style={{background: this.state.iconArray.background}}>
            <Container>
                <Row style={{marginBottom: '8vh'}}>
                    <Col md={{size: 12}}>
                        <h2 dangerouslySetInnerHTML={{__html: this.state.iconArray.heading}}></h2>
                    </Col>
                </Row>
                <Row>
                    {
                        this.state.iconArray.data.map((icon, key) =>
                            <Col key={key} md={{ size: 4 }}>
                                <Media className="iconHere" object src={icon.iconImage} alt={icon.iconLabel} />
                                <h3 style={{color: icon.iconColor}} className="icon_heading" dangerouslySetInnerHTML={{__html: icon.iconLabel}}></h3>
                            </Col>
                        )
                    }
                </Row>
            </Container>

      </div>
    );
  }
}

export default IconGridSection;
