import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css'; // importing bootstrap css for reactStrap

// if (window.location.protocol != 'https:')
// {
//     window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
// }
// else{
//     ReactDOM.render(<App />, document.getElementById('root'));
// }

    ReactDOM.render(<App />, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
