import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoHere from "./../../public/images/logo.png";
import "../../public/scss/Header.scss";


import store from './../../store';
import PropTypes from 'prop-types';
import { getAllCourses, getAllOnlineSchoolCourses, getAllClientsData, getExtraFieldsSignup, triggerLoginPopup, triggerCheckoutPopup } from './../../actions/webRedActions';
import { connect } from 'react-redux';
import { getBaseUrl, isMobileBrowser, logOut, updateLastLinkStorage } from './../../actions/utility';

import LoginComponent from './../login/LoginComponent'; // importing login component popup for general login / signup
import EnrollmentComponent from './../login/EnrollmentComponent'; // importing enrollment component popup for signup / enrollment in any course

import ForgotPasswordComponent from './../login/ForgotPasswordComponent'; // popup for forgot password
import CheckoutPaymentComponent from './../checkout/CheckoutPaymentComponent'; // importing checkout popup component

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
  // NavLink
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faUser } from '@fortawesome/free-solid-svg-icons';

import Confetti from 'react-confetti';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isLoggedIn: false,
      showLoginPopup: false,
      showEnrollmentPopup: false,
      showCheckoutPopup: false,
      showForgotPassword: false,
      loggedInUserName: '',
      signupAnimation: false
    };

    this.toggle = this.toggle.bind(this);
    this.openLoginModal2 = this.openLoginModal2.bind(this);
    this.openCheckoutModal = this.openCheckoutModal.bind(this);
    this.logout = this.logout.bind(this);

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  openLoginModal2() {
    // this.toggle(); // closes the menu
    localStorage.setItem('loginType', 'normal'); // set loginType normal to load login popup with normal forms
    try {
      var loginStatus = localStorage.getItem('loginData');
      if (loginStatus != null && typeof JSON.parse(loginStatus) === 'object') {
        this.setState({
          isLoggedIn: true
        })
      }
      else {
        this.props.triggerLoginPopup(); // triggers the login modal
      }
    }
    catch (err) {
      this.props.triggerLoginPopup(); // triggers the login modal
    }
  }

  openCheckoutModal() {
    this.setState({
      showCheckoutPopup: Math.random()
    })
  }

  componentWillReceiveProps(nextProps, thisProps) {

    console.log(nextProps)
    if (this.props.showLoginPopupRed != nextProps.showLoginPopupRed) { // gets and opens popup for login
      this.setState({
        showLoginPopup: nextProps.showLoginPopupRed
      })
    }

    if (this.props.showEnrollmentPopupRed != nextProps.showEnrollmentPopupRed) { // gets and opens popup for login
      this.setState({
        showEnrollmentPopup: nextProps.showEnrollmentPopupRed
      })
    }

    if (this.props.showForgotPasswordRed != nextProps.showForgotPasswordRed) { // gets and opens popup for forgot password
      this.setState({
        showForgotPassword: nextProps.showForgotPasswordRed
      })
    }

    if (this.props.showCheckoutPopupRed != nextProps.showCheckoutPopupRed) { // gets and opens popup for checkout
      this.setState({
        showCheckoutPopup: nextProps.showCheckoutPopupRed
      })
    }

    if (this.props.loginSuccessNormal != nextProps.loginSuccessNormal) {

      if (typeof nextProps.loginSuccessNormal === 'object' && nextProps.loginSuccessNormal.status == 'success') {
        this.setState({
          showLoginPopup: false,
          showEnrollmentPopup: false
        }, () => {
          console.log(nextProps.loginSuccessNormal)
          var loginData = localStorage.getItem('loginData');
          if (loginData && loginData != null && typeof JSON.parse(loginData) === 'object') {
            if (JSON.parse(loginData).user.user_type == 1) { // if it's admin user
              window.location = getBaseUrl() + "/programs/" + JSON.parse(loginData).user.id;
            }
            else {
              // window.location = getBaseUrl() + "/welcome/" + JSON.parse(loginData).user.id;
              window.location = window.location.protocol + "//" + window.location.host + '/#/courses';
            }
          }
        })
      }

    }

    if (this.props.loginSuccessWithCourse != nextProps.loginSuccessWithCourse) {

      if (typeof nextProps.loginSuccessWithCourse === 'object' && nextProps.loginSuccessWithCourse.status == 'success') {
        this.setState({
          showLoginPopup: false,
          showEnrollmentPopup: false
        }, () => {
          var loginData = localStorage.getItem('loginData');
          if (loginData && loginData != null && typeof JSON.parse(loginData) === 'object') {
            if (JSON.parse(loginData).user.user_type == 1) { // if it's admin user
              window.location = getBaseUrl() + "/programs/" + JSON.parse(loginData).user.id;
            }
            else {
              this.setLoggedInUser();

              if (localStorage.getItem('demoAfterLogin') && localStorage.getItem('demoAfterLogin') != null && localStorage.getItem('demoAfterLogin') != 'false') {
                var courseId = localStorage.getItem('demoAfterLogin');
                localStorage.setItem('demoAfterLogin', 'false');
                window.location = getBaseUrl() + "/takedemo/" + courseId + "/" + JSON.parse(loginData).user.id;
              }
              else {
                this.props.triggerCheckoutPopup(); // triggers the login modal
              }
              // this.props.triggerCheckoutPopup(); // triggers the login modal

              // this.setState({
              //   isLoggedIn : true
              // }, () => {
              //   this.props.triggerCheckoutPopup(); // triggers the login modal
              // })
            }
          }
        })
      }
    }

    // for social login success
    if (this.props.successData != nextProps.successData) {

      if (typeof nextProps.successData === 'object' && nextProps.successData.status == 'success' && typeof nextProps.successData.data == "undefined") {
        this.setState({
          showLoginPopup: false,
          showEnrollmentPopup: false
        }, () => {
          var loginData = localStorage.getItem('loginData');
          if (loginData && loginData != null && typeof JSON.parse(loginData) === 'object') {
            if (JSON.parse(loginData).user.user_type == 1) { // if it's admin user
              window.location = getBaseUrl() + "/programs/" + JSON.parse(loginData).user.id;
            }
            else {
              if (localStorage.getItem('loginType') == 'normal') {
                // window.location = getBaseUrl() + "/welcome/" + JSON.parse(loginData).user.id;
                // this.props.history.push('/courses');
                window.location = window.location.protocol + "//" + window.location.host + '/#/courses';
              }
              else {
                this.setLoggedInUser();

                if (localStorage.getItem('demoAfterLogin') && localStorage.getItem('demoAfterLogin') != null && localStorage.getItem('demoAfterLogin') != 'false') {
                  var courseId = localStorage.getItem('demoAfterLogin');
                  localStorage.setItem('demoAfterLogin', 'false');
                  window.location = getBaseUrl() + "/takedemo/" + courseId + "/" + JSON.parse(loginData).user.id;
                }
                else {
                  this.props.triggerCheckoutPopup(); // triggers the login modal
                }
                // this.props.triggerCheckoutPopup(); // triggers the login modal

                // this.setState({
                //   isLoggedIn : true
                // }, () => {
                //   this.props.triggerCheckoutPopup(); // triggers the login modal
                // })
              }
            }
          }
        })
      }
    }

    //for email verified success check and shows congratulations animations and sound
    if (this.props.showCongratulationsRed != nextProps.showCongratulationsRed) {
      this.setState({
        signupAnimation: true
      }, () => {
        setTimeout(() => {
          this.setState({
            signupAnimation: false
          })
        }, 7000);
      })
    }


  }

  logout() {
    if (logOut()) { // calls logout from utility
      this.setState({
        isLoggedIn: false
      })
    }
  }
  /**
   *load the login user profile
   */
  loadProfile() {
    try {
      var userId = JSON.parse(localStorage.getItem('loginData')).user.id;
      window.location = getBaseUrl() + '/userprofile/' + userId;
    }
    catch (error) {
      console.error('there error occured while redirected to user profile:', error);
    }
  }
  /**
   * load user's dashboard
   */
  loadDashboard() {
    try {
      var userId = JSON.parse(localStorage.getItem('loginData')).user.id;
      window.location = getBaseUrl() + '/home/' + userId;
    }
    catch (error) {
      console.error('there error occured while redirected to user profile:', error);
    }
  }
  componentDidMount() {

    // this.props.triggerCheckoutPopup(); // triggers the login modal

    if (!store.getState().webRed.coursesData.grades) { // check if data exists it'll not call action
      this.props.getAllCourses(); //getting data for all courses
    }

    if (!store.getState().webRed.coursesDataOnlineSchool.grades) { // check if data exists it'll not call action
      this.props.getAllOnlineSchoolCourses(); //getting data for all online school courses
    }

    if (!store.getState().webRed.allclientslist) { // check if data exists it'll not call action
      this.props.getAllClientsData(); //getting data for all clients
    }

    // checks if the user is logged in will not show login button in menu
    this.setLoggedInUser();

    //update history links in storage
    updateLastLinkStorage();

  }

  setLoggedInUser() {
    var loginStatus = localStorage.getItem('loginData');
    if (loginStatus && loginStatus != null && typeof JSON.parse(loginStatus) === 'object') {
      this.setState({
        isLoggedIn: true,
        loggedInUserName: JSON.parse(loginStatus).user.user_name
      })
    }
  }

  componentWillUnmount() {
    this.setState({
      signupAnimation: false
    })
  }

  render() {
    console.log(this.props.signUpPath);
    return (
      <div className="MainHeader">
        <div>

          <ToastContainer />

          {
            this.state.signupAnimation ?
              <div style={{ position: "fixed", width: '100%', height: '100%', left: '0', top: '0', zIndex: '9999' }}>
                <Confetti
                  width={window.innerWidth - 20}
                  height={window.innerHeight}
                />
              </div> : null
          }

          <LoginComponent showPopup={this.state.showLoginPopup} signUpPath={this.props.signUpPath} email={this.props.email} />

          <EnrollmentComponent showPopup={this.state.showEnrollmentPopup} />

          <ForgotPasswordComponent showPopup={this.state.showForgotPassword} />
          <CheckoutPaymentComponent showPopup={this.state.showCheckoutPopup} />

          <Navbar color="dark" dark expand="md">
            <NavbarBrand href="/">
              <img style={{ margin: '8px' }} src={logoHere} alt="talent map" width="130" />
            </NavbarBrand>

            {
              isMobileBrowser() ?
                <Nav className="ml-auto text-left userMenuMobile" navbar>
                  {
                    !this.state.isLoggedIn ?
                      <Button onClick={this.openLoginModal2} size="sm" color="warning">
                        Login
                    </Button> :
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav>
                          <Button className="userName" onClick={this.openLoginModal2} size="sm" color="warning">
                            {this.state.loggedInUserName}
                          </Button>
                          {/* <span></span> */}
                        </DropdownToggle>
                        <DropdownMenu className="userDropDown" right>
                          <DropdownItem>
                            <Link onClick={this.loadDashboard} className="nav-link" to="#">
                              Dashboard
                          </Link>
                            <Link onClick={this.loadProfile} className="nav-link" to="#">
                              Profile
                          </Link>
                            <Link onClick={this.logout} className="nav-link" to="#">
                              Logout
                          </Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                  }
                </Nav>
                : null
            }

            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto text-left" navbar>
                <NavItem>
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="/courses">
                    {/* <a className="nav-link" href="https://dashboard.lincolnmethod.com/store"> */}
                    Courses
                    {/* </a> */}
                  </Link>
                </NavItem>


                <NavItem>
                  <Link className="nav-link" to="/method">
                    Method
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="/research">
                    Research
                  </Link>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <span>Partnerships</span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <Link className="nav-link" to="/resellers">
                        Resellers
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />

                    <DropdownItem>
                      <Link className="nav-link" to="/franchise-partner">
                        Franchise Partner
                      </Link>
                    </DropdownItem>
                    {/* <DropdownItem divider /> */}
                    {/* <DropdownItem>
                      <Link className="nav-link" to="/fashion-design-shout-outs">
                        Fashion & Design shout-outs
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link className="nav-link" to="/educate-compton">
                        Educate Compton
                      </Link>
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <NavItem>
                  <Link className="nav-link" to="/wall-of-fame">
                    Wall of Fame
                  </Link>
                </NavItem>

                <NavItem>
                  <Link className="nav-link onlineSchoolBtn" to="/online-school">
                    <FontAwesomeIcon icon={faChalkboardTeacher} size="1x" color="inherit" style={{ marginRight: '10px', position: 'relative', fontSize: '20px', marginBottom: '-3px' }} />
                    Online School
                  </Link>
                </NavItem>

                {
                  !isMobileBrowser() ?
                    !this.state.isLoggedIn ?
                      <NavItem>
                        <Link onClick={this.openLoginModal2} className="nav-link" to="#">
                          Login
                        </Link>
                      </NavItem> :
                      <UncontrolledDropdown nav inNavbar className="userDropDownMain">
                        <DropdownToggle nav>
                          <FontAwesomeIcon icon={faUser} size="1x" color="inherit" style={{ marginRight: '10px', position: 'relative', fontSize: '20px', marginBottom: '-3px' }} />
                          <span>{this.state.loggedInUserName}</span>
                        </DropdownToggle>
                        <DropdownMenu className="userDropDown" right>
                          <DropdownItem>
                            <Link onClick={this.loadDashboard} className="nav-link" to="#">
                              Dashboard
                            </Link>
                            <Link onClick={this.loadProfile} className="nav-link" to="#">
                              Profile
                            </Link>
                            <Link onClick={this.logout} className="nav-link" to="#">
                              Logout
                            </Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    : null
                }

                {/* <NavItem>
                  <a className="nav-link" href="tel:+92 300 829 6228">
                    <FontAwesomeIcon style={{marginRight: '15px'}} icon={faPhoneAlt} size="1x" color="#f8ab24" />
                    Call Us
                  </a>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav-link" to="/">
                    About Us <br/> <sup>- What we do</sup>
                  </Link>
                </NavItem> */}
                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <span style={{lineHeight: '45px'}}>About Us</span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <Link className="nav-link" to="/objectives">
                        Our Objectives
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link className="nav-link" to="/21st-century-skills">
                        21<sup>st</sup> Century Skills
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    HireLabs <br/>Assessments
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <Link className="nav-link" to="/assessments/methodology">
                        Methodology
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link className="nav-link" to="/assessments/validity-reliability">
                        Validity & Reliability
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link className="nav-link" to="/assessments/reports-analytics">
                        Reports & Analytics
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                {/* <NavItem>
                    <Link style={{lineHeight: '45px'}} className="nav-link" to="/partners">
                      Partners
                    </Link>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav-link" to="/">
                    Contact <br/> <sup>- Get in touch</sup>
                  </Link>
                </NavItem> */}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  getAllCourses: PropTypes.func.isRequired,
  getAllOnlineSchoolCourses: PropTypes.func.isRequired,
  getAllClientsData: PropTypes.func.isRequired,
  triggerLoginPopup: PropTypes.func.isRequired,
  triggerCheckoutPopup: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  showLoginPopupRed: state.webRed.showLoginPopupRed,
  showEnrollmentPopupRed: state.webRed.showEnrollmentPopupRed,
  showForgotPasswordRed: state.webRed.showForgotPasswordRed,
  showCheckoutPopupRed: state.webRed.showCheckoutPopupRed,
  loginSuccessNormal: state.webRed.loginSuccessNormal,
  loginSuccessWithCourse: state.webRed.loginSuccessWithCourse,
  successData: state.webRed.successData,
  showCongratulationsRed: state.webRed.showCongratulationsRed
})

export default connect(mapStateToProps, { getAllCourses, getAllOnlineSchoolCourses, getAllClientsData, triggerLoginPopup, triggerCheckoutPopup })(Header);
