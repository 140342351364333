import React, { Component } from "react";
import "../../../public/scss/Partnerships.scss";
import Header from './..//Header';
import Footer from "./../Footer";

import YouTubePlaylist from 'react-youtube-playlist';
import 'react-youtube-playlist/dist/styles.scss';

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";


import banner from './../../../public/images/partnerships/bhdi/banner.jpeg';
import bannerBig from './../../../public/images/partnerships/bhdi/bannerBig.png';

class FasionDesignShoutOutsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        document.title = "Fashion & Design shout-outs - Skillupz";
    }
    
    render() {
        
        return (
        // <DocumentMeta {...meta}>
            <div className="FasionDesignShoutOutsComponent">

                <Header />

                    <div className="homeEdtech" style={{backgroundImage: 'url(' + bannerBig + ')', backgroundColor: 'rgba(255,0,0,0.5)', backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <Media style={{maxWidth: '100%'}} object src={banner} alt="un-nations-sdg-banner" />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="partnershipContent" style={{padding: '10vh 0', background: '#fed700'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row style={{paddingBottom: '10px'}}>
                                <Col md={{ size: 12 }}>
                                    
                                </Col>
                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <p>Skillupz and Beverly Hills Design Institute (<a href="https://www.bhdi.edu/">BHDI</a>) have partnered together in promoting the ‘Fashion for Peace’ cause. Lincoln Method is grateful to <a href="https://www.facebook.com/ETESonia">Sonia Ete</a> of <a href="https://www.bhdi.edu/">BHDI</a> for engaging with her network and jump-starting this casue. Fashion for Peace is an amazing cause that brings people together and gives a fun platform that supports the United Nations Sustainable Development Goals</p>
                                </Col>
                            </Row>
                            
                        </Container>
                    </div>

                    
                    <div className="partnershipContent" style={{padding: '10vh 0'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row style={{paddingBottom: '10px'}}>
                                <Col md={{ size: 12 }}>
                                    <h2 style={{fontWeight: 'bold'}}>Watch Shirin Malekzadeh's message on Fashion for Peace</h2>
                                    <iframe width="100%" height="500" src="https://www.youtube.com/embed/_FIWTf9ZfQE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                    <p style={{textAlign: 'left'}}>
                                        Special thanks to actress, Emily Drake, for the shout-out on Fashion For Peace and for supporting online learning. Click on the video to watch what she has to say.
                                    </p>
                                    <p style={{textAlign: 'left'}}>
                                        The Skillupz online learning technology will now be used by Beverly Hills Design Institute to teach Fashion & Design.
                                    </p>
                                    <p style={{textAlign: 'left'}}>
                                        Now anyone can login online from home, and can take these courses and learn about Fashion & Design. Certification will be provided directly by Beverly Hills Design Institute.
                                    </p>

                                </Col>
                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <YouTubePlaylist
                                        width={'100%'}
                                        height={'500px'}
                                        api_key="AIzaSyB1chIZ5foXB-0PxSCDeSAdGz7Fl0nFOPU"
                                        playlist_id='PLYU4Tsu1UrawH6FPqpnVumCSbeJrvBcr0'
                                        show_thumbnails={true}
                                        scrolling={true}
                                    />
                                </Col>
                            </Row>
                            
                        </Container>
                    </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default FasionDesignShoutOutsComponent;