import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../public/scss/HomeComponent.scss";
import Header from './Header';
import Footer from "./Footer";
import Typed from 'react-typed';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faTh, faStar, faChessKing, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

// importing snippets for homepage
import BannerWithText from './snippets/bannerWithText';

import homeBanner from './../../public/images/homeBanner.png';
import homeBG from './../../public/images/homeBG.png';
import ipadlm from './../../public/images/ipadlm.png';
import learnSomething from './../../public/images/learnSomething.png';
import leadershipBg from './../../public/images/leadershipBg.png';
import successBanner from './../../public/images/successBanner.png'
import step1 from './../../public/images/step1.png';
import step2 from './../../public/images/step2.png';
import step3 from './../../public/images/step3.png';
import step1_mobile from './../../public/images/step1_mobile.png';
import step2_mobile from './../../public/images/step2_mobile.png';
import step3_mobile from './../../public/images/step3_mobile.png';
import mobileAnimation from './../../public/images/mobileAnimation.gif';
import speedometterlightloop from './../../public/images/speedometterlightloop.gif';
import homeReport from './../../public/images/homeReport.gif';

class HomeComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bannerData : {
                heading: "Welcome to <span style='color: #f8ab24;'>Skillupz</span>",
                subheadings: {
                  label: "We help",
                  list: [
                    "accountants learn SAP ",
                    "managers learn leadership skills ",
                    "developers learn how to code ",
                    "students get a higher grade ",
                    "interns learn MS Excel "
                  ]
                },
                btn_text: "Play Video",
                btn_icon: faPlayCircle,
                // btn_text_url: "http://www.testedtalent.com/grant-application-form/",
                image_url: homeBanner,
                themeColor: "yellow",
                // video_url: bannerVideo,
            },
            reportHelpList: [
              "track your progress",
              "your organization elevate",
              "you see where you need to improve"
            ],
            showOnlineSchoolDemo : false
        };
    }

  componentDidMount(){
    document.title = "Skill Upz";
  }
    
  render() {
    return (
      <div className="HomeComponent">
        <Header signUpPath={this.props.location.pathname} email={this.props.match.params.email}/>

        {
          this.state.showOnlineSchoolDemo ?
            <div className="homeEdtech padding-20" style={{ backgroundColor: '#007bff', padding: "20px 0"}} >
              <Container>
                  <Row>
                      <Col md={{ size: 8, offset: 2 }}>
                          <h4 style={{color: '#fff', margin: '0', cursor: 'pointer', textAlign: 'center'}} onClick={e => {
                            window.location.href="/#/online-school";
                          }}>
                            Click for a Demo class at the Online School
                          </h4>
                      </Col>
                  </Row>
              </Container>
          </div>
          : null
        }

        <BannerWithText bannerData={this.state.bannerData} />

        <div className="homeEdtech" style={{ backgroundImage: "url("+ homeBanner +")", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundColor: 'rgba(0,0,0,1)', backgroundSize: 'cover' }} >
            <Container>
                <Row>
                    <Col md={{ size: 4 }}>
                        <h2>Our EdTech helps upgrade skills</h2>
                    </Col>
                    <Col md={{ size: 8 }}>
                        <p>
                        SkillUpz is a progressive way of learning, that focuses on addressing learner’s weak areas. The learning happens by supplementing what has already been taught. For the areas where the learner is weak, their concepts are further clarified by showing them a playlist of videos that present a variety of viewpoints. As a result, the learner receives a holistic view of the concepts, and has a better grasp of the overall subject.

                          <br/> <br/>
SkillUpz's secret sauce is its ability to identify areas of weakness for each individual learner. Once the weak areas are known, then correcting any misconceptions becomes relatively easy.                        </p>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="homeEdtech">
            <Container style={{textAlign: 'center'}}>
                <Row>
                    <Col md={{ size: 12 }}>
                        <h2 style={{color: '#000'}}>Learn something new</h2>
                    </Col>
                    <Col md={{ size: 12 }} style={{paddingTop: '5vh'}}>
                      <Media style={{width:'100%'}} object src={learnSomething} alt="learn something new" />
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="homeEdtech" style={{backgroundImage: 'url('+ homeBG +')', backgroundPosition: 'center', backgroundSize: 'cover', padding: '0'}}>
            <Container style={{background: 'url('+ ipadlm +') left 130px bottom / 60% no-repeat fixed'}}>
                <Row style={{padding: "30vh 0"}}>
                    <Col md={{ size: 6, offset: 6 }}>

                        <h2 style={{color: '#fff'}}>What would you like to learn ?</h2>

                        <Link to="/courses">
                          <Button className="BannerWithText_btn" size="lg" color="primary">
                            <FontAwesomeIcon style={{marginRight: '15px'}} icon={faTh} size="1x" color="#f8ab24" />
                            View Courses
                          </Button>
                        </Link>

                    </Col>
                </Row>
            </Container>
        </div>

        <div className="homeEdtech leadership" style={{backgroundImage: 'url('+ leadershipBg +')', backgroundPosition: 'left bottom', backgroundSize: 'cover', backgroundColor: '#fff', padding: '20vh 0'}}>
            <Container>
                <Row>
                    <Col md={{ size: 7, offset: 5 }}>

                        <Media className="onlyMobile" style={{maxWidth: 'calc(100% + 30px)', position: 'relative', left: '-15px'}} object src={leadershipBg} alt="learning steps" />
                        
                        <h5>We have an amazing online course on</h5>
                        <h2 style={{color: '#001e37'}}>Leadership for Millennials</h2>
                        <Row style={{padding: '5vh 0'}}>
                          <Col md={{ size: 3}}>
                            <div className="numberBox">
                              <span>4</span>
                              chapters
                            </div>
                          </Col>
                          <Col md={{ size: 3}}>
                            <div className="numberBox">
                              <span>40</span>
                              training modules
                            </div>
                          </Col>
                          <Col md={{ size: 3}}>
                            <div className="numberBox">
                              <span>6</span>
                              hours online training
                            </div>
                          </Col>
                          <Col md={{ size: 3}}>
                            <div className="numberBox">
                              <span>65</span>
                              percent improvement 
                            </div>
                          </Col>
                        </Row>
                        <Link to="/course/EgQk6yN8K7vq8pG">
                          <Button className="BannerWithText_btn" size="lg" color="primary">
                            <FontAwesomeIcon style={{marginRight: '15px'}} icon={faStar} size="1x" color="#f8ab24" />
                            View this course
                          </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="homeEdtech" style={{background: '#001e37'}}>
            <Container style={{textAlign: 'center'}}>
                <Row>
                    <Col md={{ size: 12 }}>
                        <h2 style={{color: '#fff'}}>We have made learning easy</h2>
                        <h3 style={{color: '#f8ab24'}}>Learn in 3 easy steps</h3>
                    </Col>
                </Row>
                <Row className="stepBoxes" style={{padding: "5vh 0"}}>
                  <Col md={{ size: 3 }}>
                    <div className="engineBox">
                      Our Learning Engine
                    </div>
                  </Col>
                  <Col md={{ size: 3 }}>
                    <Media object src={step1} />
                    <Media className="onlyMobile" object src={step1_mobile} />
                  </Col>
                  <Col md={{ size: 3 }}>
                    <Media object src={step2} />
                    <Media className="onlyMobile" object src={step2_mobile} />
                  </Col>
                  <Col md={{ size: 3 }}>
                    <Media object src={step3} />
                    <Media className="onlyMobile" object src={step3_mobile} />
                  </Col>
                </Row>
            </Container>
        </div>

        <div className="homeEdtech takePeak" style={{backgroundImage: 'url('+ mobileAnimation +')', backgroundPosition: 'right bottom', backgroundSize: 'contain', backgroundColor: '#fff', backgroundRepeat: 'no-repeat', padding: '27vh 0'}}>
            <Container>
                <Row>
                    <Col md={{ size: 5, offset: 1 }}>
                        <h2 style={{color: '#000'}}>Take a peek & explore our learning engine</h2>
                        
                        <Link to="/method">
                          <Button className="BannerWithText_btn" size="lg" color="primary">
                            <FontAwesomeIcon style={{marginRight: '15px'}} icon={faChessKing} size="1x" color="#f8ab24" />
                            View Method
                          </Button>
                        </Link>
                        <Media className="onlyMobile" style={{maxWidth: 'calc(100% + 30px)', position: 'relative', left: '-15px'}} object src={mobileAnimation} alt="learning steps" />
                        
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="homeEdtech" style={{background: '#001e37'}}>
            <Container>
                <Row>
                    <Col md={{ size: 12 }}>
                        <h2 style={{color: '#fff'}} className="BannerWithText_subheading">
                          Our reports can help
                          <Typed
                            strings={this.state.reportHelpList}
                                typeSpeed={60}
                                backSpeed={15}
                                loop >
                                  <span></span>
                          </Typed>
                        </h2>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="engineContainer imageContainer">
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>IMAGE is worth a thousand words</h2>
                    </Col>
                    <Col style={{padding: '14vh 15px 0 15px', textAlign: 'left'}} md={{ size: 4 }}>
                        <p style={{fontSize: '14px'}}>
                            All our reports are presented through an easy to understand acronym, called &nbsp; <span style={{fontWeight: 'bold', color: '#000'}}>I M A G E</span>
                        </p>
                    </Col>
                    <Col md={{ size: 4 }}>
                      <div className="imageBox">
                        <ul>
                          <li style={{color: '#ff0000'}}><span style={{background: '#ff0000'}}>I</span>Insufficient</li>
                          <li style={{color: '#ffa400'}}><span style={{background: '#ffa400'}}>M</span>Marginal</li>
                          <li style={{color: '#ffff01'}}><span style={{background: '#ffff01'}}>A</span>Average</li>
                          <li style={{color: '#00e600'}}><span style={{background: '#00e600'}}>G</span>Good</li>
                          <li style={{color: '#008500'}}><span style={{background: '#008500'}}>E</span>Excellent</li>
                        </ul>
                      </div>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <Media style={{maxWidth: '100%'}} object src={speedometterlightloop} alt="Reports Meter" />
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="homeEdtech dashboard" style={{background: '#f8c634', paddingBottom: '0'}}>
            <Container style={{textAlign: 'center'}}>
                <Row>
                    <Col md={{ size: 12 }}>
                        <h2 style={{color: '#000'}}>Dashboard & Report</h2>
                    </Col>
                    <Col md={{ size: 12 }} style={{paddingTop: '5vh'}}>
                      <Media style={{maxWidth:'100%'}} object src={homeReport} alt="Dashboard and report" />
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="homeEdtech" style={{ backgroundImage: "url("+ successBanner +")", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundColor: 'rgba(0,0,0,1)', backgroundSize: 'cover' }} >
            <Container>
                <Row>
                    <Col md={{ size:12 }} style={{textAlign:'center'}}>
                          <img src={require('./../../public/images/succ.png')}/>
                    </Col>
                  
                </Row>
            </Container>
        </div>
        <div className="homeEdtech" style={{background: '#001e37', padding: '20vh 0'}}>
            <Container>
                <Row>
                  <Col md={{ size: 7 }}>
                    <iframe width="100%" height="356" src="https://www.youtube.com/embed/BibqzkDpr3U" frameBorder='0' allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </Col>
                  <Col style={{paddingTop: '10vh'}} md={{ size: 4, offset: 1 }}>
                      <h5 style={{color: '#fff'}}>Our EdTech can be used to power large scale projects through Public-Private-Partnerships</h5>
                      <h2 style={{color: '#f8ab24'}}>See what we are doing in Philippines</h2>
                  </Col>
                </Row>
            </Container>
        </div>   
        
        <Footer />
      </div>
    );
  }
}

export default HomeComponent;