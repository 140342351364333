import React, { Component } from "react";
import "../../../public/scss/Partnerships.scss";
import Header from './..//Header';
import Footer from "./../Footer";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";


import banner from './../../../public/images/partnerships/UN_Pic_Banner.png';

//images for twitter gallery
import twitterimage1 from './../../../public/images/partnerships/twitter/47.jpg';
import twitterimage2 from './../../../public/images/partnerships/twitter/19.jpg';
import twitterimage3 from './../../../public/images/partnerships/twitter/44.jpg';
import twitterimage4 from './../../../public/images/partnerships/twitter/42.jpg';
import twitterimage5 from './../../../public/images/partnerships/twitter/20.jpg';
import twitterimage6 from './../../../public/images/partnerships/twitter/43.jpg';
import twitterimage7 from './../../../public/images/partnerships/twitter/17.jpg';
import twitterimage8 from './../../../public/images/partnerships/twitter/45.jpg';
import twitterimage9 from './../../../public/images/partnerships/twitter/46.jpg';
import twitterimage10 from './../../../public/images/partnerships/twitter/13.jpg';
import twitterimage11 from './../../../public/images/partnerships/twitter/41.jpg';
import twitterimage12 from './../../../public/images/partnerships/twitter/11.jpg';
import twitterimage13 from './../../../public/images/partnerships/twitter/12.jpg';
import twitterimage14 from './../../../public/images/partnerships/twitter/16.jpg';


//images for UN gallery
import UNimage1 from './../../../public/images/partnerships/united/27540020_1964997823763980_6062082052073669612_n.jpg';
import UNimage2 from './../../../public/images/partnerships/united/27459220_1966554416941654_7752688022149705483_n.jpg';
import UNimage3 from './../../../public/images/partnerships/united/27459313_1966554483608314_2700168222056784694_n.jpg';
import UNimage4 from './../../../public/images/partnerships/united/27459551_1966554360274993_8858553953305320977_n.jpg';
import UNimage5 from './../../../public/images/partnerships/united/27337059_1966554326941663_6174516189159973921_n.jpg';
import UNimage6 from './../../../public/images/partnerships/united/27336974_1966554510274978_6993237461120942250_n1.jpg';
import UNimage7 from './../../../public/images/partnerships/united/27858938_1966554446941651_2542729163554927824_n.jpg';
import UNimage8 from './../../../public/images/partnerships/united/27657512_1966554393608323_7893750823325001925_n.jpg';

// importing videos
import video1 from './../../../public/videos/partnerships/video1.mp4';
import video2 from './../../../public/videos/partnerships/video2.mp4';

class UnitedNationsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            photoIndex1: 0,
            isOpen1: false,
            video1: video1,
            video2: video2
        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }
    openLighBox1(index) {
        this.setState({
            photoIndex1: index,
            isOpen1: true
        })
    }

    componentDidMount() {
        document.title = "United Nations SDG - Lincoln Method";
    }
    
    render() {

        const { photoIndex, isOpen } = this.state;
        const images = [
            twitterimage1,
            twitterimage2,
            twitterimage3,
            twitterimage4,
            twitterimage5,
            twitterimage6,
            twitterimage7,
            twitterimage8,
            twitterimage9,
            twitterimage10,
            twitterimage11,
            twitterimage12,
            twitterimage13,
            twitterimage14
        ];

        const { photoIndex1, isOpen1 } = this.state;
        const images1 = [
            UNimage1,
            UNimage2,
            UNimage3,
            UNimage4,
            UNimage5,
            UNimage6,
            UNimage7,
            UNimage8
        ];
        
        return (
        // <DocumentMeta {...meta}>
            <div className="UnitedNationsComponent">

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                )}

                {isOpen1 && (
                    <Lightbox
                        mainSrc={images1[photoIndex1]}
                        nextSrc={images1[(photoIndex1 + 1) % images1.length]}
                        prevSrc={images1[(photoIndex1 + images1.length - 1) % images1.length]}
                        onCloseRequest={() => this.setState({ isOpen1: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex1: (photoIndex1 + images1.length - 1) % images1.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex1: (photoIndex1 + 1) % images1.length,
                        })
                        }
                    />
                )}

                <Header />

                    {/* <div className="homeEdtech" style={{background: '#121625 center fixed / cover'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <h2>banner</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}

                    <div className="partnershipContent" style={{padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row style={{paddingBottom: '10px'}}>
                                <Col md={{ size: 12 }}>
                                    <Media style={{maxWidth: '100%'}} object src={banner} alt="un-nations-sdg-banner" />
                                </Col>
                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <h3>Skillupz presents it’s vision for Quality Education at the United Nations in Geneva (SDG #4)</h3>
                                    <p>Skillupz is a proud Goalkeeper of the UN’s Sustainable Development Goal #4, which endorses Quality Education.</p>
                                    <p>Achieving inclusive and quality education for all reaffirms the belief that education is one of the most powerful and proven vehicles for sustainable development.</p>
                                    <p>Skillupz is one of the few institutions that is promoting digital education in Pakistan.</p>
                                    <p>Affordable, reliable and context-sensitive digital education, can promote equal opportunities for students and reduce inequalities by ensuring every child has access to high quality content. Digital education technologies improves fundamental skills such as collaboration, problem solving and global awareness.</p>
                                </Col>
                            </Row>
                            
                        </Container>

                        <Container style={{padding: '5vh 0'}}>
                            <Row>

                                {
                                    images.map((imageUrl, key) =>
                                        <Col key={key} md={{ size: 3 }}>
                                            <Media style={{maxWidth: '100%', cursor: 'pointer', margin: '15px 0'}} onClick={() => this.openLighBox(key)} object src={imageUrl} />
                                        </Col>
                                    )
                                }
                            </Row>
                            <Row style={{borderTop: '1px #ccc solid', marginTop: '4vh', paddingTop: '4vh'}}>
                                {
                                    images1.map((imageUrl, key) =>
                                        <Col key={key} md={{ size: 3 }}>
                                            <Media style={{maxWidth: '100%', cursor: 'pointer', margin: '15px 0'}} onClick={() => this.openLighBox1(key)} object src={imageUrl} />
                                        </Col>
                                    )
                                }

                            </Row>
                            <Row style={{borderTop: '1px #ccc solid', marginTop: '4vh', paddingTop: '4vh'}}>
                                <Col md={{size: 6}}>
                                    <video width="100%" controls>
                                        {/* <source src={this.state.video_url} type="video/webm" /> */}
                                        <source type="video/mp4" src={this.state.video1} />
                                    </video>
                                </Col>
                                <Col md={{size: 6}}>
                                    <video width="100%" controls>
                                        {/* <source src={this.state.video_url} type="video/webm" /> */}
                                        <source type="video/mp4" src={this.state.video2} />
                                    </video>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '4vh'}}>
                                <Col md={{size: 6}}>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/3IB4GREDpKc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default UnitedNationsComponent;