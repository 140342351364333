import React, { Component } from "react";
import "../../public/scss/Partnerships.scss";
import Header from './Header';
import Footer from "./Footer";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";


//images for twitter gallery
import learningClinic1 from './../../public/images/learningclinic/learningClinic1.jpeg';
import learningClinic2 from './../../public/images/learningclinic/learningClinic2.jpeg';
import learningClinic3 from './../../public/images/learningclinic/learningClinic3.jpeg';
import learningClinic4 from './../../public/images/learningclinic/learningClinic4.jpeg';

import banner1 from './../../public/images/learningclinic/banner1.jpg';
import learning_journey from './../../public/images/learningclinic/learning_journey.jpg';
import banner2 from './../../public/images/learningclinic/banner2.jpg';
import understanding from './../../public/images/learningclinic/understanding.jpg';
import banner3 from './../../public/images/learningclinic/banner3.jpg';
import remotely from './../../public/images/learningclinic/remotely.jpg';
import banner4 from './../../public/images/learningclinic/banner4.jpeg';

class LearningClinicComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }
    openLighBox1(index) {
        this.setState({
            photoIndex1: index,
            isOpen1: true
        })
    }

    componentDidMount() {
        document.title = "Learning Clinic - Lincoln Method";
    }
    
    render() {
        
        return (
        // <DocumentMeta {...meta}>
            <div className="LearningClinicComponent">

                <Header />

                    <div className="homeEdtech" style={{background: '#121625 center fixed / cover'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <h2>The Learning Clinic</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="homeEdtech" style={{background: '#fff center fixed / cover', padding: '5vh 0'}}>
                        <Container style={{textAlign: 'center'}}>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <h2 style={{color: '#121625', margin: '0'}}>Here are few Points</h2>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    {/* <div className="partnershipContent" style={{padding: '5vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row style={{paddingBottom: '10px'}}>
                                
                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <Row>
                                        <Col md={{size: '12'}}>
                                            <h2 style={{fontWeight: 'bold'}}>The Learning Clinic is different from our TUITIONs</h2><br />
                                            <Media style={{maxWidth: '100%', float: 'right', margin: '5vh 0'}} object src={learningClinic1} alt="learningClinic1" />
                                            <br />
                                            <h3>Here is how the Learning Clinic works:</h3><br />
                                            <h4>Step 1:</h4>
                                            <p>We work with experienced professional in psychology, and we try to find out where the child is having a difficulty in learning.</p>
                                            <h4>Step 2:</h4>
                                            <p>We create a customized learning plan for the child to overcome learning impairments.</p>
                                        </Col>
                                    </Row>
                                    
                                    <h3>What is the background of The Learning Clinic?</h3>
                                    <p>You may be aware that approximately 10% of kids go through mild to moderate levels of attention impairments during their formative years...</p>
                                    <p>We have developed a program that starts of by identifying the severity of the attention impairment (each child is different), and then helping that child managing their attention impairment.</p>
                                    <p>Unfortunately, we have realized that schools are not equipped to train their teacher on how to manage kids that have trouble with focus and concentration. As a result these kids are left behind in class, and time moves on.</p>
                                    <p>Since the problem is not addressed in class by the teachers, and the child is not taught how to increase their concentration levels, it makes life very difficult for the child as they grow up.</p>
                                    <p>Since we are a TUITION CENTER, and since we have a lot of kids come to us who are not star students, we get to see a lot of cases where kids are falling behind in school. Our main focus is on understanding <strong>WHY</strong> is each child left behind in school.</p>
                                    <p>We also notice that a lot of the kids who come to us have difficulty in concentration, and some of these kids are also hyperactive at the same time. So as we work with each child and dig deeper into the child's weak areas, we are able to create a customized program that helps them overcomes their learning challenges.</p>
                                    <p>If the problem is only with concentration, then the underlying technique that we use is to help increase the neural activity within the child's brain. This is done in a number of ways (depending on the child's age, and the severity of the attention impairment).</p>
                                    <p>
                                        If the problem is both lack of concentration AND hyperactivity, then the underlying technique that we use is a rewards based system to naturally increase dopamine levels within the brain. We also do not try to inhibit the hyperactivity (because it is very difficult to do so), however, we introduce a small fidget-toy so the hyperactivity is contained (a lot of these techniques are customized depending on the child's impairment).
                                        <br /><Media style={{maxWidth: '400px', width: '100%', float: 'right', margin: '2vh'}} object src={learningClinic2} alt="learningClinic2" />
                                    </p><br />
                                    <p>One of the first things that we did is try to understand the brain's physiology and try to understand what is going on with the electrical signals in the synapses of the brain.</p>
                                    <p>We learnt that in the brain, kids with ADHD have an issue with Dopamine D4 receptors (DRD4), because of which the electrical signals in the brain do not jump from one synapse to another as effectively (this is because of DRD4).</p>
                                    <p>Now that we know this about the brain, and because we also know that Dopamine release is linked to when you get a reward, then it became easier for us to develop the right program of each child.</p>
                                    <p>Since we know that the problem is that DRD4 is weak (which means that the brain needs more dopamine), this means that if we develop a program for the child, and if we structure the rewards (when to give the rewards to the child during the learning journey), then we will increase the motivation of the child, which in turn leads to increased Attention</p>
                                    <p>We also noticed another problem that kids have with remembering things. Kids with ADHD have a problem with working memory (or short-term memory). This becomes a problem during exam time. So there are multiple techniques that we have developed to address how kids can have a better recall. One successful way is to use visuals aides to facilitate recall. Common Visual aids that we use are mind maps</p>
                                </Col>

                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <h3>How do we help the student?</h3>
                                    <p>One of the first things that we try to do is focus on forming habits. The habit can be anything such as brushing your teeth, finishing your breakfast, putting your toys away... anything simple</p>
                                    <p>This gives us the opportunity to help the child learn.</p>
                                    <p>Along with habit forming, we reinforce it with rewards, so that the brain wants to repeat the task and get the next reward (and a habit starts forming).</p>
                                    <p>We are working with a group of experienced psychologist and we have designed a set of programs (for each age) to help kids focus and learn how to turn off all the noises.</p>
                                </Col>

                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <h3>How do we help parents who reside outside Karachi?</h3>
                                    <p>We provide the parents (with whom we are working remotely) with weekly packets and planners to help in managing the child. We work with the parent over weekly phone calls. We are actually working with a couple of parents all over Pakistan, and helping them work with their child (ideally we would want the child to come to our clinic).</p>
                                </Col>

                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <h3>How can we help your child?</h3>
                                    <Row style={{padding: '3vh 0'}}>
                                        <Col md={{size: '6'}}>
                                            <Media style={{maxWidth: '100%'}} object src={learningClinic3} alt="learningClinic3" />
                                        </Col>
                                        <Col md={{size: '6'}}>
                                            <Media style={{maxWidth: '100%'}} object src={learningClinic4} alt="learningClinic4" />
                                        </Col>
                                    </Row>
                                    <p>Each child is different, and each child's attention impairment is also different, so we first have a consultation session with the parent and the child to figure out what are the problem areas, then we design a program to help the child overcome the problem areas.</p>
                                    <p>We offer initial consultations to discuss your child's attention impairment.</p>
                                </Col>

                                <Col md={{size: 12}} style={{paddingTop: '5vh'}}>
                                    <h3>What happens in the Consultation Session?</h3>
                                    <p>During the consultation, we will:</p>
                                    <ul>
                                        <li>Review the current state of the child's learning impairment.</li>
                                        <li>Review any remedial strategies that have been undertaken.</li>
                                        <li>Discuss what is happening in the brain of a child that has an attention impairment, and why the child is having difficulty in learning.</li>
                                        <li>Discuss different ways and techniques that you can use to help the child overcome their current learning challenges.</li>
                                    </ul>
                                </Col>

                            </Row>
                            
                        </Container>

                    

                    </div> */}

                    <div className="partnershipContent overlay" style={{background: 'url(' + banner1 + ') center fixed / cover', padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row>
                                <Col md={{size: 6}} style={{padding: '15vh 15px'}}>
                                   <h1 style={{color: '#fff'}}>
                                        We <span style={{color: 'rgb(248, 171, 36)', fontWeight: 'bold'}}>identify</span> what part of the child learning journey is not being addressed at school
                                   </h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="partnershipContent" style={{background: '#e7e7e5 center fixed / cover', padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row>
                                <Col md={{size: 6}} style={{padding: '15vh 15px'}}>
                                   <h1 style={{color: '#000'}}>
                                   We work with <span style={{color: 'rgb(248, 171, 36)', fontWeight: 'bold'}}>parents to identify</span> learning goals & then create a learning journey to meet those goals</h1>
                                </Col>

                                <Col md={{size: 6}}>
                                    <Media style={{maxWidth:'100%'}} object src={learning_journey} alt="Learning Journey" />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="partnershipContent overlay" style={{background: 'url(' + banner2 + ') center fixed / cover', padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row>
                                <Col md={{size: 6}} style={{padding: '15vh 15px'}}>
                                    <h1 style={{color: '#fff'}}>
                                        We can address almost every learning impairment can be addressed by providing the <span style={{color: 'rgb(248, 171, 36)', fontWeight: 'bold'}}>right attention</span>
                                    </h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="partnershipContent" style={{background: '#f7f7f7 center fixed / cover', padding: '5vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row>
                                <Col md={{size: 5}} style={{padding: '15vh 15px'}}>
                                   <h1 style={{color: '#000'}}>
                                   We address learning impairments by first understanding the <span style={{color: 'rgb(248, 171, 36)', fontWeight: 'bold'}}>physiology of the brain</span></h1>
                                </Col>

                                <Col md={{size: 6, offset: 1}}>
                                    <Media style={{maxWidth:'100%'}} object src={understanding} alt="understanding brain" />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="partnershipContent overlay" style={{background: 'url(' + banner3 + ') center fixed / cover', padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row>
                                <Col md={{size: 6}} style={{padding: '15vh 15px'}}>
                                    <h1 style={{color: '#fff'}}>
                                        We work <span style={{color: 'rgb(248, 171, 36)', fontWeight: 'bold'}}>one on one</span> with the child and provide the relevant attention to overcome the learning impairment
                                    </h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="partnershipContent" style={{background: '#ffffff center fixed / cover'}}>
                        <Container style={{textAlign: 'left', overflow: 'hidden'}}>
                            <Row>
                                <Col md={{size: 5}} style={{padding: '15vh 15px'}}>
                                   <h1 style={{color: '#000', zIndex: '2'}}>
                                   We can also work with <span style={{color: 'rgb(248, 171, 36)', fontWeight: 'bold'}}>parents remotely</span> in administrating a <span style={{color: 'rgb(248, 171, 36)', fontWeight: 'bold'}}>learning plan</span> </h1>
                                </Col>

                                <Col md={{size: 6, offset: 1}}>
                                    <Media object src={remotely} alt="parents remotely" />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="partnershipContent overlay" style={{background: 'url(' + banner4 + ') center fixed / cover', padding: '10vh 0'}}>
                        <Container style={{textAlign: 'left'}}>
                            <Row>
                                <Col md={{size: 5}} style={{padding: '15vh 15px'}}>
                                    <h1 style={{color: '#fff'}}>
                                        Tell us about your child's learning impairment and <span style={{color: 'rgb(248, 171, 36)', fontWeight: 'bold'}}>lets schedulte an appointment</span>
                                    </h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                
                <Footer />
            </div>
        // </DocumentMeta>
        );
    }
}

export default LearningClinicComponent;