import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { isMobileBrowser } from "./actions/utility";

const initialState = {};
const middleware = [thunk];
var store;

// if(!isMobileBrowser()){

  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()   
    )
  );

// }
// else{

//   store = createStore(
//     rootReducer,
//     initialState,
//     compose(
//       applyMiddleware(...middleware)
//     )
//   );

// }


export default store;
